import React, { useEffect, useState } from "react";

import "react-credit-cards-2/dist/es/styles-compiled.css";
import PaymentButton from "./PaymentButton";
import { useStripe, useElements, CardElement, PaymentElement, CardExpiryElement, CardCvcElement, CardNumberElement } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardNumberElement, StripeElementsOptions } from "@stripe/stripe-js";
import { IUserReducer } from "store/reducers/UserReducer";
import { useSelector } from "react-redux";
import { User } from "store/types/User";
import SubscriptionService from "services/SubscriptionService";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import { ExternalLinks, SubscriptionInfoType } from "values/values";
import { Plan } from "store/types/Plan";
import { Variant } from "store/types/Variant";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import UserService from "services/UserServices";

interface Props {
  subscription: SubscriptionInfo,
  selectedPlan: Plan,
  promoCode?: string,
  SDI?: string,
  city_id?: number,
  variant?: Variant,
  onClose: () => void
}
const CreditCardFormStripe: React.FC<Props> = ({ subscription, selectedPlan, promoCode, SDI, city_id, variant, onClose }) => {

  const [stripePaymentResponse, stripePaymentError, stripePaymentRequest] = SubscriptionService.useStripePaymentIntentAPI()
  const [stripePaymentIntentStatusResponse, stripePaymentIntentStatusError, stripePaymentIntentStatusRequest] = SubscriptionService.useStripePaymentIntentStatusAPI()
  const [userResponse, userError, userRequest] = UserService.useUserAPI();

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const [intentId, setIntentId] = useState<number>();
  const [status, setStatus] = useState("pending");
  const [error, setError] = useState(null);
  const [polling, setPolling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();



  useEffect(() => {
    if (stripePaymentResponse) {
      // Set the `intentId` state to the ID from the stripe payment response
      setIntentId(stripePaymentResponse.id);
    }
  }, [stripePaymentResponse]);

  useEffect(() => {
    if (intentId) {
      // Call the function to confirm the Stripe payment once `intentId` is set
      confirmStripePayment();
    }
  }, [intentId]);

  useEffect(() => {
    if (userResponse) {
      history.replace(`/dashboard?subscription=${JSON.stringify(subscription)}`)
    }
  }, [userResponse])

  const pollStatus = async () => {
    // Request the current status of the Stripe payment intent using the intent ID
    stripePaymentIntentStatusRequest(intentId!);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (polling) {
      intervalId = setInterval(() => {
        pollStatus();
      }, 1000); // Poll every 1 second
    }

    return () => clearInterval(intervalId!); // Cleanup on unmount or when polling stops
  }, [polling]);

  useEffect(() => {
    if (stripePaymentIntentStatusResponse) {
      setStatus(stripePaymentIntentStatusResponse.status); // Assuming API returns { status: 'pending' | 'successful' }
      if (stripePaymentIntentStatusResponse.status === "successful") {
        setPolling(false); // Stop polling on success
        // TODO: After redirect
        userRequest()

      } else if (stripePaymentIntentStatusResponse.status === "failed") {
        toast.error("Qualcosa è andato storto! Ti preghiamo di riprovare più tardi.");
        setLoading(false)
      }
    }

    if (error) {
      setPolling(false); // Stop polling on error
    }

  }, [stripePaymentIntentStatusResponse, error]);





  const handleCardChange = (event: any) => {

    if (event.complete && event.error == undefined) {
      setValidForm(true)
    } else if (event.error != undefined) {
      setValidForm(false)
      setLoading(false)
      toast.error(event.error.message);
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) return

    setLoading(true);
    stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement) as StripeCardElement,
      billing_details: {
        name: User.fullName(currentUser!),
        email: currentUser!.email,
        phone: currentUser!.phone
      },
    }).then((value) => {
      if (value.paymentMethod && value.paymentMethod.id) {
        handleCCPayment(subscription, value.paymentMethod.id)
      }
    })
  };

  const handleCCPayment = (subscription?: SubscriptionInfo, payment_method_id?: string) => {
    if (!subscription || !subscription.type || !selectedPlan || !payment_method_id) return

    switch (subscription.type) {
      case SubscriptionInfoType.GOLD:
        stripePaymentRequest(payment_method_id, subscription!.id!, selectedPlan!.id!, undefined, promoCode, city_id, SDI)
        break
      case SubscriptionInfoType.PROFESSIONAL:
        stripePaymentRequest(payment_method_id, subscription!.id!, selectedPlan!.id!, undefined, promoCode, city_id, SDI)
        break
      case SubscriptionInfoType.SKILLS:
        stripePaymentRequest(payment_method_id, subscription!.id!, selectedPlan!.id!, variant!.id, promoCode, city_id, SDI)
        break
      default: return
    }
  }

  const confirmStripePayment = async () => {
    if (!stripe || !elements) return

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      stripePaymentResponse.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement) as StripeCardElement
      },
      return_url: `${ExternalLinks.AppAddress}/dashboard`
    })

    if (error) {
      console.log('Error creating payment method:', error.message);
      toast.error(error.message!);
      setLoading(false);

    } else {
      console.log('paymentIntent created:', paymentIntent);
      if (paymentIntent && paymentIntent.id) {
        setPolling(true)
      }
    }
  }

  return (
    <div key="Payment">
      <div className="credit-card-form">

        <div id="card-number" style={{ border: '1px solid #f3f3f3', paddingLeft: '8px', paddingRight: '8px', paddingTop: '16px', paddingBottom: '16px', borderRadius: '4px', marginBottom: '16px' }}>
          <CardElement options={{ hidePostalCode: true }} onChange={handleCardChange} />
        </div>

        <div style={{ marginBottom: 16 }} />

        <PaymentButton
          disabled={!validForm}
          extraClass="bank-transfer"
          text={loading ? 'Elaborazione...' : 'Paga con carta di credito'}
          onClick={handleSubmit} />

        <div style={{ marginBottom: 8 }} />

        <PaymentButton
          text={"Annulla"}
          onClick={() => onClose()} />
      </div>
    </div>
  );
}

export default CreditCardFormStripe