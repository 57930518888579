import React, { Fragment, ChangeEvent, FC } from 'react';
import { useState, useEffect } from 'react';
import stationery from 'image/icons/activity/stationery-business-unselected.svg'
import stationeryActive from 'image/icons/activity/stationery-business-selected.svg'
import lawyer from 'image/icons/activity/domiciled-lawyer-unselected.svg'
import lawyerActive from 'image/icons/activity/domiciled-lawyer-selected.svg'
import substitute from 'image/icons/activity/substitute-hearing-selected-unselected.svg'
import substituteActive from 'image/icons/activity/substitute-hearing-selected.svg'
import modalGirl from 'image/icons/activity/modalGirl.svg'
import send from 'image/icons/activity/send.svg'
import strings from 'values/strings';
import classNames from 'classnames';
import ConfirmModal from './modal/ConfirmModal';
import DatePicker from 'react-datepicker';
import { FormFields, DateFormat, SkillThreshold } from 'values/values';
import { updateRequestData, RequestReducer } from 'store/reducers/RequestReducer';
import { useDispatch, useSelector } from 'react-redux';
import SelectOption from '../../store/types/SelectionOption';
import RequestField, { RequestFieldType } from './RequestField';
import _ from 'lodash';
import ActivitiesService from '../../services/ActivitiesService';
import { Request } from '../../store/types/Request';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { setRequestData } from '../../store/reducers/RequestReducer';
import { ActivityModal } from 'store/types/ActivityModal';
import smoothscroll from 'smoothscroll-polyfill';
import { IUserReducer } from 'store/reducers/UserReducer';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import UserService from 'services/UserServices';
import { OptionsType } from 'react-select/src/types';
import { User } from 'store/types/User';
import DashboardService from 'services/DashboardService';
import skillIcon from 'image/skill-icon.svg'
import UpdatesModal, { Step } from 'components/updates-modal/UpdatesModal';
import { Skill } from 'store/types/Profile';
import ProfileWrapper from 'components/profile/ProfileWrapper';
import { ProfileSwitch } from 'components/profile/ProfileItem';
import InputAutosuggest from '../common/AutoSuggest'
import Select from 'components/common/Select';
import EventsService from 'services/EventService';

smoothscroll.polyfill();

const requestType = [
  {
    unselected: lawyer,
    selected: lawyerActive,
    name: 'DOMICILIARY',
    text: strings.requestActivities.type.domiciliary,
  },
  {
    unselected: stationery,
    selected: stationeryActive,
    name: 'CHANCELLERY',
    text: strings.requestActivities.type.cancellery,
  },
  {
    unselected: substitute,
    selected: substituteActive,
    name: 'SUBSTITUTE',
    text: strings.requestActivities.type.substitute,
  },
]

enum PriceRanges { custom, none }

enum RequestActivitySteps { First = 1, Second, Third, Fourth, Fifth }
type requestActivitiesProps = {
  currentUser: User;
}
const RequestActivities: React.FC<requestActivitiesProps> = () => {

  const [isLoading, setIsLoading] = useState(false);

  let [requestResponse, , requestRequest] = ActivitiesService.useCreateRequestAPI()
  let [skillsResponse, , skillsRequest] = EventsService.useSearchSkillsAPI();

  let [step, setStep] = useState<RequestActivitySteps>(RequestActivitySteps.First)
  const [activityType, setActivityType] = useState(undefined);
  const [markSeen, , markPopupAsSeen] = DashboardService.useMarkPopupAsSeenApi();
  const [hasSeenPopup, , checkIfSeen] = DashboardService.useHasSeenPopupApi();

  useEffect(() => {
    checkIfSeen(2);
  }, []);

  let [datetime, setDatetime] = useState<any>({
    date: undefined,
    time: undefined
  })

  let [checks, setChecks] = useState<any>({
    all: true,
    aiga: false,
    certified: false
  })

  let [selectedRange, setSelectedRange] = useState<any>()
  const [customPrice, setCustomPrice] = React.useState<any>(0);

  const [validChecks, setValidChecks] = useState(false)

  const [opened, setOpened] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  let requestData: Request = useSelector((state: RequestReducer) => state.requestReducer.requestData)
  const [estimationResponse, error, estimationRequest] = ActivitiesService.useGetEstimationsAPI();

  let dispatch = useDispatch()
  let history = useHistory()

  const openedType: ActivityModal =
  {
    img: modalGirl,
    title: strings.requestActivities.modal.opened.title,
    copy: strings.requestActivities.modal.opened.copy,
    cta: strings.requestActivities.modal.opened.cta,
    ctaUndo: strings.requestActivities.modal.opened.ctaUndo,
    onConfirm: () => { setOpened(false); requestRequest(requestData); setIsLoading(true) },
    onCancel: () => setOpened(false),
  }


  const confirmedType: ActivityModal =
  {
    img: send,
    title: strings.requestActivities.modal.confirmed.title,
    copy: strings.requestActivities.modal.confirmed.copy,
    cta: strings.requestActivities.modal.confirmed.cta,
    ctaUndo: strings.requestActivities.modal.confirmed.ctaUndo,
    onConfirm: () => { history.push("/activity-request") },
    onCancel: () => { history.push("/dashboard") },
  }

  useEffect(() => {
    dispatch(setRequestData(new Request()))

    // let defaultSkill: Skill = new Skill()
    // defaultSkill.id = 12
    // defaultSkill.name = 'Generica'

    // dispatch(updateRequestData(FormFields.skill, defaultSkill))
  }, [])

  useEffect(() => {
console.log('A', requestData.skill)
  },[requestData])

  useEffect(() => {
    dispatch(updateRequestData(FormFields.money, customPrice))
  }, [customPrice])

  let minPrice = () => {
    let min_chancellery_value = 20
    let min_value = 35
    return requestData.type == 'CHANCELLERY' ? min_chancellery_value : min_value
  }

  useEffect(() => {
    setCustomPrice(minPrice())
    setSelectedRange(PriceRanges.custom)
  }, [activityType])


  useEffect(() => {
    if (!requestResponse) return

    AnalyticsService.screenName(AnalyticsScreens.activityRequestDone)

    setIsLoading(false)
    setConfirmed(true)
  }, [requestResponse])

  useEffect(() => {
    if (datetime.date && datetime.time) {
      let date = moment(datetime.date).format(DateFormat.server)
      let time = moment(datetime.time).format(DateFormat.time)
      dispatch(updateRequestData(FormFields.datetime, date + 'T' + time))

    } else {
      dispatch(updateRequestData(FormFields.datetime, undefined))
    }
  }, [datetime])

  useEffect(() => {
    setValidChecks(checks.all || checks.aiga || checks.certified)

    dispatch(updateRequestData(FormFields.only_aiga, checks.aiga))
    dispatch(updateRequestData(FormFields.only_certified, checks.certified))

  }, [checks])

  useEffect(() => {
    sendAnalyticsScreenName(step)

    if (step === 3) {
      document.querySelectorAll<HTMLElement>('.percentage-banner').forEach(node => node.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' }))
    } else if (step === 5) {
      document.querySelectorAll<HTMLElement>('.step4').forEach(node => node.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' }))
    }
  }, [step])

  let isSelectedRange = (range: any) => {
    return range === selectedRange
  }


  useEffect(() => {
    if (selectedRange === PriceRanges.none) {
      dispatch(updateRequestData(FormFields.money, null))
    }

  }, [selectedRange])


  let sendAnalyticsScreenName = (position: number) => {
    switch (position) {
      case 1: AnalyticsService.screenName(AnalyticsScreens.activityRequest1); break
      case 2: AnalyticsService.screenName(AnalyticsScreens.activityRequest2); break
      case 3: AnalyticsService.screenName(AnalyticsScreens.activityRequest3); break
      case 4: AnalyticsService.screenName(AnalyticsScreens.activityRequest4); break
      case 5: AnalyticsService.screenName(AnalyticsScreens.activityRequest5); break
    }
  }


  const handleIncrement = () => {
    setSelectedRange(PriceRanges.custom)

    if (customPrice < 50) {
      setCustomPrice(customPrice + 5);
    } else {
      setCustomPrice(customPrice + 10);
    }
  };

  const handleDecrement = () => {
    setSelectedRange(PriceRanges.custom)

    if (customPrice > 50) {
      setCustomPrice(customPrice - 10);
    } else if (customPrice > minPrice()) {
      setCustomPrice(customPrice - 5);
    }
  };

  let priceFilter = () => {
    return (
      <div className={'price__filter'}>

        <div className='price__selector'>
          <button
            className={classNames('date', 'button-filter_minus', customPrice == minPrice() ? 'button-filter_disabled' : '')}
            onClick={handleDecrement}>{'-'}</button>

          <span className={classNames('price__value ', { active: isSelectedRange(PriceRanges.custom) })}>{customPrice}€</span>

          <button
            className={classNames(['date', 'button-filter_plus'])}
            onClick={handleIncrement}>{'+'}</button>
        </div>

        <button
          className={classNames('button-filter date', { active: isSelectedRange(PriceRanges.none) })}
          onClick={() => setSelectedRange(PriceRanges.none)}>{strings.requestActivities.tbd}</button>

      </div>
    )
  }


  // RENDER

  let renderStep1 = () => {
    return (
      <div>
        <h3><span>{strings.requestActivities.step1.title}</span> {strings.requestActivities.step1.title2}</h3>
        <div className={'request__cta__container'}>
          {requestType.map((item: any, index: number) => {
            const isClicked = requestData.type === item.name

            return (
              <div
                key={String(index)}
                className={'cta__container'}
                onClick={() => {
                  dispatch(updateRequestData(FormFields.type, item.name))
                  setActivityType(item.name)
                }} >
                <img
                  className={isClicked ? 'active' : undefined}
                  src={isClicked ? item.selected : item.unselected}
                  alt={item.name} />

                <p className={isClicked ? 'active' : undefined}>{item.text}</p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  let renderStep2 = () => {
    return (
      <Fragment>
        {renderStep1()}
        <div className={'request__step__container'}>
          <div className={'request__steps'}>
            <div className={'request__activity'}>
              <h3>{strings.requestActivities.step2.title}<span>{strings.requestActivities.step2.title2}</span>{strings.requestActivities.step2.title3}</h3>

              <RequestField
                type={RequestFieldType.activity}
                value={requestData.activity}
                onChange={(option: SelectOption) => dispatch(updateRequestData(FormFields.activity, option.value))} />
              <p>{strings.requestActivities.obligatory}</p>
            </div>

            <div className="request__price__container">
              <h3>{strings.requestActivities.step2.fee}</h3>
              {priceFilter()}
              <p className={'disclaimer'}>{strings.activityPanel.activity.vat_cpa}</p>
            </div>
          </div>

          <form>
            <div className={'form__full'}>
              <h3>{strings.agenda.add.addAnnotation}</h3>
              <textarea
                placeholder={strings.fieldPlaceHolderAnnotation}
                value={requestData.notes}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => dispatch(updateRequestData(FormFields.notes, e.target.value))} />
            </div>
          </form>
        </div>
      </Fragment>
    )
  }

  let renderStep3 = () => {
    return (
      <Fragment>
        {renderStep2()}
        <div className={'request__step__container step3'}>
          <div className={'request__steps'}>

            <Fragment>
              <div className={'request__activity'}>
                <h3><span>{strings.requestActivities.step3.title}</span>{strings.requestActivities.step3.title2}</h3>
                <RequestField
                  type={RequestFieldType.office}
                  value={requestData.office}
                  onSelectedObject={(object) => {
                    dispatch(updateRequestData(FormFields.office, object.value))
                    dispatch(updateRequestData(FormFields.skill, undefined))
                   
                    estimationRequest(object.value.id)
                  }} />
                <p>{strings.requestActivities.obligatory}</p>
              </div>
            </Fragment>

            {/* <div className="request__input__container">
              <h3>{strings.requestActivities.step3.matter}</h3>
              <RequestField
                type={RequestFieldType.subject}
                value={requestData.subject}
                onChange={(option: SelectOption) => dispatch(updateRequestData(FormFields.subject, option.value))} />
            </div> */}

            <div className="request__input__container">
              <h3>{strings.requestActivities.step3.section}</h3>
              <RequestField
                type={RequestFieldType.section}
                value={requestData.section}
                onChange={(option: SelectOption) => dispatch(updateRequestData(FormFields.section, option.value))} />
            </div>

            <div className="request__input__container">
              <h3>{strings.requestActivities.step3.judge}</h3>
              <RequestField
                type={RequestFieldType.judge}
                value={requestData.judge}
                onChange={(option: SelectOption) => dispatch(updateRequestData(FormFields.judge, option.value))} />
            </div>

          </div>
        </div>
      </Fragment >
    )
  }


  interface Option {
    value: string;
    label: string;
    icon?: React.ReactNode;
  }

  

  // useEffect(() => {
  //   if (skillsResponse) setIsLoading(false);
  // }, [skillsResponse]);

  useEffect(() => {
    dispatch(updateRequestData(FormFields.money, customPrice))
  }, [customPrice])

  const [candidates, setCandidates] = useState<number>(0);

  useEffect(() => {
    if (requestData.office && requestData.skill && currentUser) {
      const skillId = requestData.skill.id;
      const officeId = requestData.office.id;

      if (typeof officeId === 'number') {
        //estimationRequest(officeId);
      }
    }
  }, [requestData.skill, requestData.office]);

  // useEffect(() => {
  //   if (estimationResponse) {
  //     setCandidates(estimationResponse.candidates);
  //   }
  // }, [estimationResponse]);


  const selectedSkill = requestData.skill ? new SelectOption(requestData.skill, requestData.skill.name) : undefined;

  const renderStep4 = () => {
    return (
      <div className={"skills-step-container"} style={{ paddingBottom: 160 }} >
        {renderStep3()}
        <div className={"text-container step4"}>
          <h3>
            <span>{strings.requestActivities.step4.title}</span>
          </h3>
          <div className={"subtitle-container"}>
            <p>{strings.requestActivities.step4.subtitle}</p>
            <span>{strings.requestActivities.step4.subtitle2}</span>
            <p>{strings.requestActivities.step4.subtitle3}</p>
            <span>{strings.requestActivities.step4.subtitle4}</span>
          </div>

          {/* <div className={"subtitle-container"}>
            <span>{strings.requestActivities.step4.subtitle5}</span>
          </div> */}

          

          <div className={'select-container'} style={{ marginTop: 8 }}>
            <Select<SelectOption>
              key={selectedSkill ? selectedSkill.value : 'placeholder'}
              defaultValue={selectedSkill}
              value={selectedSkill}
              placeholder={strings.requestField.placeholder.skill}
              noOptionsMessage={'Non ci sono competenze per il foro selezionato.'}
              isSearchable
              options={estimationResponse}
              onChange={(option: SelectOption) => {
                dispatch(updateRequestData(FormFields.skill, option.value))
                dispatch(updateRequestData(FormFields.is_delayed, true))}} />
          </div>

          {/* {(requestData.skill && candidates < SkillThreshold) && <div className={"input-help-text-container"}>
            <p className={"help-text"}>
              {strings.requestActivities.step4.helpText.subtitle}
            </p>
          </div>} */}

          {/* <div className={'info__container_secondary'} style={{ width: '65%' }}>
            <ProfileWrapper title={''}>
              <ProfileSwitch
                content={requestData.skill && candidates < SkillThreshold ? 'Invia a tutti gli avvocati del foro' : 'Estendi automaticamente a tutti gli avvocati del foro'}
                checked={requestData.is_delayed}
                onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.checked
                  if (value !== undefined) dispatch(updateRequestData(FormFields.is_delayed, value));
                }} />
            </ProfileWrapper>
          </div> */}
        </div>
      </div>
    );
  };


  let renderStep5 = () => {
    return (
      <div>
        {renderStep4()}
        <Fragment>
          <div className={'request__step__container'}>
            <div className={'request__steps'}>
              <div className={'request__activity'}>
                <h3><span>{strings.requestActivities.step5.title}</span>{strings.requestActivities.step5.title2}</h3>
                <form>
                  <div className={'request__date__container'}>
                    <DatePicker
                      placeholderText={strings.requestActivities.step5.date}
                      popperPlacement='right'
                      selected={datetime.date ? moment(datetime.date).toDate() : undefined}
                      onChange={(date: Date) => setDatetime({ ...datetime, date: date })}
                      locale="it"
                      dateFormat="eeee d MMMM" />
                    <DatePicker
                      placeholderText={strings.requestActivities.step5.time}
                      popperPlacement='right'
                      selected={datetime.time ? moment(datetime.time).toDate() : undefined}
                      onChange={(date: Date) => setDatetime({ ...datetime, time: date })}
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      timeIntervals={5}
                      timeCaption="Time"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm" />
                  </div>
                </form>
                <p>{strings.requestActivities.obligatory}</p>
              </div>
              <div className={'checkbox-container'}>

                <div className={'checkbox-content'}>
                  <div
                    className={classNames('check', { checked: checks.all, outline: checks.all })}
                    onClick={() => setChecks(
                      {
                        all: !checks.all,
                        aiga: false,
                        certified: false
                      }
                    )}>
                    <input type={'checkbox'} />
                    <span />
                  </div>
                  <p>{strings.requestActivities.step5.checkbox.all}</p>
                </div>

                <div className={'checkbox-content'}>
                  <div
                    className={classNames('check', { checked: checks.certified, outline: checks.certified })}
                    onClick={() => setChecks(
                      {
                        all: false,
                        aiga: false,
                        certified: !checks.certified
                      }
                    )}>
                    <input type={'checkbox'} />
                    <span />
                  </div>
                  <p>{strings.requestActivities.step5.checkbox.certified}</p>
                </div>

                {/* commented aiga checkbox*/}
                {/* {currentUser!.is_aiga && <div className={'checkbox-content'}>
                  <div
                    className={classNames('check', { checked: checks.aiga, outline: checks.aiga })}
                    onClick={() => setChecks(
                      {
                        all: false,
                        aiga: !checks.aiga,
                        certified: false
                      }
                    )}>
                    <input type={'checkbox'} />
                    <span />
                  </div>
                  <p>{strings.requestActivities.step5.checkbox.aiga}</p>
                </div>} */}

              </div>
            </div>
          </div>
        </Fragment>
      </div>
    )
  }

  let renderSteps = () => {
    switch (step) {
      case 1: return renderStep1()
      case 2: return renderStep2()
      case 3: return renderStep3()
      case 4: return renderStep4()
      case 5: return renderStep5()
    }
  }

  let validateStep = (step: number): any => {
    switch (step) {
      case 1: return requestData.type !== undefined
      case 2: return validateStep(1) && requestData.activity !== undefined
      case 3: return validateStep(2) && requestData.office !== undefined
      case 4: return validateStep(3) && requestData.skill !== undefined
      case 5: return validateStep(4) && requestData.datetime !== undefined
    }
  }

  let renderProgress = () => {
    return <progress value={step / RequestActivitySteps.Fifth} />
  }

  let onNext = async () => {
    if (step === RequestActivitySteps.Fifth) {
      AnalyticsService.screenName(AnalyticsScreens.activityRequestConfirm)

      setOpened(true)

    } else {
      setStep(step + 1)
    }
  }

  const steps: Step[] = [
    {
      title: 'Nuova ricerca per competenze',
      description: 'Da oggi potrai effettuare richieste rivolte a TUTTI oppure solamente a Colleghi con competenze specifiche',
      image: skillIcon,
      buttonText: 'Avanti',
      onClick: async () => {
        await markPopupAsSeen(2);
        window.location.reload();
      },
    }
  ]

  // Use this function for handle guest users. 
  // If guests, show the new request results and the light register form.
  // Otherwise, continue with standard flow.

  let checkUserForConfirm = () => {
    if (!opened) return null

    if (currentUser) {
      return <ConfirmModal content={openedType} />
    }
  }


  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={"requestActivities__container"}>
          <h2>{strings.requestActivities.title}</h2>
          {renderProgress()}
          <div className={"requestActivities"}>{renderSteps()}</div>

          {checkUserForConfirm()}
          {confirmed && <ConfirmModal content={confirmedType} />}
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '40px', marginBottom: '20px' }}>
          <button
            style={{ width: "120px" }}
            className={`button-primary ${!validateStep(step) ? "disabled" : ""}`}
            disabled={!validChecks || !validateStep(step)}
            onClick={onNext}
          >
            {isLoading ? (
              <span className={"button-loading"} />
            ) : (
              strings.requestActivities.cta
            )}
          </button>
        </div>
      </div>
      {hasSeenPopup == false && <UpdatesModal steps={steps} />}
    </>
  );
};

export default RequestActivities;
