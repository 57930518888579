import React, { useEffect, useState } from 'react';
import { Skill } from 'store/types/Profile';
import classNames from 'classnames';
import _ from 'lodash';
import { SkillsType } from 'values/values';
import strings from 'values/strings';
import { useSelector } from 'react-redux';
import { IUserReducer } from 'store/reducers/UserReducer';

interface Props {
  skills: Array<Skill>;
  max?: number;
  onSave?: (skills: Array<Skill>) => void;
  type?: SkillsType;
  otherSelectedSkills?: Array<Skill>
  allSkills?: Array<Skill>
}

const Checkboxes: React.FC<Props> = ({ skills, max, onSave, type, otherSelectedSkills, allSkills }) => {

  const skillsType = () => {
    if (type === SkillsType.BASIC) {
      return 'BASIC'
    } else if (type === SkillsType.SKILLS) {
      return 'ADDITIONAL_SKILLS'
    } else if (type === SkillsType.PROFESSIONAL) {
      return 'PROFESSIONAL'
    }
  }

  const isMain = () => {
    if (type === SkillsType.BASIC) {
      return true
    } else if (type === SkillsType.SKILLS) {
      return false
    } else if (type === SkillsType.PROFESSIONAL) {
      return false
    }
  }

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

  const [filteredAllSkills, setFilteredAllSkills] = useState<Array<Skill>>([]);
  const [selectedSkills, setSelectedSkills] = useState<Array<Skill>>([]);
  const [filteredSelectedSkills, setFilteredSelectedSkills] = useState<Array<Skill>>([]);

  const filteredSkills = filteredAllSkills ? filteredAllSkills.filter(skill => skill.name !== 'Generica') : [];



  useEffect(() => {
    if (!currentUser || !allSkills) return
    const _userTypedSkills = allSkills.filter(s => currentUser.profile.skills.some(x => s.id === x.id && x.type == skillsType()))
    const _filteredAllSkills = _userTypedSkills.concat(allSkills.filter(s => !currentUser.profile.skills.some(x => s.id === x.id)))
    setFilteredAllSkills(_filteredAllSkills ? _filteredAllSkills : [])

  }, [currentUser, allSkills])

  useEffect(() => {
    setFilteredSelectedSkills(selectedSkills ? selectedSkills.filter(skill => skill.type !== 'Generica' && skill.type == skillsType()) : [])
  }, [selectedSkills])

  const handleSelection = (skill: Skill, selected: boolean) => {

    let _skill = {
      ...skill,
      is_main: isMain(),
      description: '',
      type: skillsType()
    }

    if (type === SkillsType.BASIC) {
      const allNonBasic = otherSelectedSkills!.filter((s) => s.type != 'BASIC' || s.name == 'Generica')!
      const updatedSkills = !selected ? [...allNonBasic, _skill] : otherSelectedSkills!.filter((s) => _skill.id !== s.id);
      setSelectedSkills(updatedSkills);

    } else {
      if (max && filteredSelectedSkills.length >= max && !selected) return;
      const updatedSkills = !selected ? [...selectedSkills, _skill] : selectedSkills.filter((s) => _skill.id !== s.id);
      setSelectedSkills(updatedSkills);
    }
  };

  useEffect(() => {
    setSelectedSkills(otherSelectedSkills!)
  }, [skills, otherSelectedSkills])



  return (
    <div>
      <div className={"checkbox-container"}>
        {filteredSkills.map((skill: Skill) => {
          const selected = _.find(selectedSkills, { id: skill.id }) !== undefined;
          return (
            <div key={skill.id} className={classNames("checkbox-content")}>
              <p>{skill.name}</p>
              <div
                className={classNames("check", { checked: selected })}
                onClick={() => handleSelection(skill, selected)}>
                <input type={"checkbox"} checked={selected} readOnly />
                <span />
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ alignSelf: 'end', marginTop: 24 }}>
        <button className={'skill-button'} disabled={false && type === 'basic'} onClick={() => { onSave && onSave(selectedSkills) }}>
          {strings.save}
        </button>
      </div>
    </div>
  );
}

export default Checkboxes;
