import React, { FC } from 'react'
import plusIcon from '../../image/plus-icon.svg' 
import collegaWhite from '../../image/collega-white.svg';
import colors from 'values/colors';

type EmptyProfessionalBannerProps = {
  action: () => void
}

const EmptyProfessionalBanner: FC<EmptyProfessionalBannerProps> = ({ action }) => {
  return (
    <div className="banner-container-professional"  style={{cursor: 'pointer'}} onClick={action}>
      
      <div className="banner-content">
        <img src={collegaWhite} alt="collega" style={{ fill: 'white', height: 32, marginBottom: 12 }}  />
        <div className="banner-title">{'Acquista Collega Professional'}</div>
        <div className="banner-subtitle">{'Sfrutta le potenzialità di un profilo\nCollega Professional.'}</div>
      </div>
      
      <button className="banner-button">
        <img src={plusIcon} alt="plus-icon" />
      </button>
    </div>
  )
}

export default EmptyProfessionalBanner