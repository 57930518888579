import React, {ChangeEvent, Fragment, MouseEvent, useEffect, useState} from 'react';

import strings from '../values/strings';
import {Link} from 'react-router-dom';

import logo from '../image/logo-collega.svg';
import login from '../image/login__image.svg';
import close from '../image/icons/close.svg';

import UserService from '../services/UserServices';
import Utility from '../utils/utils';
import Network from '../network/Network';
import {FormFields} from '../values/values';
import {useSelector} from 'react-redux';
import {IAppReducer} from '../store/reducers/AppReducer';
import classNames from 'classnames';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';


const Forgot: React.FC = () => {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();
  const [success, error, request] = UserService.useResetPasswordAPI();
  const [showPopup, setShowPopup] = useState(false);


  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);

  // Effect

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.forgotPassword)
  }, [])
  
  useEffect(() => {
    if (success) {
      setShowPopup(true);
      setEmail('');
      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    }
  }, [success]);

  useEffect(() => {
    setEmailError(Network.retrieveError(FormFields.email, error));
  }, [error]);

  // Action

  const handleDismissPopup = (event: MouseEvent) => {
    event.preventDefault();
    setShowPopup(false);
  };

  const onForgotPassword = (event: MouseEvent) => {
    event.preventDefault();
    if (!isLoading) {
      request(email);
    }
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setShowPopup(false);
  };

  const enableButton = Utility.validateEmail(email) || Utility.validatePhone(email);

  return (
    <Fragment>
      <div className={'header-login'}>
        <img className={'logo'} src={logo} alt="Logo collega" />
      </div>
      <div className={'wrapper-login'}>
        <div className={'login'} style={{paddingTop: '90px'}}>
          <div style={{position: 'relative'}} className={'login__form'}>
          {showPopup && 
          <div className={'login__modal'}>
            <button
              className={'close'}
              title={strings.forgotPassword.popup.close}
              onClick={handleDismissPopup}
            >
              <img src={close} alt="Logo collega" />
            </button>
            <p className={'title'}>{strings.forgotPassword.popup.title}</p>
            <p className={'copy'}>{strings.forgotPassword.popup.text}</p>
          </div>
          }
            <h1 style={{marginBottom: 16}}>{strings.forgotPassword.headerTitle}</h1>
            <p style={{marginTop: 0, marginBottom: 32}}>{strings.forgotPassword.text}</p>
            <form>
              <label>{strings.fieldLabelEmail}</label>
              <input
                type="email"
                name="email"
                autoFocus={true}
                className={emailError ? 'input-error' : undefined}
                placeholder={strings.fieldPlaceholderEmail}
                value={email}
                onChange={handleChangeEmail}
                readOnly={isLoading}
              />
              {emailError && <p className={'error'}>{emailError}</p>}

              <button
                className={`button-primary ${!enableButton ? 'disabled' : ''}`}
                disabled={!enableButton}
                onClick={onForgotPassword}
              >
                <span className={classNames({unvisible: isLoading})}>{strings.forgotPassword.actionConfirm}</span>
                {isLoading && <span className={'button-loading'} />}
              </button>
              <p><Link to="SignIn">{strings.forgotPassword.actionBackToLogin}</Link></p>
            </form>
          </div>
          <div className={'login__image'}>
            <img src={login} alt="Logo collega" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Forgot;
