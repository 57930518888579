import * as firebase from 'firebase/app';
import Storage from 'store/Storage';


export default class AnalyticsService {

    static sendUID = async () => {
        let UID = await Storage.get(Storage.USER_ID)

        console.log('Analytics send UID:', UID)
        firebase.analytics().setUserId(`${UID}`)
    }

    static screenName = async (screen: AnalyticsScreens | string) => {
        AnalyticsService.sendUID()

        let lastScreen = await Storage.get(Storage.LAST_ANALYTICS_SCREEN)
        if (screen === lastScreen) return
        Storage.set(Storage.LAST_ANALYTICS_SCREEN, screen)

        console.log('Analytics Screen:', screen)
        firebase.analytics().setCurrentScreen(screen)
    }

    static event = async (event: AnalyticsEvents, data: any) => {
        AnalyticsService.sendUID()

        console.log('Analytics Event:', event, data)
        firebase.analytics().logEvent(event, data)
    }

}


// VALUES

export enum AnalyticsEvents {
    activityOpen = 'activity_opened',
    newsOpen = 'news_opened',
    notificationOpen = 'notification_opened',
    recordOpen = 'record_opened',

    card = 'card',

    shop = 'collega_shop',

    iSkilled = 'formazione_professionista',
    share = 'share_collega_app',

    support = 'needs_support',
    dynamic_link = 'dynamic_link',

    registerEdit = 'register_edit',

    agendaEventUpdate = 'agenda_event_updated',
    agendaNewEvent = 'agenda_event_create',
    agendaFilters = 'agenda_filters',

    recordShare = 'record_share',

    activityCompleted = 'activity_completed',
    activityPaid = 'activity_paid',

    dashboardActivityTab = 'dashboard_activity_tab',

    profileCompleteClicked = 'profile_complete_clicked',
    profileContact = 'profile_contact',

}

export enum AnalyticsScreens {
    // Onboarding new
    onboarding = '1.0 OnBoarding',

    // Login
    login = '2.1 Accesso - Login',
    forgotPassword = '2.2 Accesso - Recupera password',

    // Register
    register1 = '3.1 Registrazione - step 1',
    register2 = '3.1.5 Registrazione - step 2',
    register3 = '3.3 Registrazione - step 3',
    register4 = '3.4 Registrazione - step 4',
    registerSummary = '3.5 Registrazione - step 5 - Riepilogo',
    registerSummaryEdit = '3.5 Registrazione - step 5 - Riepilogo - Modifica',
    registerDone = '3.6 Registrazione - step 6 - Welcome page',

    dashboard = '6.0.1 Dashboard',

    // Profile
    profile = '11.3 – Profilo Privato',
    profilePublic = '8.1.7 – Attività - Dettaglio Profilo',
    profileEdit = '11.1.2 – Profilo privato - Modifica',
    profileSkills = '14.2.1 - Competenze aggiuntive',
    profileCity2 = '14.3 – Secondo comune',
    profileCertification = '14.1.1 - Verifica Dati',
    profileCertificationDone = '14.1.5 - Pagamento - completo',
    profileCertified = '11.2.2 – Profilo certificato - details',

    profileCompletion1 = '11.3.1 Profilo privato completa  - step 1',
    profileCompletion2 = '11.3.3 Profilo privato completa  - step 2',
    profileCompletion3 = '11.3.4 Profilo privato completa  - step 3',
    profileCompletion4 = '11.3.5 Profilo privato completa  - step 4',
    profileCompletion5 = '11.3.6 Profilo privato completa  - step 5',
    profileCompletion6 = '11.3.7 Profilo privato completa  - step 6',

    // News
    news = '12.1 - News',

    // Courses
    courses = '16.0 - Elenco Corsi',
    purchasedCourses = '16.0.1 - Corsi acquistati',
    courseDetail = '16.0.5 - Dettaglio corso',
    packageDetail = '16.2.2 - Dettaglio Package',
    purchasedCourseDetail = '16.1.2 - Dettaglio corso acquistato',
    coursesForm = '16.0.8 - Iscrizione - Verifica dati',
    packageForm = '16.2.4 - Package Dati',
    coursesPayPal = '16.0.10 - Iscrizione - Paga con PayPal',
    packagePayPal = '16.2.5 - Package Riepilogo - Paga con PayPal',
    coursesSuccess = '16.0.12 - Iscrizione - Success',
    packageSuccess = '16.2.6 - Pacchetto acquistato',
    coursesPopup = '16.0.7 - Popup accesso',

    // Notifications
    notification = '5.1.0 - Notifiche',

    // Agenda
    agenda = '9.1.1 – Agenda - Calendario',
    agendaAdd = '9.3.1 – Agenda - Creazione Evento',
    agendaEvent = '9.6.1 – Agenda - Dettaglio Evento',
    agendaEventPostpone = '9.9.1 – Agenda - Rinvia Udienza',
    agendaSearch = '9.9.2 – Agenda - Cerca',

    // Verbali
    records = '10.0.1 – Verbali',

    // Activities
    activitiesDemands = '8.1.0 – Attività richieste',
    activitiesDemands1 = '8.1.1 – Attività richieste - Da Assegnare',
    activitiesDemands2 = '8.1.2 – Attività richieste - Assegnate',
    activitiesDemands3 = '8.1.4 – Attività richieste - Concluse da Pagare',
    activitiesDemands4 = '8.1.5 – Attività richieste - Concluse Pagate',
    activitiesDemands5 = '8.1.6 – Attività richieste - Annullate',
    activitiesDemands6 = '8.1.7 – Attività richieste - Scadute',

    activitiesCandidates = '8.2.0 – Attività Disponibili',
    activitiesCandidates1 = '8.2.1 – Attività Disponibili - Nuove',
    activitiesCandidates2 = '8.2.2 – Attività Disponibili - In Attesa di Assegnazione',
    activitiesCandidates3 = '8.2.3 – Attività Disponibili - Rifiutate',
    activitiesCandidates4 = '8.2.4 – Attività Disponibili - Non Assegnate',

    activitiesReceivedDemands = '8.3.0 – Attività assegnate',
    activitiesReceivedDemands1 = '8.3.1 – Attività assegnate - Assegnate',
    activitiesReceivedDemands2 = '8.3.2 – Attività assegnate - Concluse in Attesa di Pagamento',
    activitiesReceivedDemands3 = '8.3.3 – Attività assegnate - Concluse e Pagate',

    activityDetail = '8.1.6 – Attività - Dettaglio',

    activityRequest1 = '7.1.0 – Step 1 - Chi stai cercando',
    activityRequest2 = '7.2.0 – Step 2 - Per quale attività',
    activityRequest3 = '7.3.0 – Step 3 - Dove',
    activityRequest4 = '7.4.0 – Step 4 - Competenze',
    activityRequest5 = '7.5.0 – Step 5 - Quando',
    activityRequestConfirm = '7.6.0 - Step 6 Conferma invio',
    activityRequestDone = '7.6.1 - Step 6 richiesta inviata',

    //Popup
    popupCancelSubscription = '11.2.3 – Popup annulla abbonamento',
    popupNewRecord = '10.1 – Nuovo Verbale - Conferma',
    popupUncompletedProfile = '8.2.7 –  Alert - Completa profilo',
    popupEventDelete = '9.7.1 – Agenda - Elimina Evento',
}