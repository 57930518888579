import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { IUserReducer, createTempUser, goInSignUpReview, outSignUpReview, clearTempUser } from '../store/reducers/UserReducer';
import Utility from '../utils/utils';
import StepFirst from './signup/StepFirst';
import StepSecond from './signup/StepSecond';
import StepThird from './signup/StepThird';
import StepFourth from './signup/StepFourth';
import StepReview from './signup/StepReview';
import UserService from '../services/UserServices';

import logo from '../image/logo-collega.svg';
import signUp from '../image/registration__image.svg';
import welcome from '../image/registration__image.svg';
import review from '../image/review__image.svg';
import { FormFields } from '../values/values';
import { ISignUpError } from './signup/interface/SignupInterface';
import StepWelcome from './signup/StepWelcome';
import { IAppReducer } from '../store/reducers/AppReducer';
import AnalyticsService, { AnalyticsEvents } from 'services/AnalyticsService';

enum Step {
  first, second, third, fourth, review, welcome
}

const SignUp: React.FC = () => {
  const [step, setStep] = useState(Step.first);

  // Validation step field
  const [isValidType, setIsValidType] = useState(true);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEMail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [unHidePassword, setUnHidePassword] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isValidCity, setIsValidCity] = useState(true);

  const initError: ISignUpError = {};
  const [serverError, setServerError] = useState(initError);

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
  const isInReview = useSelector((state: IUserReducer) => state.user.registerInReview);
  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);


  const [registerResponse, registerError, registerRequest] = UserService.useRegister();

  const dispatch = useDispatch();

  // Lifecycle
  useEffect(() => {
    dispatch(createTempUser());
  }, []);

  useEffect(() => {
    if (isInReview) AnalyticsService.event(AnalyticsEvents.registerEdit, undefined)
  }, [isInReview]);

  useEffect(() => {
    if (registerResponse) {
      goToWelcome()
    }
  }, [registerResponse]);

  useEffect(() => {
    if (registerError) {
      setServerError({ ...registerError });
    }
  }, [registerError]);

  useEffect(() => {
    if (registerError) {
      Object.keys(registerError).forEach((key) => {
        switch (key) {
          case FormFields.type:
            setIsValidType(false);
            break;
          case FormFields.first_name:
            setIsValidFirstName(false);
            break;
          case FormFields.last_name:
            setIsValidLastName(false);
            break;
          case FormFields.email:
            setIsValidEmail(false);
            break;
          case FormFields.password1:
            setIsValidPassword(false);
            break;
        }
      });
    }
  }, [registerError]);


  const validateStep = (t: Step): boolean => {
    if (!tempUser) {
      return false;
    }

    switch (t) {
      case Step.first:
        return tempUser.validateType() && tempUser!.validateName();

      case Step.second:
        return Utility.validateEmail(tempUser!.email) &&
          Utility.validatePassword(tempUser!.password1) &&
          tempUser!.validateFlag();

      case Step.third:
        return !!tempUser!.profile.lawfirm.city

      case Step.fourth:
        return Utility.validatePhone(tempUser!.phone);

      case Step.review:
        return tempUser!.checkSignUpValidity();

      default:
        return false;
    }
  };

  const goToStepFirst = () => {
    setStep(Step.first);
  };

  const goToStepSecond = () => {
    if (validateStep(Step.first)) {
      setStep(Step.second);
    }
  };

  const goToStepThird = () => {
    if (validateStep(Step.second)) {
      setStep(Step.third);
    }
  };

  const goToStepFour = () => {
    if (validateStep(Step.third)) {
      setStep(Step.fourth);
    }
  };

  const goToReview = () => {
    if (validateStep(Step.fourth)) {
      dispatch(goInSignUpReview());
      setStep(Step.review);
    }
  };

  const goToWelcome = () => {
    setStep(Step.welcome);
    dispatch(outSignUpReview());
  };

  const createAccount = () => {
    if (!isLoading && validateStep(Step.review)) {
      registerRequest(tempUser!);
    }
  };

  const goToNextStep = () => {
    if (validateStep(step)) {
      switch (step) {
        case Step.first:
          goToStepSecond();
          break;
        case Step.second:
          goToStepThird();
          break;
        case Step.third:
          goToStepFour();
          break;
        case Step.fourth:
          goToReview();
          break;
        case Step.review:
          createAccount();
          break;
      }
    }
  };

  const goToPreviousStep = () => {
    switch (step) {
      case Step.second:
        goToStepFirst();
        break;
      case Step.third:
        goToStepSecond();
        break;
      case Step.fourth:
        goToStepThird();
        break;
    }
  };
  if (step === Step.first && currentUser) {
    return (<Redirect to={'/'} />);
  }

  return (
    <Fragment>
      <div className={classNames({ 'header-signup': step !== Step.review, 'header-review': step === Step.review })}>
        <img className={'logo'} src={logo} alt="Logo collega" />
      </div>
      <div className={'wrapper-login'}>
        {isInReview && (
          <div className={'signup reviewStep'}>
            <div className={'signup__image reviewStep'}>
              <img src={review} alt="splash" />
            </div>
            <StepReview
              first_name={{ valid: isValidFirstName, setValidity: setIsValidFirstName }}
              last_name={{ valid: isValidLastName, setValidity: setIsValidLastName }}
              email={{ valid: isValidEMail, setValidity: setIsValidEmail }}
              password={{ valid: isValidPassword, setValidity: setIsValidPassword }}
              unHidePassword={{ valid: unHidePassword, setValidity: setUnHidePassword }}
              phone={{ valid: isValidNumber, setValidity: setIsValidNumber }}
              city={{ valid: isValidCity, setValidity: setIsValidCity }}
              serverError={{ error: serverError, setError: setServerError }}
              next={goToNextStep}
            />
          </div>
        )}
        {!isInReview && (
          <div className={'signup'}>
            <div className={'signup__image'}>
              <img src={step !== Step.welcome ? signUp : welcome} alt="splash" />
            </div>

            {tempUser && (
              <div className={'signup__form'}>
                {/*STEP 1*/}
                {step === Step.first && (
                  <StepFirst
                    type={{ valid: isValidType, setValidity: setIsValidType }}
                    first_name={{ valid: isValidFirstName, setValidity: setIsValidFirstName }}
                    last_name={{ valid: isValidLastName, setValidity: setIsValidLastName }}
                    next={goToNextStep}
                  />
                )}

                {/*STEP 2*/}
                {step === Step.second && (
                  <StepSecond
                    email={{ valid: isValidEMail, setValidity: setIsValidEmail }}
                    password={{ valid: isValidPassword, setValidity: setIsValidPassword }}
                    unHidePassword={{ valid: unHidePassword, setValidity: setUnHidePassword }}
                    next={goToNextStep}
                  />
                )}

                {/*STEP 3*/}
                {step === Step.third && <StepThird
                  city={{ valid: isValidCity, setValidity: setIsValidCity }}
                  next={goToNextStep}
                />}

                {/*STEP 4*/}
                {step === Step.fourth && <StepFourth
                  phone={{ valid: isValidNumber, setValidity: setIsValidNumber }}
                  next={goToNextStep}
                />}

                {step !== Step.welcome && (
                  <div className={'pagination'}>
                    <button tabIndex={-1} className={classNames('back', { unvisible: step === Step.first })}
                      onClick={goToPreviousStep} />

                    <div className={'pagination__pagination'}>
                      <span
                        className={classNames({ active: step === Step.first })}
                        onClick={goToStepFirst}
                      />
                      <span
                        className={classNames({ active: step === Step.second, disabled: !validateStep(Step.first) })}
                        onClick={goToStepSecond}
                      />
                      <span
                        className={classNames({ active: step === Step.third, disabled: !validateStep(Step.second) })}
                        onClick={goToStepThird}
                      />
                      <span
                        className={classNames({ active: step === Step.fourth, disabled: !validateStep(Step.third) })}
                        onClick={goToStepFour}
                      />
                    </div>
                    <button
                      className={classNames('button-primary', { disabled: !validateStep(step) })}
                      onClick={goToNextStep}
                    >
                      <span>Avanti</span>
                    </button>
                  </div>
                )}
              </div>
            )}

            {step === Step.welcome && (
              <div className={'signup__form'}>
                <StepWelcome />
              </div>
            )}

          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SignUp;
