import React, { useEffect } from 'react';
import Storage from '../store/Storage';
import logo from '../image/logo-collega.svg';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import _ from 'lodash';
import SubscriptionService from 'services/SubscriptionService';
import UserService from 'services/UserServices';
import { useSelector } from 'react-redux';
import { ISubscriptionReducer } from 'store/reducers/SubscriptionReducer';
import toast from 'react-hot-toast';


interface RouterProps {
  token: string
}

interface Props extends RouteComponentProps<RouterProps> { }

const SubscriptionActivate: React.FC<Props> = ({ history }) => {

  const [activateSubscriptionResponse, activateSubscriptionError, activateSubscriptionRequest] = SubscriptionService.useActivateSubscriptionAPI()
  const [userResponse, userError, userRequest] = UserService.useUserAPI();

  const location = useLocation();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const subscription_id = queryParams.get('subscription_id');
    const approval_token_id = queryParams.get('approval_token_id');
    const plan_id = queryParams.get('plan_id');
    const payment_method = queryParams.get('payment_method')
    const variant_id = queryParams.get('variant_id');
    const discount_code = queryParams.get('discount_code')
    const SDI = queryParams.get('sdi')
    const city_id = queryParams.get('city_id')
    const card_form_data = queryParams.get('card_form_data')
    const token: string | null = queryParams.get('auth_token')

    if (token != undefined) {
      Storage.set(Storage.AUTH_TOKEN, token).then(() => {

        if (approval_token_id != undefined) { // PayPal
          console.log('Approval Token ID:', approval_token_id);
          activateSubscriptionRequest(Number(subscription_id), approval_token_id, Number(plan_id), payment_method ? payment_method : undefined, Number(variant_id), discount_code ? discount_code : undefined, Number(city_id), undefined, SDI != undefined && !_.isEmpty(SDI) ? SDI : '0000000')

        } else if (card_form_data != undefined) { // Credit Card
          console.log("FFRFRFRF")
          activateSubscriptionRequest(Number(subscription_id), undefined, Number(plan_id), payment_method ? payment_method : undefined, Number(variant_id), discount_code ? discount_code : undefined, Number(city_id), card_form_data, SDI != undefined && !_.isEmpty(SDI) ? SDI : '0000000')

        } else { // Bank Transfer
          console.log("HEEREEEE")
          activateSubscriptionRequest(Number(subscription_id), undefined, Number(plan_id), payment_method ? payment_method : undefined, Number(variant_id), discount_code ? discount_code : undefined, Number(city_id), undefined, SDI != undefined && !_.isEmpty(SDI) ? SDI : '0000000')
        }
      })
    }

  }, [location]);

  useEffect(() => {
    if (activateSubscriptionResponse) {
      userRequest()
    }
  }, [activateSubscriptionResponse])

  useEffect(() => {
    if (activateSubscriptionError) {
      toast.error(activateSubscriptionError.message)
      history.replace(`/dashboard?subscription_error=${JSON.stringify(activateSubscriptionError)}`)
    }
  }, [activateSubscriptionError])

  useEffect(() => {
    if (userResponse) {
      history.replace(`/dashboard?subscription=${JSON.stringify(activateSubscriptionResponse)}`)
    }
  }, [userResponse])


  return (
    <div className={'loading-wrapper'}>
      <div className={'logo-container'}>
        <img className={'logo'} src={logo} alt="Logo collega" />
      </div>
      <h1>{"Attivazione abbonamento in corso, attendere.."}</h1>
      <div className={'loader'} />
    </div>
  );
};

export default SubscriptionActivate;
