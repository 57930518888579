import { Variant } from 'store/types/Variant';
import { SubscriptionInfo } from 'store/types/SubscriptionInfo';

const SET_CITY_ID = 'SET_CITY_ID';
const SET_PRICE = 'SET_PRICE';
const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
const SET_SELECTED_VARIANT = 'SET_SELECTED_VARIANT';
const SET_PROMO_DISCOUNT = 'SET_PROMO_DISCOUNT';
const SET_PROMO_PERCENTAGE = 'SET_PROMO_PERCENTAGE';
const SET_PROMO_CODE = 'SET_PROMO_CODE';

export interface ISubscriptionReducer {
  subscription: ISubscriptionReducerState;
}

export interface ISubscriptionReducerState {
  promoDiscount?: string
  promoPercentage?: number
  promoCode?: string
  variant?: Variant
  city_id?: number
  price?: number
  subscription?: SubscriptionInfo
}


// ACTIONS

export function setPromoPercentage(value: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PROMO_PERCENTAGE,
      promoPercentage: value
    });
  };
}

export function setPromoDiscount(value: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PROMO_DISCOUNT,
      promoDiscount: value
    });
  };
}

export function setPromoCode(value: string) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PROMO_CODE,
      promoCode: value
    });
  };
}

export function setCityId(city_id?: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_CITY_ID,
      city_id
    });
  };
}

export function setPrice(price?: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_PRICE,
      price: price
    });
  };
}

export function setSubscription(subscription?: SubscriptionInfo) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_SUBSCRIPTION,
      subscription: subscription
    });
  };
}

export function setSelectedVariant(variant?: Variant) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_SELECTED_VARIANT,
      variant
    });
  };
}


// REDUCER
export default function (state: ISubscriptionReducerState = {}, action: any) {
  switch (action.type) {
    case SET_PROMO_DISCOUNT:
      return {
        ...state,
        promoDiscount: action.promoDiscount
      };

    case SET_PROMO_PERCENTAGE:
      return {
        ...state,
        promoPercentage: action.promoPercentage
      };

    case SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.promoCode
      };

    case SET_CITY_ID:
      return {
        ...state,
        city_id: action.city_id
      };

    case SET_PRICE:
      return {
        ...state,
        price: action.price
      };

    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.subscription
      };

    case SET_SELECTED_VARIANT:
      return {
        ...state,
        variant: action.variant
      };

    default:
      return state;
  }
}
