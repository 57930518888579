import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Storage from '../store/Storage';
import { setCurrentUser, createTempUser } from '../store/reducers/UserReducer';
import logo from '../image/logo-collega.svg';
import strings from 'values/strings';
import { setCurrentCourse, setCurrentPackage, setPurchasedCourse } from 'store/reducers/SidePanelReducer';

const Logout: React.FC = () => {

  const dispatch = useDispatch();

  const logout = async () => {
    await Storage.set(Storage.AUTH_TOKEN, null);
    await Storage.set(Storage.USER_ID, null)

    dispatch(setCurrentCourse(undefined));
    dispatch(setCurrentPackage(undefined));
    dispatch(setPurchasedCourse(undefined));
    dispatch(setCurrentUser(undefined));
    dispatch(createTempUser(undefined));
  };

  useEffect(() => {
    logout();
  }, []);



  return (
    <div className={'loading-wrapper'}>
      <div className={'logo-container'}>
        <img className={'logo'} src={logo} alt="Logo collega" />
      </div>
      <h1>{strings.loading}</h1>
      <div className={'loader'} />
    </div>
  );
};

export default Logout;
