import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventsService from '../../services/EventService';
import { IAgendaReducer, showDeleteModal } from '../../store/reducers/AgendaReducer';
import strings from 'values/strings';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';

const CommitmentDeleteModal: React.FC = () => {

  const [, , deleteRequest] = EventsService.useDeleteEventAPI();
  const currentEvent = useSelector((state: IAgendaReducer) => state.agenda.event);
  const dispatch = useDispatch();

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.popupEventDelete)
  }, [])

  return (
    <Fragment>
      <div className={'modal opened'}>
        <div className={'modal__content'}>
          <p className={'title'}>{strings.agenda.modal.title}</p>
          <p className={'copy'}>{strings.agenda.modal.copy}</p>

          <button className={'button-primary'} onClick={async () => {
            if (currentEvent) {
              AnalyticsService.screenName(AnalyticsScreens.agendaEvent)
              await deleteRequest(currentEvent);
            }
            dispatch(showDeleteModal(false));
          }}>
            <span>{strings.agenda.modal.cta}</span>
          </button>
          <button className={'cancel'} onClick={() => {
            AnalyticsService.screenName(AnalyticsScreens.agendaEvent)
            dispatch(showDeleteModal(false));
          }}>
            <span>{strings.agenda.modal.ctaUndo}</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default CommitmentDeleteModal;
