import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import { IAppReducer, tabThirdClose } from '../../store/reducers/AppReducer';
import Page from '../../store/types/Page';
import { DateFormat, FormFields, NoticeType, PartsType, ResourceType } from '../../values/values';
import SelectOption from '../../store/types/SelectionOption';
import EventsService from '../../services/EventService';
import InputAutosuggest, { CancelableInputSuggestField } from '../common/AutoSuggest';
import strings from '../../values/strings';
import { CancelableInputField } from '../common/Input';
import Select, { CancelableSelectField } from '../common/Select';
import Utility from '../../utils/utils';
import Event from '../../store/types/Event';
import Network from '../../network/Network';

import { IAgendaReducer, resetEvents, setCurrentDay } from '../../store/reducers/AgendaReducer';
import AnalyticsService, { AnalyticsScreens, AnalyticsEvents } from 'services/AnalyticsService';

const options = [
  new SelectOption<ResourceType>(ResourceType.Hearing, 'Udienza'),
  new SelectOption<ResourceType>(ResourceType.Appointment, 'Appuntamento'),
  new SelectOption<ResourceType>(ResourceType.Fulfillment, 'Adempimento')
];

interface IShowField {
  nrg: boolean;
  judge: boolean;
  office: boolean;
  expense1: boolean;
  expense2: boolean;
  expense3: boolean;
  notice: boolean;
}

interface ICommitmentForm {
  stateEvent?: Event;
  currentEvent?: Event;
}

const extendedMoment = extendMoment(Moment);


const CommitmentForm: React.FC<ICommitmentForm> = ({ stateEvent, currentEvent }) => {

  const page = useSelector((state: IAppReducer) => state.app.page);
  const dateEvents = useSelector((state: IAgendaReducer) => state.agenda.events);

  const [eventDate, setEventDate] = useState<Date>(currentEvent ? moment(currentEvent.date).toDate() : new Date());
  const [startTime, setStartTime] = useState<Date>(currentEvent ? (currentEvent.all_day ? moment(new Date()).set('hour', 9).set('minute', 0).toDate() : moment(currentEvent.start, DateFormat.time).toDate()) : moment(new Date()).set('hour', 9).set('minute', 0).toDate());
  const [endTime, setEndTime] = useState<Date>(currentEvent ? (currentEvent.all_day ? moment(new Date()).set('hour', 9).set('minute', 30).toDate() : moment(currentEvent.end, DateFormat.time).toDate()) : moment(new Date()).set('hour', 9).set('minute', 30).toDate());
  const [allDay, setAllDay] = useState(currentEvent ? currentEvent.all_day : false);
  const [part1, setPart1] = useState(currentEvent ? currentEvent.lawyerevent.part1 || '' : '');
  const [part2, setPart2] = useState(currentEvent ? currentEvent.lawyerevent.part2 || '' : '');
  const [activity, setActivity] = useState<any>(currentEvent ? currentEvent.lawyerevent.activity || '' : '');
  const [judge, setJugde] = useState(currentEvent ? currentEvent.lawyerevent.judge || '' : '');
  const [officeInput, setOfficeInput] = useState((currentEvent && currentEvent.lawyerevent.office) ? currentEvent.lawyerevent.office.name! : '');
  const [office, setOffice] = useState<any>((currentEvent && currentEvent.lawyerevent.office) ? new SelectOption(currentEvent.lawyerevent.office, currentEvent.lawyerevent.office.name) : '');
  const [notes, setNotes] = useState(currentEvent ? currentEvent.getResource(FormFields.notes) || null : null);
  const [nrg, setNrg] = useState(currentEvent ? currentEvent.getResource(FormFields.nrg) || undefined : undefined);
  const [expense1, setExpense1] = useState(currentEvent ? currentEvent.getResource(FormFields.expense1) || undefined : undefined);
  const [expense2, setExpense2] = useState(currentEvent ? currentEvent.getResource(FormFields.expense2) || undefined : undefined);
  const [expense3, setExpense3] = useState(currentEvent ? currentEvent.getResource(FormFields.expense3) || undefined : undefined);
  const [typeEvent, setTypeEvent] = useState<SelectOption<ResourceType>>(currentEvent ? options.find((o) => o.value === currentEvent.resourcetype)! : options[0]);
  const [notice, setNotice] = useState((currentEvent && currentEvent.noticeDays()) ? Utility.selectOptions(NoticeType).find((o) => o.value === `_${currentEvent.getResource(FormFields.notice)}`) : null);

  const dispatch = useDispatch();

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  let INITIAL_STATE_FIELD = {
    nrg: false,
    expense1: false,
    expense2: false,
    expense3: false,
    office: false,
    judge: false,
    notice: false
  };
  if (currentEvent) {
    INITIAL_STATE_FIELD = {
      nrg: !!currentEvent.getResource(FormFields.nrg),
      expense1: !!currentEvent.getResource(FormFields.expense1),
      expense2: !!currentEvent.getResource(FormFields.expense2),
      expense3: !!currentEvent.getResource(FormFields.expense3),
      office: !!currentEvent.lawyerevent.office,
      judge: !!currentEvent.lawyerevent.judge,
      notice: !!currentEvent.noticeDays()
    };
  }
  const [showField, setShowField] = useState<IShowField>(INITIAL_STATE_FIELD);

  const [suggPart1, , requestPart1] = EventsService.useSearchPartsAPI(PartsType.parts1);
  const [suggPart2, , requestPart2] = EventsService.useSearchPartsAPI(PartsType.parts2);
  const [activities, , activitiesRequest] = EventsService.useSearchActivitiesAPI();
  const [suggJudge, , requestJudge] = EventsService.useSearchJudgesAPI();
  const [suggOffice, , requestOffice] = EventsService.useSearchOfficesAPI();

  const [error, setError] = useState({});

  const [ce, createEventError, createRequest] = EventsService.useCreateEventAPI();
  const [ue, updateEventError, updateRequest] = EventsService.useUpdateEventAPI();
  const [pe, , postponeError, postponeRequest] = EventsService.usePostponeEvent();

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.agendaAdd)
  }, [])

  useEffect(() => {
    createEventError ?
      setError({ ...createEventError }) :
      (updateEventError ?
        setError({ ...updateEventError }) :
        (postponeError ?
          setError({ ...postponeError }) :
          setError({})));
  }, [createEventError, updateEventError, postponeError]);

  useEffect(() => {
    setEndTime(moment(startTime).add('m', 30).toDate());
  }, [startTime]);

  const validate = () => {
    return true;
  };

  const submit = () => {
    if (validate()) {

      let _part1 = part1 === '' ? null : part1
      let _part2 = part2 === '' ? null : part2
      let _activity = activity === '' ? null : activity
      let _judge = judge === '' ? null : judge
      let _office = office === '' ? null : office
      let __office = _office === null ? null : _office.value

      const newObjectEvent = {
        all_day: allDay,
        date: moment(eventDate).format(DateFormat.server),
        start: !allDay ? moment(startTime).startOf('m').format(DateFormat.timeSecond) : undefined,
        end: !allDay ? moment(endTime).startOf('m').format(DateFormat.timeSecond) : undefined,
        lawyerevent: {
          part1: _part1,
          part2: _part2,
          judge: _judge,
          activity: _activity,
          office: __office,
          hearing: typeEvent.value === ResourceType.Hearing ? { nrg, notes } : undefined,
          appointment: typeEvent.value === ResourceType.Appointment ? {
            nrg,
            expense1: +expense1,
            expense2: +expense2,
            expense3: +expense3,
            notes
          } : undefined,
          fulfillment: typeEvent.value === ResourceType.Fulfillment ? {
            nrg,
            notice_days: notice ? +notice.value.slice(1) : null,
            notes
          } : undefined
        },
        resourcetype: typeEvent.value
      };

      if (page === Page.NewCommitment) {
        createRequest(new Event(newObjectEvent));
    
      } else if (page === Page.EditCommitment && currentEvent) {
        updateRequest(stateEvent!, new Event({
          ...newObjectEvent,
          id: currentEvent.id,
          lawyerevent: {
            ...newObjectEvent.lawyerevent,
            status: currentEvent.eventStatus()
          }
        }));
     
      } else if (page === Page.PostPoneHearing && currentEvent) {
        postponeRequest(currentEvent, new Event({
          ...newObjectEvent,
          id: currentEvent.id,
          lawyerevent: {
            ...newObjectEvent.lawyerevent,
            status: currentEvent.eventStatus()
          }
        }));
      }
    }
  };
  //
  useEffect(() => {
    if (page === Page.NewCommitment && ce) {
      AnalyticsService.event(AnalyticsEvents.agendaNewEvent, { event_type: ce.resourcetype })

      dispatch(tabThirdClose());
      if (dateEvents && dateEvents[ce!.date!]) {
        dispatch(setCurrentDay(ce!.date!));
      } else {
        if (dateEvents) {
          const momentDate = moment(ce!.date!, DateFormat.server);
          const dateKeys = Object.keys(dateEvents).sort();

          const { 0: firstDate, [dateKeys.length - 1]: lastDate } = dateKeys;

          const firstDateMoment = moment(firstDate, DateFormat.server);
          const lastDateMoment = moment(lastDate, DateFormat.server);

          const beforeDateFirst = firstDateMoment.clone();
          beforeDateFirst.add(-1, 'month').add(-15, 'day').startOf('month')
          const afterDateLast = lastDateMoment.clone();
          afterDateLast.add(1, 'month').add(15, 'day').endOf('month');

          const beforeRange = extendedMoment.range(beforeDateFirst, firstDateMoment);
          const afterRange = extendedMoment.range(lastDateMoment, afterDateLast);

          if (beforeRange.contains(momentDate) || afterRange.contains(momentDate)) {
            dispatch(setCurrentDay(ce!.date!));
          } else {
            dispatch(resetEvents());
            setTimeout(() => { dispatch(setCurrentDay(ce!.date!)); }, 200);
          }
        }
      }
    }
  }, [ce]);

  useEffect(() => {
    if (ue) {
      if (dateEvents && dateEvents[ue!.date!]) {
        dispatch(setCurrentDay(ue!.date!));
      } else {
        if (dateEvents) {
          const momentDate = moment(ue!.date!, DateFormat.server);
          const dateKeys = Object.keys(dateEvents).sort();

          const { 0: firstDate, [dateKeys.length - 1]: lastDate } = dateKeys;

          const firstDateMoment = moment(firstDate, DateFormat.server);
          const lastDateMoment = moment(lastDate, DateFormat.server);

          const beforeDateFirst = firstDateMoment.clone();
          beforeDateFirst.add(-1, 'month').add(-15, 'day').startOf('month')
          const afterDateLast = lastDateMoment.clone();
          afterDateLast.add(1, 'month').add(15, 'day').endOf('month');

          const beforeRange = extendedMoment.range(beforeDateFirst, firstDateMoment);
          const afterRange = extendedMoment.range(lastDateMoment, afterDateLast);

          if (beforeRange.contains(momentDate) || afterRange.contains(momentDate)) {
            dispatch(setCurrentDay(ue!.date!));
          } else {
            dispatch(resetEvents());
            setTimeout(() => { dispatch(setCurrentDay(ue!.date!)); }, 200);
          }
        }
      }
    }
  }, [ue]);

  useEffect(() => {
    if (pe) {
      if (dateEvents && dateEvents[pe!.date!]) {
        dispatch(setCurrentDay(pe!.date!));
      } else {
        if (dateEvents) {
          const momentDate = moment(pe!.date!, DateFormat.server);
          const dateKeys = Object.keys(dateEvents).sort();

          const { 0: firstDate, [dateKeys.length - 1]: lastDate } = dateKeys;

          const firstDateMoment = moment(firstDate, DateFormat.server);
          const lastDateMoment = moment(lastDate, DateFormat.server);

          const beforeDateFirst = firstDateMoment.clone();
          beforeDateFirst.add(-1, 'month').add(-15, 'day').startOf('month');
          const afterDateLast = lastDateMoment.clone();
          afterDateLast.add(1, 'month').add(15, 'day').endOf('month');

          const beforeRange = extendedMoment.range(beforeDateFirst, firstDateMoment);
          const afterRange = extendedMoment.range(lastDateMoment, afterDateLast);


          if (beforeRange.contains(momentDate) || afterRange.contains(momentDate)) {
            setTimeout(() => { dispatch(setCurrentDay(pe!.date!)); }, 200);
          } else {
            dispatch(resetEvents());
            setTimeout(() => { dispatch(setCurrentDay(pe!.date!)); }, 500);
          }
        }
      }
    }
  }, [pe]);

  return (
    <div className={'agenda__detail__form'}>
      <form onSubmit={preventSubmit(submit)}>
        <>
          <div className={'form__full'}>
            <Select<SelectOption>
              label={strings.agenda.add.type}
              defaultValue={typeEvent}
              value={typeEvent}
              options={options}
              readonly={page === Page.EditCommitment}
              onChange={(opt: SelectOption) => setTypeEvent(opt)}
              error={Network.retrieveError(FormFields.resourcetype, error)}
            />
          </div>

          <div className={'form__full'}>
            <div className={classNames('form__color', {
              app: typeEvent.value === ResourceType.Appointment,
              udi: typeEvent.value === ResourceType.Hearing,
              ade: typeEvent.value === ResourceType.Fulfillment
            })} />
          </div>

          <div className={'form__full'}>
            <label>{strings.agenda.add.date}</label>
            <DatePicker
              selected={eventDate}
              onChange={(date: Date) => {
                setEventDate(date);
              }}
              locale="it"
              dateFormat="eeee d MMMM"
            />
            {Network.retrieveError(FormFields.date, error) &&
              <p className={'error'}>{Network.retrieveError(FormFields.date, error)}</p>
            }
          </div>
          {!allDay && (
            <>
              <div className={'form__mid-l'}>
                <label>{strings.agenda.add.start}</label>
                <DatePicker
                  selected={startTime}
                  onChange={(date: Date) => {
                    setStartTime(date);
                  }}
                  showTimeSelect={true}
                  showTimeSelectOnly={true}
                  timeIntervals={5}
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                />
              </div>

              <div className={'form__mid-r'}>
                <label>{strings.agenda.add.end}</label>
                <DatePicker
                  selected={endTime}
                  onChange={(date: Date) => {
                    setEndTime(date);
                  }}
                  showTimeSelect={true}
                  showTimeSelectOnly={true}
                  timeIntervals={5}
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                />
              </div>
            </>
          )}

          <div className={'form__full'}>
            <div className={'switch-container'}>
              <p>{strings.agenda.add.allDay}</p>
              <label className="switch">
                <input type="checkbox" checked={allDay} onChange={() => setAllDay(!allDay)} />
                <span className="slider" />
              </label>
            </div>
            {Network.retrieveError(FormFields.allDay, error) &&
              <p className={'error'}>{Network.retrieveError(FormFields.allDay, error)}</p>
            }
            {Network.retrieveError(FormFields.start, error) &&
              <p className={'error'}>{Network.retrieveError(FormFields.start, error)}</p>
            }
            {Network.retrieveError(FormFields.end, error) &&
              <p className={'error'}>{Network.retrieveError(FormFields.end, error)}</p>
            }
          </div>

          <div className={'form__full'}>
            <div className={'form__color'} />
          </div>

          <div className={'form__full'}>
            <InputAutosuggest
              label={strings.agenda.add.part}
              placeholder={strings.fieldPlaceHolderPart}
              suggestions={suggPart1 || []}
              onChange={setPart1}
              value={part1}
              onFetch={requestPart1}
              error={Network.retrieveError(FormFields.part1, error, 'lawyerevent')}
            />
          </div>

          <div className={'form__full'}>
            <InputAutosuggest
              label={strings.agenda.add.counterpart}
              placeholder={strings.fieldPlaceHolderCounterpart}
              suggestions={suggPart2 || []}
              onChange={setPart2}
              value={part2}
              onFetch={requestPart2}
              error={Network.retrieveError(FormFields.part1, error, 'lawyerevent')}
            />
          </div>

          <div className={'form__full'}>
            <InputAutosuggest
              label={strings.agenda.add.activity}
              placeholder={strings.agenda.add.activity}
              suggestions={activities || []}
              // onChange={(v) => setActivity(v === '' ? ' ' : v)}
              onChange={setActivity}
              value={activity}
              onFetch={activitiesRequest}
              error={Network.retrieveError(FormFields.activity, error, 'lawyerevent')}
            />
          </div>

          <div className={'form__full'}>
            <div className={'form__color'} />
          </div>

          {typeEvent.value === ResourceType.Fulfillment && (
            <div className={'form__full'}>
              {/*AVVISO*/}
              <CancelableSelectField
                labelAdd={strings.agenda.add.addNotice}
                label={strings.agenda.add.notice}
                value={notice}
                placeholder={strings.fieldPlaceHolderNotice}
                options={Utility.selectOptions(NoticeType)}
                onChange={setNotice}
                autoFocus={true}
                showField={showField.notice}
                onCancel={() => {
                  setNotice(null)
                  setShowField({ ...showField, notice: false })
                }}
                handleShow={() => setShowField({ ...showField, notice: true })}
                error={Network.retrieveError(FormFields.status, error)}
              />
            </div>
          )}

          {/*GIUDICE*/}
          <div className={'form__full'}>
            <CancelableInputSuggestField
              labelAdd={strings.agenda.add.addJudje}
              label={strings.agenda.add.judge}
              placeholder={strings.agenda.add.judge}
              suggestions={suggJudge || []}
              onChange={setJugde}
              autoFocus={true}
              value={judge}
              onFetch={requestJudge}
              showField={showField.judge}
              onCancel={() => {
                setJugde('')
                setShowField({ ...showField, judge: false })
              }}
              handleShow={() => setShowField({ ...showField, judge: true })}
              error={Network.retrieveError(FormFields.judge, error, 'lawyerevent')}
            />
          </div>
          {/*UFFICIO*/}
          <div className={'form__full'}>
            <CancelableInputSuggestField
              labelAdd={strings.agenda.add.addJudicialOffice}
              label={strings.agenda.add.judicialOffice}
              placeholder={strings.agenda.add.judicialOffice}
              suggestions={suggOffice || []}
              onChange={setOfficeInput}
              autoFocus={true}
              value={officeInput}
              onFetch={requestOffice}
              showField={showField.office}
              onCancel={() => {
                setOffice('')
                setShowField({ ...showField, office: false })
              }
              }
              handleShow={() => setShowField({ ...showField, office: true })}
              onSelect={(sugg) => setOffice(sugg)}
              onBlur={(s) => !office ? setOfficeInput('') : (!s ? setOfficeInput(office.getValue()) : null)}
              error={Network.retrieveError(FormFields.office, error, 'lawyerevent')}
            />
          </div>

          {typeEvent.value != ResourceType.Hearing && (
            <>
              <div className={'form__full'}>
                <CancelableInputField
                  label={strings.agenda.add.expense1Label}
                  labelAdd={strings.agenda.add.expense1Action}
                  placeholder={strings.agenda.add.expense1Placeholder}
                  onCancel={() => {
                    setShowField({ ...showField, expense1: false });
                    setExpense1(undefined);
                  }}
                  handleShow={() => setShowField({ ...showField, expense1: true })}
                  showField={showField.expense1}
                  autoFocus={true}
                  value={expense1}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (!isNaN(+event!.target.value)) {
                      setExpense1(event!.target.value.replace(/(\.[0-9]*)/g, ''));
                    }
                  }}
                  error={Network.retrieveError(FormFields.expense1, error, 'lawyerevent/fulfillment')}
                />
              </div>
              <div className={'form__full'}>
                <CancelableInputField
                  label={strings.agenda.add.expense2Label}
                  labelAdd={strings.agenda.add.expense2Action}
                  placeholder={strings.agenda.add.expense2Placeholder}
                  onCancel={() => {
                    setShowField({ ...showField, expense2: false });
                    setExpense2(undefined);
                  }}
                  handleShow={() => setShowField({ ...showField, expense2: true })}
                  showField={showField.expense2}
                  autoFocus={true}
                  value={expense2}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (!isNaN(+event!.target.value)) {
                      setExpense2(event!.target.value.replace(/(\.[0-9]*)/g, ''));
                    }
                  }}
                  error={Network.retrieveError(FormFields.expense2, error, 'lawyerevent/fulfillment')}
                />
              </div>
              <div className={'form__full'}>
                <CancelableInputField
                  label={strings.agenda.add.expense3Label}
                  labelAdd={strings.agenda.add.expense3Action}
                  placeholder={strings.agenda.add.expense3Placeholder}
                  onCancel={() => {
                    setShowField({ ...showField, expense3: false });
                    setExpense3(undefined);
                  }}
                  handleShow={() => setShowField({ ...showField, expense3: true })}
                  showField={showField.expense3}
                  autoFocus={true}
                  value={expense3}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (!isNaN(+event!.target.value)) {
                      setExpense3(event!.target.value.replace(/(\.[0-9]*)/g, ''));
                    }
                  }}
                  error={Network.retrieveError(FormFields.expense3, error, 'lawyerevent/fulfillment')}
                />
              </div>
            </>
          )}

          {/*NRG*/}
          <div className={'form__full'}>
            <CancelableInputField
              label={strings.agenda.add.nrg}
              labelAdd={strings.agenda.add.addNrg}
              placeholder={strings.agenda.add.nrg}
              value={nrg}
              autoFocus={true}
              onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                setNrg(event!.target.value);
              }}
              onCancel={() => {
                setShowField({ ...showField, nrg: false });
                setNrg(null);
              }}
              handleShow={() => setShowField({ ...showField, nrg: true })}
              showField={showField.nrg}
              error={Network.retrieveError(FormFields.nrg, error, 'lawyerevent/' + typeEvent.value!.toLowerCase())}
            />
          </div>

          <div className={'form__full'}>
            <label>{strings.agenda.add.addAnnotation}</label>
            <textarea
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value === '' ? null : e.target.value)}
              placeholder={strings.fieldPlaceHolderAnnotation}
              value={notes}
            />
          </div>
        </>

        <div className={'cta-container'}>
          <button className={'button-primary'}>
            {page === Page.NewCommitment && (
              <span>{strings.agenda.add.headerTitle}</span>
            )}
            {page === Page.EditCommitment && (
              <span>{strings.agenda.edit.action.modify}</span>
            )}
            {page === Page.PostPoneHearing && (
              <span>{strings.agenda.edit.postpone.action}</span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
export default CommitmentForm;


