import { FC } from "react";
import { Step } from "../UpdatesModal";
import React from "react";

type DotsProps = {
    currentStep: number, 
    steps: Step[], 
    handleStepChange: (step: number) => void 
}

const Dots: FC<DotsProps> = ({ currentStep, steps, handleStepChange }) => {
  return (
    <div className="dots-container">
      {steps.map((_, index: number) => (
        <div
          key={index}
          className={`dot ${index === currentStep ? 'active' : ''}`}
          onClick={() => handleStepChange(index)}
        />
      ))}
    </div>
  )
}

export default Dots