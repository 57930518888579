import React from 'react';
import {useDispatch} from 'react-redux';
import {inRecordCreation} from '../../store/reducers/RecordReducer';
import strings from '../../values/strings';

const RecordRightCreate: React.FC = () => {

  const dispatch = useDispatch();
  return (
    <>
      <div className={'createVerbal'}>
        <p className={'main'}>{strings.records.right.empty.question}</p>
        <p className={'copy'}>{strings.records.right.empty.copy}</p>
        <button className={'button-primary'} onClick={() => dispatch(inRecordCreation(true))}>
          <span>{strings.records.add.headerTitle}</span>
        </button>
      </div>
    </>
  );
};

export default RecordRightCreate;
