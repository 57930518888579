export default class SelectOption<T extends any = any> {
  value: T;
  label: string;

  constructor(value: any, label: any) {
    this.value = value;
    this.label = label;
  }

  get id(): any {
    if (this.value instanceof Object && this.value.id) {
      return this.value.id;
    } else {
      return 0;
    }
  }
  get name(): any {
      return this.label;
  }

  getValue() {
    return this.label;
  }

}
