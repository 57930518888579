import moment from 'moment';
import { SubscriptionType, DateFormat, ResourceType, SubscriptionInfoType } from '../values/values';
import SelectOption from '../store/types/SelectionOption';
import { ActivitiesContext } from 'components/activity-request/ActivityRequest';
import { SubscriptionInfo } from 'store/types/SubscriptionInfo';


export interface IHash {
  [details: string]: string;
}

export default class Utility {

  static validateEmail = (text?: string): boolean => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!text) {
      return false;
    }

    return regex.test(text);
  };

  static validatePassword = (text?: string): boolean => {
    const regex = /^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/;

    if (!text) {
      return false;
    }
    return regex.test(text);
  };

  static validateFiscalCode = (text?: string): boolean => {
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
    const regex = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/;

    if (!text) {
      return false;
    }
    return regex.test(text);
  };

  static validateBirthDate = (text?: string): boolean => {
    const regex = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;

    if (!text) {
      return false;
    }
    return regex.test(text);
  };

  static isUnder46 = (date: string) => {
    const age = moment().diff(moment(date, DateFormat.server), 'years');
    return age < 46;
  };

  static validatePhone = (text?: string): boolean => {
    const regex = /^\+?[0-9]{3}-?([0-9]{7}|[0-9]-[0-9]{2}-[0-9]{2}-[0-9]{2}|[0-9]{3}-[0-9]{2}-[0-9]-[0-9])$/;

    if (!text) {
      return false;
    }
    return regex.test(text);
  }

  static selectOptions = (values: any, first?: number): SelectOption[] => {
    return Object.keys(values).map((key) => new SelectOption(String(key), values[key])).slice(0, first);
  };

  static sliced(value: number, digits: number) {
    const digitString = Array(digits).join('0');
    return (digitString + value).slice(-digits);
  }


  static localised = (string?: string) => {
    switch (string) {
      case ResourceType.Appointment: return 'Appuntamento'
      case ResourceType.Fulfillment: return 'Adempimento'
      case ResourceType.Hearing: return 'Udienza'
      case ResourceType.News: return 'News'
      case ResourceType.Notice: return 'Avviso'

      case 'LAWYER': return 'Avvocato'
      case 'ESTABLISHED': return 'Avvocato stabilito'
      case 'PRACTICING': return 'Praticante avvocato'
      case 'QUALIFIED': return 'Praticante avvocato abilitato'

      case 'SUBSTITUTE': return 'Sostituto in Udienza'
      case 'DOMICILIARY': return 'Avvocato Domiciliatario'
      case 'CHANCELLERY': return 'Attività di Cancelleria'

      default: return string
    }
  }


  static format = (fmt: any, ...args: any) => {
    if (!fmt.match(/^(?:(?:(?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{[0-9]+\}))+$/)) {
      throw new Error('invalid format string.');
    }
    return fmt.replace(/((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g, (m: any, str: string, index: number) => {
      if (str) {
        return str.replace(/(?:{{)|(?:}})/g, m => m[0]);
      } else {
        if (index >= args.length) {
          throw new Error('argument index is out of range in format');
        }
        return `${args[index]}`;
      }
    });
  }


  static currency = (value?: number | undefined | null) => {
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    })

    return formatter.format(value || 0)
  }


  static subscriptionType = (subscription: SubscriptionInfo): SubscriptionInfoType => {
    if (!subscription || !subscription.type) return SubscriptionInfoType.GOLD

    switch (subscription.type) {
      case SubscriptionInfoType.PROFESSIONAL: return SubscriptionInfoType.PROFESSIONAL
      case SubscriptionInfoType.GOLD: return SubscriptionInfoType.GOLD
      case SubscriptionInfoType.SKILLS: return SubscriptionInfoType.SKILLS
      default: return SubscriptionInfoType.PROFESSIONAL
    }
  }


  static hasDecimals(value?: number | string): boolean {
    if (value == undefined) return false
    // Converti il numero in una stringa
    const strValue = value.toString();

    // Controlla se esiste un punto decimale e se ci sono cifre dopo il punto
    return strValue.includes('.') && strValue.split('.')[1].length > 0;
  }


  static paymentMethodTitle = (method: string) => {
    switch (method) {
      case 'paypal': return 'PayPal'
      case 'credit_card': return 'Carta di credito'
      case 'bank_transfer': return 'Bonifico bancario'
      case 'trial': return 'Prova gratuita'
      default: return 'ND'
    }
  }

}
