import React from 'react';
import classNames from 'classnames';

interface IIconProps {
  isValid: boolean;
}

const InputIconValidate = ({isValid}: IIconProps) => {
  return <span className={classNames({'ico-correct': isValid, 'ico-error': !isValid})} />;
}

export default InputIconValidate;
