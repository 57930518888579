import { User } from '../types/User';
import { IHash } from '../../utils/utils';
import { PendingUser } from '../types/PendingUser';

const SET_CURRENT_USER = 'SET_CURRENT_USER';
const CREATE_TEMP_USER = 'CREATE_TEMP_USER';
const UPDATE_TEMP_USER = 'UPDATE_TEMP_USER';
const CLEAR_TEMP_USER = 'CLEAR_TEMP_USER';
const GO_IN_SIGNUP_REVIEW = 'GO_IN_SIGNUP_REVIEW';
const OUT_IN_SIGNUP_REVIEW = 'OUT_IN_SIGNUP_REVIEW';


export interface IUserReducer {
  user: IUserReducerState;
}

export interface IUserReducerState {
  currentUser?: User
  tempUser?: User
  registerInReview: boolean
}


// ACTIONS

export function setCurrentUser(user?: User) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_CURRENT_USER,
      user
    });
  };
}

export function createTempUser(user?: User) {
  return async (dispatch: any) => {
    await dispatch({
      type: CREATE_TEMP_USER,
      user: user || new User()
    });
  };
}

export function clearTempUser() {
  return async (dispatch: any) => {
    await dispatch({
      type: CLEAR_TEMP_USER,
    });
  };
}

export function updateTempUser(field: string, value?: any) {
  console.log(field, value);
  return async (dispatch: any) => {
    const updates: IHash = {
      [field]: value
    };
    updates[field] = value;

    await dispatch({
      type: UPDATE_TEMP_USER,
      updates
    });
  };
}

export function goInSignUpReview() {
  return {
    type: GO_IN_SIGNUP_REVIEW
  };
}


export function outSignUpReview() {
  return {
    type: OUT_IN_SIGNUP_REVIEW
  };
}

const INITIAL_STATE = { registerInReview: false };

// REDUCER
export default function (state: IUserReducerState = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user ? action.user : undefined
      };

    case CREATE_TEMP_USER:
      return {
        ...state,
        tempUser: action.user
      };

    case UPDATE_TEMP_USER:
      return {
        ...state,
        tempUser: new PendingUser({
          ...state.tempUser,
          ...action.updates
        })
      };

    case CLEAR_TEMP_USER:
      return {
        ...state,
        tempUser: undefined
      };

    case GO_IN_SIGNUP_REVIEW:
      return {
        ...state,
        registerInReview: true
      };
    case OUT_IN_SIGNUP_REVIEW:
      return {
        ...state,
        tempUser: undefined,
        registerInReview: false
      };
    default:
      return state;
  }
}
