import {Record} from '..//types/Record';

const SET_RECORDS = 'SET_RECORDS';
const UPDATE_RECORDS = 'UPDATE_RECORDS';
const SELECT_RECORD = 'SELECT_RECORD';
const IN_CREATION = 'IN_CREATION';
const MINE_OR_SHARED = 'MINE_OR_SHARED';

export interface IRecordsReducer {
  record: IRecordsReducerState;
}

export interface IRecordsReducerState {
  records: Record[];
  record?: Record;
  mineOrShared: boolean;
  inCreation: boolean;
}

const INITIAL_STATE: IRecordsReducerState = {records: [], inCreation: false, mineOrShared: true};

// ACTIONS
export function setRecords(records?: Record[]) {
  return async (dispatch: any) => {

    await dispatch({
      type: SET_RECORDS,
      records
    });
  };
}

export function updateRecords(record: Record) {
  return async (dispatch: any) => {

    await dispatch({
      type: UPDATE_RECORDS,
      record
    });
  };
}

export function selectRecord(record?: Record) {
  return async (dispatch: any) => {
    await dispatch({
      type: SELECT_RECORD,
      record
    });
  };
}

export function inRecordCreation(visible: boolean) {
  return async (dispatch: any) => {
    await dispatch({
      type: IN_CREATION,
      inCreation: visible
    });
  };
}

export function setMineOrShared(mineOrShared: boolean) {
  return async (dispatch: any) => {
    await dispatch({
      type: MINE_OR_SHARED,
      mineOrShared
    });
  };
}

// REDUCER
export default function (state: IRecordsReducerState = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_RECORDS:
      return {
        ...state,
        records: action.records
      };

    case UPDATE_RECORDS:
      return {
        ...state,
        records: [action.record, ...state.records]
      };

    case SELECT_RECORD:
      return {
        ...state,
        record: action.record
      };

    case IN_CREATION:
      return {
        ...state,
        inCreation: action.inCreation
      };
    case MINE_OR_SHARED:
      return {
        ...state,
        mineOrShared: action.mineOrShared
      }
    default:
      return state;
  }
}
