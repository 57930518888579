import React from "react";
import SubscriptionListItem from "../SubscriptionListItem";
import strings from "values/strings";
import Utility from "utils/utils";
import { useSelector } from "react-redux";
import { ISubscriptionReducer } from "store/reducers/SubscriptionReducer";

interface Props {
}


const CollegaGold: React.FC<Props> = ({ }) => {
  const collega = strings.subscriptions.collega;

  const subscription = useSelector((state: ISubscriptionReducer) => state.subscription.subscription);
  const subscriptionInfoDescriptionArray = subscription != undefined ? subscription.description!.split('• ').filter(Boolean) : []

  return (
    <div className="collega-details">
      <div className="collega-details__title">{subscription!.title}</div>
      <ul className="collega-details__list">
        {subscription && subscriptionInfoDescriptionArray!.map((description, index) => (
          <SubscriptionListItem key={index} type={Utility.subscriptionType(subscription)} text={description} />
        ))}
      </ul>
      {/* <div className="collega-details__price-container">
        <span className="collega-details__price">
          {subscription && Utility.currency(Number(subscription.price))}

          <span> al {collega.month} + {"IVA"}</span>
        </span>
        <span className="collega-details__renewal">{collega.automaticAnnualRenewal}</span>
      </div> */}
    </div>
  );
};

export default CollegaGold;
