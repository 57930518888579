import React, { useEffect } from 'react';
import logo from '../image/logo-collega-white.svg';
import card1 from '../image/on-boarding-card-1.svg';
import card2 from '../image/on-boarding-card-2.svg';
import card3 from '../image/on-boarding-card-3.svg';
import card4 from '../image/on-boarding-card-4.svg';
import strings from '../values/strings';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';

const OnboardingMob: React.FC = () => {

  return (
    <div className={'onboarding-cntr-mob'}>
      <div className={'header-onboarding-mob'}>
        <div>
          <img className={'logo'} src={logo} alt="Logo collega" />
          <h2>{strings.onBoarding.first.pre}</h2>
          <p className={'title'}>{strings.onBoarding.first.title}</p>
          <p>{strings.onBoarding.first.subtitle}</p>
        </div>
        <div className={'onboarding__store '}>
          <div className="text">
            <h2>{strings.onBoarding.store.pre}</h2>
            <p>{strings.onBoarding.store.text}</p>
          </div>
          <div className={'cta-container'}>
            <a className={'app-store'} href="https://apps.apple.com/app/id1126249594" target="_blank" />
            <a className={'google-play'} href="https://play.google.com/store/apps/details?id=com.collega" target="_blank" />
          </div>
        </div>
      </div>
      <div className={'wrapper-on-mob'}>
        <div className={'onboarding-mob'}>
          <div className={'onboarding__content'}>
            <div className={'img__container'}>
              <img src={card1} alt="card1" />
            </div>
            <h2>{strings.onBoarding.card1.pre}</h2>
            <p>{strings.onBoarding.card1.text}</p>
          </div>
          <div className={'onboarding__content'}>
            <div className={'img__container'}>
              <img src={card2} alt="card2" />
            </div>
            <h2>{strings.onBoarding.card2.pre}</h2>
            <p>{strings.onBoarding.card2.text}</p>
          </div>
          <div className={'onboarding__content'}>
            <div className={'img__container'}>
              <img src={card3} alt="card3" />
            </div>
            <h2>{strings.onBoarding.card3.pre}</h2>
            <p>{strings.onBoarding.card3.text}</p>
          </div>
          <div className={'onboarding__content'}>
            <div className={'img__container'}>
              <img src={card4} alt="card4" />
            </div>
            <h2>{strings.onBoarding.card4.pre}</h2>
            <p>{strings.onBoarding.card4.text}</p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default OnboardingMob;
