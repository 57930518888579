import {useState} from 'react';
import Network, {NetworkMethod} from '../network/Network';
import {Record} from '../store/types/Record';
import {useDispatch} from 'react-redux';
import {inRecordCreation, setRecords, updateRecords} from '../store/reducers/RecordReducer';
import {setError500, setIsLoading} from '../store/reducers/AppReducer';

class RecordsService {

  static useRecordsAPI = (): [Record[], any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async () => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.get;
      const endpoint = 'records/';

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
        dispatch(setRecords(networkResponse.data));
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

  static useCreateRecordAPI = (): [Record, any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async () => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'records/';

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
        dispatch(inRecordCreation(false));
        dispatch(updateRecords(networkResponse.data));
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };
}

export default RecordsService;
