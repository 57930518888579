
import { IHash } from 'utils/utils';
import { Request } from 'store/types/Request';

const SET_TEMP_REQUEST_DATA = 'SET_TEMP_REQUEST_DATA'
const SET_REQUEST_DATA = 'SET_REQUEST_DATA'
const UPDATE_REQUEST_DATA = 'UPDATE_REQUEST_DATA'
const SET_REQUEST_ERROR = 'SET_REQUEST_ERROR'

const RESET_DATA = 'RESET_DATA'

let initialState = {
    requestData: new Request(),
    requestResponse: {},

    requestError: undefined
}

export interface RequestReducer {
    requestReducer: RequestReducerState
}

export interface RequestReducerState {
    requestData: Request
    requestError: any
}


// ACTIONS

export function setRequestData(data: any) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_REQUEST_DATA,
            data: data
        })
    }
}

export function updateRequestData(field: string, value?: any) {
    console.log(field + ' - ' + value)
    return async (dispatch: any) => {
        let dict: IHash = {}; dict[field] = value

        await dispatch({
            type: UPDATE_REQUEST_DATA,
            updates: dict
        })
    }
}

export function setRequestError(data: any) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_REQUEST_ERROR,
            data: data
        })
    }
}

export function resetFormData() {
    return async (dispatch: any) => {

        await dispatch({
            type: RESET_DATA,
        })
    }
}


// REDUCER

export function requestReducer(state: RequestReducerState = initialState, action: any) {
    switch (action.type) {

        case SET_TEMP_REQUEST_DATA:
            return {
                ...state,
                tempRequestData: action.data
            }

        case SET_REQUEST_DATA:
            return {
                ...state,
                requestData: action.data
            }

        case UPDATE_REQUEST_DATA:
            return {
                ...state,
                requestData: {
                    ...state.requestData,
                    ...action.updates
                }
            }

        case SET_REQUEST_ERROR:
            return {
                ...state,
                requestError: action.data
            }

        case RESET_DATA:
            return initialState

        default: return state
    }
}