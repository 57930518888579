import React from 'react';
import { Item, ResourceItem } from 'store/types/Item';
import strings from 'values/strings';
import { SidePanelReducer, setPurchasedCourse } from 'store/reducers/SidePanelReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Ticket } from 'image/icons/ticket.svg';
import greenArrow45 from 'image/icons/nav/arrow-green-45.svg';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

interface Props {
  obj: Item
}

let PurchasedItem: React.FC<Props> = React.memo(({ obj }) => {

  const { category, course, last_purchased } = obj

  const purchasedCourse = useSelector((state: SidePanelReducer) => state.sidePanelReducer.purchasedCourse);

  let dispatch = useDispatch()

  let deadline = last_purchased && last_purchased.course && last_purchased.course.deadline
  let expiring = moment().isBetween(moment(deadline).subtract(7, 'days'), moment(deadline))
  let expired = moment().isAfter(deadline);

  let handleExternalLink = (link?: string) => {
    if (isMobile) {
      let temp = window.location.href; window.open(`_blank_${link}`); window.open(temp)
    } else {
      window.open(link, '_blank')
    }
  }

  return (
    <div className={purchasedCourse && purchasedCourse.id === obj.id ? 'courses__card selected' : 'courses__card'}
    >
      <div className="text" onClick={() => {
        dispatch(setPurchasedCourse(obj))
      }}>
        {category && <div className={'ticket'} style={{ color: `${category.foreground_color}`, background: `${category.background_color}` }}>
          <Ticket fill={category && category.foreground_color} width={'9px'} height={'13px'} />
          <p>{category.name}</p>
        </div>}
        <p className="title">{obj.name}</p>
        <p className="content">{obj.short_description && obj.short_description.length > 180 ? obj.short_description.substring(0, 180) + '...' : obj.short_description}</p>
        <p className={'purchased'}>{strings.courses.purchased.buy} {moment(last_purchased && last_purchased.created_at).format("DD/MM/YYYY")}</p>

        {last_purchased && last_purchased.course && last_purchased.course.deadline &&
          <p className={!expired ? 'deadline' : 'deadline expired'}>{!expired ? strings.courses.purchased.deadline : strings.courses.purchased.expired}
            {expired ? <span className={'expired'}> {moment(last_purchased.course.deadline).format("DD/MM/YYYY")}</span>
              : <span className={!expiring ? undefined : 'expired'}> {moment(last_purchased.course.deadline).format("DD/MM/YYYY")}</span>
            }
          </p>
        }

        {!expired && course && course.resources && course.resources.map((item: ResourceItem, index) => {
          return (

            <div className={'resource__container'} key={index}>
              <a onClick={() => handleExternalLink(item.link)} >
                <p className={'resource'}><span>{index + 1}.</span> {item.name && item.name.length > 87 ? item.name.substring(0, 87) + '...' : item.name}</p>
                <img src={greenArrow45} alt={'arrow'} />
              </a>

              {item.duration != null && <p className={'duration'} >{strings.courses.lesson} {item.duration} {strings.courses.minutes}</p>}
            </div>

          )
        })
        }

      </div>
      <div className={'rightBorder'} />
    </div>
  );
});

export default PurchasedItem;
