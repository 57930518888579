import Event from './Event';

export class Resource {
  notes?: string;
  nrg?: string;
  expense1?: number;
  expense2?: number;
  expense3?: number;
  notice_days?: number | null;
  notice?: Event;

  constructor(object : Partial<Resource>) {
    Object.assign(this, object);
  }
}
