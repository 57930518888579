import React, { useCallback, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import closeRight from '../../image/icons/close-right.svg';
import classNames from 'classnames';
import CommitmentForm from './CommitmentForm';
import CommitmentDetailEvent from './CommitmentDetailEvent';
import { useDispatch, useSelector } from 'react-redux';
import {
  editCommitment,
  IAppReducer,
  postPoneHearing,
  selectEvent,
  tabThirdClose,
  tabThirdOpen
} from '../../store/reducers/AppReducer';
import Page from '../../store/types/Page';
import { IAgendaReducer, setCurrentEvent, showDeleteModal } from '../../store/reducers/AgendaReducer';
import strings from '../../values/strings';
import { EventStatus, ResourceType } from '../../values/values';
import Event from '../../store/types/Event';
import EventsService from '../../services/EventService';
import { IUserReducer } from '../../store/reducers/UserReducer';
import { AnalyticsEvents, AnalyticsScreens } from 'services/AnalyticsService';
import AnalyticsService from '../../services/AnalyticsService';

const AgendaDetail: React.FC = () => {

  const opened = useSelector((state: IAppReducer) => state.app.isOpenTabThird);
  const page = useSelector((state: IAppReducer) => state.app.page);
  const currentEvent = useSelector((state: IAgendaReducer) => state.agenda.event);

  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);

  const [, , updateStatusRequest] = EventsService.useUpdateEventStatusAPI();

  const openContextMenu = useCallback(() => {
    setIsOpenContextMenu(true);
  }, [isOpenContextMenu]);

  const closeContextMenu = useCallback(() => {
    setIsOpenContextMenu(false);
  }, [isOpenContextMenu]);

  const dispatch = useDispatch();

  const onToggleArrowClick = useCallback(() => {
    if (opened) {
      dispatch(tabThirdClose());
      dispatch(setCurrentEvent());
    } else {
      dispatch(tabThirdOpen());
    }
  }, [opened, page]);

  const [event, setEvent] = useState<Event | undefined>();

  useEffect(() => {
    dispatch(setCurrentEvent());
  }, [])

  useEffect(() => {
    if (page === Page.PostPoneHearing) AnalyticsService.screenName(AnalyticsScreens.agendaEventPostpone)
    if (page === Page.Commitment) AnalyticsService.screenName(AnalyticsScreens.agendaEvent)
  }, [page])

  useEffect(() => {
    if (currentEvent && currentEvent.resourcetype === ResourceType.Notice && currentEvent.fulfillment instanceof Event) {
      setEvent(currentEvent.fulfillment);
    } else {
      setEvent(currentEvent);
    }
    return () => setEvent(undefined);
  }, [currentEvent]);

  return (
    <div className={classNames('agenda__detail', { closed: !opened })}>
      <div className={'header'}>
        {page === Page.NewCommitment && (
          <>
            <p>{strings.agenda.add.headerTitle}</p>
            <div className={'close'} onClick={() => dispatch(tabThirdClose())} />
          </>
        )}
        {page === Page.EditCommitment && (
          <>
            <p>{strings.agenda.edit.headerTitle}</p>
            <div className={'close'} onClick={() => dispatch(selectEvent())} />
          </>
        )}
        {page === Page.PostPoneHearing && (
          <>
            <p>{strings.agenda.edit.postpone.headerTitle}</p>
            <div className={'close'} onClick={() => dispatch(selectEvent())} />
          </>
        )}

        {(page === Page.Commitment && event) && (
          <>
            <div className={'close-right'} onClick={() => onToggleArrowClick()}>
              <img src={closeRight} />
            </div>
            <div className={'menu'} onClick={openContextMenu}>
              <span />
              <span />
              <span />
            </div>
            {/*MENU DETTAGLIO*/}
            {isOpenContextMenu && (
              <OutsideClickHandler
                onOutsideClick={() => closeContextMenu()}
              >
                <div className={'menu-detail'} style={{ zIndex: 1 }}>
                  <ul>
                    {event.lawyerevent.status === EventStatus.TODO &&
                      <li>
                        <a
                          href={'#'}
                          onClick={async (e) => {
                            e.preventDefault();
                            AnalyticsService.event(AnalyticsEvents.agendaEventUpdate, { event_id: event.id, status: EventStatus.DONE })
                            await updateStatusRequest(event, EventStatus.DONE);
                            closeContextMenu();
                          }}>
                          {strings.tooltip.done}
                        </a>
                      </li>
                    }
                    {event.lawyerevent.status === EventStatus.DONE &&
                      <li>
                        <a
                          href={'#'}
                          onClick={async (e) => {
                            e.preventDefault();
                            AnalyticsService.event(AnalyticsEvents.agendaEventUpdate, { event_id: event.id, status: EventStatus.TODO })
                            await updateStatusRequest(event, EventStatus.TODO);
                            closeContextMenu();
                          }}>
                          {strings.tooltip.todo}
                        </a>
                      </li>
                    }
                    {event.lawyerevent.status !== EventStatus.DONE && (
                      <>
                        {(event.resourcetype === ResourceType.Hearing && event.lawyerevent.status !== EventStatus.POSTPONED) && (
                          <li><a
                            href={'#'}
                            onClick={async (e) => {
                              e.preventDefault();
                              await dispatch(setCurrentEvent(currentEvent));
                              closeContextMenu();
                              dispatch(postPoneHearing());
                            }}>
                            {strings.tooltip.postpone}</a>
                          </li>
                        )}
                        {event.lawyerevent.status !== EventStatus.POSTPONED && (
                          <li><a
                            href={'#'}
                            onClick={async (e) => {
                              e.preventDefault();
                              await dispatch(setCurrentEvent(currentEvent));
                              closeContextMenu();
                              dispatch(editCommitment());
                            }}>
                            {strings.tooltip.edit}</a>
                          </li>
                        )}
                      </>
                    )}
                    <li><a
                      href={'#'}
                      onClick={async (e) => {
                        e.preventDefault();
                        closeContextMenu();
                        dispatch(showDeleteModal(true));
                      }}>
                      {strings.tooltip.delete}</a>
                    </li>
                  </ul>
                </div>
              </OutsideClickHandler>
            )}
          </>
        )}

      </div>
      {page === Page.NewCommitment && (
        <CommitmentForm />
      )}
      {(page === Page.EditCommitment && event) && (
        <CommitmentForm stateEvent={currentEvent} currentEvent={event} />
      )}
      {(page === Page.PostPoneHearing && event) && (
        <>
          <CommitmentForm stateEvent={currentEvent} currentEvent={event} />
        </>
      )}
      {page === Page.Commitment && (
        <CommitmentDetailEvent />
      )}
    </div>
  );
};

export default AgendaDetail;
