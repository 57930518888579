import React, { FC } from 'react'
import plusIcon from '../../image/plus-icon.svg'
import strings from 'values/strings'
import goal from '../../image/goal-icon.svg'
import { SubscriptionInfoType } from 'values/values'

type EmptySpecificSkillBannerProps = {
  isForMainSkill: boolean,
  action: () => void
}

const EmptySpecificSkillBanner: FC<EmptySpecificSkillBannerProps> = ({ action, isForMainSkill }) => {
  return (
    <div className="banner-container" style={{ cursor: 'pointer' }} onClick={action}>

      <div className="banner-content">
        {isForMainSkill && <img src={goal} alt="goal-icon" height={32} />}
        <div className="banner-title">{isForMainSkill ? strings.profile.emptyMainSkillBanner.title : strings.profile.emptySpecificSkillBanner.title}</div>
        <div className="banner-subtitle">{isForMainSkill ? strings.profile.emptyMainSkillBanner.subtitile : strings.profile.emptySpecificSkillBanner.subtitile}</div>
      </div>

      <button className="banner-button">
        <img src={plusIcon} alt="plus-icon" />
      </button>
    </div>
  )
}

export default EmptySpecificSkillBanner