import React, { Fragment, useEffect, useState } from "react";
import strings from "values/strings";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import { Plan } from "store/types/Plan";
import { useSelector } from "react-redux";
import Utility from "utils/utils";
import classNames from 'classnames';
import logo from '../../image/logo-collega.svg';
import PaymentButton from "./PaymentButton";
import SubscriptionPlanRadioButton from "./SubscriptionPlanRadioButton";
import AccordionPromo from "./AccordionPromo";
import SubscriptionService from "services/SubscriptionService";
import { ExternalLinks, StripePK, SubscriptionInfoType } from "values/values";
import skill from '../../image/skill__image.svg';
import gold from '../../image/gold__image.svg';
import professional from '../../image/professional__image.svg';
import { Link, RouteComponentProps } from "react-router-dom";
import { ISubscriptionReducer } from "store/reducers/SubscriptionReducer";
import ConfirmModal from "components/request-activities/modal/ConfirmModal";
import { ActivityModal } from "store/types/ActivityModal";
import Storage from "store/Storage";
import CreditCardFormStripe from "./CreditCardFormStripe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";


interface RouterProps { }
interface Props extends RouteComponentProps<RouterProps> { }

const SubscriptionPlan: React.FC<Props> = ({ }) => {
  const stripePromise = loadStripe(StripePK);

  const [subscriptionInfoResponse, subscriptionInfoError, subscriptionInfoRequest] = SubscriptionService.usePPSetupTokenAPI()

  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [confirmationModal, setConfirmationModal] = useState<any>();
  const [authToken, setAuthToken] = useState<string>();

  const [SDI, setSDI] = useState<string>('');


  const [cardFormVisible, setCardFormVisible] = useState<boolean>(false);

  const promoDiscount: any = useSelector((state: ISubscriptionReducer) => state.subscription.promoDiscount);
  const promoPercentage: any = useSelector((state: ISubscriptionReducer) => state.subscription.promoPercentage);
  const promoCode: any = useSelector((state: ISubscriptionReducer) => state.subscription.promoCode);

  const subscription = useSelector((state: ISubscriptionReducer) => state.subscription.subscription);
  const price = useSelector((state: ISubscriptionReducer) => state.subscription.price);
  const variant = useSelector((state: ISubscriptionReducer) => state.subscription.variant);
  const city_id = useSelector((state: ISubscriptionReducer) => state.subscription.city_id);

  const radioButton = strings.subscriptions.radioButton;


  useEffect(() => {
    Storage.get(Storage.AUTH_TOKEN).then((token) => {
      setAuthToken(token)
    })

    SubscriptionService.oAuthAPI()
  }, [])

  useEffect(() => {
    setCardFormVisible(false)
  }, [selectedPlan])

  useEffect(() => {
    if (subscription == undefined || subscription.plans == undefined) {
      window.location.href = '/dashboard'

    } else {
      setSelectedPlan(subscription.plans.filter((plan) => {
        return plan.is_default
      })[0])
    }
  }, [subscription])

  useEffect(() => {
    if (!subscriptionInfoResponse) return
    window.open(subscriptionInfoResponse.links[1].href, '_self');

  }, [subscriptionInfoResponse])


  const isSelected = (plan: Plan) => {
    return (selectedPlan != undefined && selectedPlan.id) == plan.id
  }

  const title = (plan: Plan) => {
    if (plan.percentage == null) return `${plan.months} mesi`
    return `${plan.months} mesi (-${plan.percentage}%)`
  }

  const getOiginalPrice = (plan: Plan) => {
    if (price != null) {
      return price * Number(plan.months)
    } else return null
  }

  const getPromoValue = (subTotal: number): number => {
    if (!promoPercentage && promoDiscount) return 0
    return promoPercentage != undefined ? subTotal / 100 * promoPercentage : promoDiscount
  }

  const getFinalPrice = (plan: Plan) => {
    if (price != null) {
      let costPerMonths = price * Number(plan.months)
      let subTotal = costPerMonths - (costPerMonths / 100 * Number(plan.percentage))
      return getPromoValue(subTotal) ? subTotal - getPromoValue(subTotal) : subTotal
    } else return null
  }


  const handlePayPalPayment = (subscription?: SubscriptionInfo) => {
    let method = 'paypal'

    if (!subscription || !subscription.type || !selectedPlan) return
    switch (subscription.type) {
      case SubscriptionInfoType.GOLD:
        subscriptionInfoRequest(authToken!, subscription.id!, selectedPlan.id!, method, undefined, promoCode, undefined, SDI)
        break

      case SubscriptionInfoType.PROFESSIONAL:
        subscriptionInfoRequest(authToken!, subscription.id!, selectedPlan.id!, method, undefined, promoCode, city_id, SDI)
        break

      case SubscriptionInfoType.SKILLS:
        if (variant) subscriptionInfoRequest(authToken!, subscription.id!, selectedPlan.id!, method, variant.id, promoCode, undefined, SDI)
        break
      default: return
    }
  }

  const handleBankTransferPayment = (subscription?: SubscriptionInfo) => {
    let method = 'bank_transfer'

    if (!subscription || !subscription.type || !selectedPlan) return
    let url = `${ExternalLinks.AppAddress}/subscription-activate?auth_token=${authToken}&subscription_id=${subscription.id}&plan_id=${selectedPlan!.id}&payment_method=${method}&discount_code=${promoCode}&city_id=${city_id}&sdi=${SDI}`

    switch (subscription.type) {
      case SubscriptionInfoType.GOLD: break
      case SubscriptionInfoType.PROFESSIONAL: break
      case SubscriptionInfoType.SKILLS:
        url = `${ExternalLinks.AppAddress}/subscription-activate?auth_token=${authToken}&subscription_id=${subscription.id}&plan_id=${selectedPlan!.id}&payment_method=${method}&variant_id=${variant!.id}&discount_code=${promoCode}&city_id=${city_id}&sdi=${SDI}`
        break
      default: return
    }

    window.open(url, '_self');
  }

  const imageMap = {
    [SubscriptionInfoType.PROFESSIONAL]: professional,
    [SubscriptionInfoType.GOLD]: gold,
    [SubscriptionInfoType.SKILLS]: skill,
  };

  const confirmModalContent: ActivityModal = selectedPlan != undefined && subscription != undefined ? {
    title: `Conferma abbonamento`,
    copy: `Stai per attivare l\'abbonamento ${subscription!.title} al costo di ${Utility.currency(getFinalPrice(selectedPlan!))} + IVA con rinnovo automatico.`,
    cta: 'Attiva abbonamento',
    ctaUndo: strings.courses.modal.ctaUndo,
    onCancel: (() => {
      setConfirmationModal(undefined)
    }),
    onConfirm: confirmationModal != undefined ? () => {
      confirmationModal.block()
      setConfirmationModal(undefined)
    } : undefined
  } : {}

  return (
    <Fragment>
      {confirmationModal && <ConfirmModal content={confirmModalContent} />}

      <div className={'header-subscription'}>
        <Link to={'/dashboard'}><img className={'logo subscription-logo'} src={logo} alt="Logo collega" /></Link>
      </div>
      <div className={'wrapper-login'}>
        <div className={'login'} style={{ paddingTop: '6px' }}>
          {subscription && <div style={{ position: 'relative' }} className={classNames('login__form subscription-plan-container')}>

            <div className="collega-details">
              <h1 className="title" style={{ marginBottom: 16 }}>{subscription.type == SubscriptionInfoType.PROFESSIONAL ? 'Verifica completata' : subscription.title}</h1>
              <p style={{ marginTop: 0, marginBottom: 32, lineHeight: '24px' }}>{strings.subscriptions.subscriptionPlan.professional.description}</p>
            </div>

            <div className="subscription-plan-container__divider" />
            <AccordionPromo subscription={subscription} />

            <div className="subscription-plan-container__radio-buttons">
              {subscription && subscription.plans && subscription.plans.map((plan) => {
                return <SubscriptionPlanRadioButton
                  key={plan.id}
                  id="annual"
                  title={title(plan)}
                  subtitle={radioButton.automaticAnnualRenewal}
                  originalPrice={getOiginalPrice(plan) == getFinalPrice(plan) ? '' : `${Utility.currency(getOiginalPrice(plan))}`}
                  discountedPrice={`${Utility.currency(getFinalPrice(plan))} + IVA`}
                  isActive={isSelected(plan)}
                  onClick={() => setSelectedPlan(plan)}
                />
              })}
            </div>

            <form className={'sdi-field'}>
              <div className="form-group">
                <label>Codice SDI</label>
                <input
                  value={SDI}
                  type="text"
                  name="sdi"
                  className="form-control"
                  placeholder="Inserisci il tuo codice SDI"
                  required
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event && event.target.value != undefined) setSDI(event.target.value)
                  }}
                />
              </div>
            </form>

            {selectedPlan && selectedPlan.methods && <div className="subscription-plan-container__buttons">
              {selectedPlan.methods.includes('paypal') && !cardFormVisible &&
                <PaymentButton
                  extraClass="paypal"
                  text={""}
                  withIcon
                  onClick={() => { setConfirmationModal({ block: () => handlePayPalPayment(subscription) }) }}
                />
              }

              {selectedPlan.methods.includes('credit_card') && !cardFormVisible &&
                <PaymentButton
                  extraClass="credit-card"
                  text={Utility.paymentMethodTitle('credit_card')}
                  onClick={() => setCardFormVisible(true)
                  }
                />
              }

              {cardFormVisible &&
                <Elements stripe={stripePromise} options={{locale: 'it'}}>
                  <CreditCardFormStripe
                    subscription={subscription}
                    selectedPlan={selectedPlan}
                    city_id={city_id}
                    SDI={SDI}
                    promoCode={promoCode}
                    variant={variant}
                    onClose={() => setCardFormVisible(false)}
                  />
                </Elements>}

              {selectedPlan.methods.includes('bank_transfer') && !cardFormVisible &&
                <PaymentButton
                  extraClass="bank-transfer"
                  text={Utility.paymentMethodTitle('bank_transfer')}
                  onClick={() => { setConfirmationModal({ block: () => handleBankTransferPayment(subscription) }) }}
                />
              }

            </div>}

          </div>}
          <div className={'login__image'}>
            <img style={{ width: 450, paddingTop: 24 }} src={imageMap[Utility.subscriptionType(subscription!)]} alt="Logo collega" />
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default SubscriptionPlan;