import moment from 'moment';
import { DateFormat } from 'values/values';
import { User } from './User';
import Office from './Office';
import { Skill, Subject } from './Profile';

export class Demand {
    id?: number
    user?: User
    datetime?: string
    type?: string
    subject?: Subject
    money?: number
    notes?: string
    skill?: Skill
    activity?: string
    judge?: string
    office?: Office
    status?: string
    active_candidates: Array<Candidate> = []
    created_at?: string
    deleted?: boolean
    is_delayed?: boolean
    can_be_delayed?: boolean

    static at = (demand: Demand): string => {
        return 'Il ' + moment(demand.datetime).format(DateFormat.profile) + ' ore ' + moment(demand.datetime).format(DateFormat.time)
    }
}

export class Candidate {
    id?: number
    user?: User
    status?: string
    demand?: Demand
    deleted?: boolean
}