import React, {FormEvent, Fragment, useState} from 'react';
import {useDispatch} from 'react-redux';
import imagePreview from '../../image/preview-verbal.svg';
import Input from '../common/Input';
import strings from '../../values/strings';
import {setMineOrShared} from '../../store/reducers/RecordReducer';


const RecordRightShared: React.FC = () => {

  const dispatch = useDispatch();

  const [link, setLink] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const preventSubmit = () => {
    return (event: FormEvent<HTMLFormElement>) => {
      if (link.length === 0) {
        event.preventDefault();
      }
    };
  };

  return (
    <Fragment>
      <div className={'previewVerbal'}>
        <img src={imagePreview} alt="preview"/>
        <form onSubmit={preventSubmit()} target={'_blank'} method={'get'} action={`${link}/${code}`}>
          <p className={'subtitle'}>{strings.records.right.shared.copy}</p>
          <p className={'caption'}>{strings.records.right.shared.link}</p>
          <Input
            value={link}
            onChange={(e?: React.ChangeEvent<HTMLInputElement>) => setLink(e!.target.value)}
            placeholder={strings.fieldPlaceHolderLink}
          />
          <p className={'caption'}>{strings.records.right.shared.code}</p>
          <Input
            value={code}
            onChange={(e?: React.ChangeEvent<HTMLInputElement>) => setCode(e!.target.value)}
            placeholder={strings.fieldPlaceHolderCode}
          />
          <button className={'button-primary'}>
            <span>{strings.records.right.shared.action.go}</span>
          </button>
        </form>
        <div className={'close'} onClick={() => dispatch(setMineOrShared(true))} />
      </div>
    </Fragment>
  );
};

export default RecordRightShared;
