import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { DateFormat, EventStatus, ResourceType } from '../../values/values';
import Event from '../../store/types/Event';
import arrow from 'image/icons/arrow-agenda.svg'
import { alert, news } from '../../image/icons';
import { useDispatch, useSelector } from 'react-redux';
import { IAgendaReducer, setCurrentEvent } from '../../store/reducers/AgendaReducer';
import strings from '../../values/strings';
import { selectEvent } from '../../store/reducers/AppReducer';

interface ICommitmentEvent {
  event: Event;
  showDate?: boolean;
}

const CommitmentEvent: React.FC<ICommitmentEvent> = ({ event, showDate = false }) => {

  const currentEvent = useSelector((state: IAgendaReducer) => state.agenda.event);
  const dispatch = useDispatch();

  return (
    <div
      style={{ opacity: event.eventStatus() === EventStatus.TODO ? 1 : 0.4 }}
      className={classNames('commitment__date__content__content', {
        app: event.resourcetype === ResourceType.Appointment,
        udi: event.resourcetype === ResourceType.Hearing,
        ade: event.resourcetype === ResourceType.Fulfillment,
        notice: event.resourcetype === ResourceType.Notice,
        news: event.resourcetype === ResourceType.News,
        selected: currentEvent && currentEvent.id === event.id
      })}
      key={event.id}
      onClick={() => {
        if (event.resourcetype === ResourceType.News) {
          if (event.news) {
            const win = window.open(event.news.link, '_blank');
            if (win) {
              win.focus();
            }
          }

        } else {
          let _event: any = event
          
          if (event.resourcetype === ResourceType.Notice) {
            _event = event.fulfillment
          }

          dispatch(selectEvent());
          dispatch(setCurrentEvent(_event));
        }
      }}>
      <div className={'right-border'}>
        {(event.resourcetype !== ResourceType.Notice && event.resourcetype !== ResourceType.News) && <div className={'right-border__content'} />}
        {event.resourcetype === ResourceType.Notice && (
          <div className={'right-border__alert'}>
            <img src={alert} alt="alert" />
          </div>
        )}
        {event.resourcetype === ResourceType.News && (
          <div className={'right-border__alert'}>
            <img src={news} alt="news" />
          </div>
        )}
      </div>
      <div className={'detail-name'}>
        {(event.resourcetype === ResourceType.News && event.news) && (
          <p>{event.news.title || event.display_name}</p>
        )}
        {(event.resourcetype !== ResourceType.News || ((event.resourcetype === ResourceType.News && !event.news))) && (
          <p>{event.display_name}</p>
        )}
      </div>

      <div className={'detail-date'}>
        <div className={'detail-date-row'}>
          {showDate && <p className={'day'}>{moment(event.date).format(DateFormat.dayWithNumberAndMounthAndYear)}</p>}
        </div>
        <div className={'detail-date-row'}>

          {!(event.resourcetype === ResourceType.Notice) && <p className={'time'}>
            {event.all_day && strings.agenda.add.allDay}
            {!event.all_day && <>{event.start}<span><img src={arrow} alt="arrow" /></span>{event.end}</>}
          </p>}

          <p className={'kind'}>
            {event.resourcetype === ResourceType.Notice && 'Avviso'}
            {event.resourcetype === ResourceType.Fulfillment && 'Adempimento'}
            {event.resourcetype === ResourceType.Hearing && 'Udienza'}
            {event.resourcetype === ResourceType.Appointment && 'Appuntamento'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CommitmentEvent;
