import React, { useEffect, useState, useCallback } from 'react';

import Input from 'components/common/Input';
import { close } from '../../image/icons';
import OutsideClickHandler from 'react-outside-click-handler';
import strings from 'values/strings';
import { useDispatch } from 'react-redux';
import { setSearchedEvents, setSearchVisibility } from 'store/reducers/AgendaReducer';
var _ = require('lodash');


interface Props {
  callback: (query: string) => void;
}

const Search: React.FC<Props> = ({ callback }) => {

  const dispatch = useDispatch();

  const [query, setQuery] = useState<string>('');
  const [timeout, _setTimeout] = useState<any>()


  const checkIfIsEmptySearch = useCallback(() => {
    if (query.length === 0) {
      dispatch(setSearchedEvents(undefined, true));
      dispatch(setSearchVisibility(false));
    }
  }, [query]);

  useEffect(() => {
    handleQuery()
  }, [query]);


  let handleQuery = async () => {
    if (!_.isEmpty(query)) {

      dispatch(setSearchedEvents(undefined, true));

      if (timeout) clearTimeout(timeout)
      _setTimeout(setTimeout(async () => {
        callback(query);
      }, 300))
    }
  }


  return (
    <OutsideClickHandler onOutsideClick={checkIfIsEmptySearch}>
      <form onSubmit={(event) => event!.preventDefault()}>
        <Input
          value={query}
          onChange={(e?: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e!.target.value);
          }}
          placeholder={strings.fieldPlaceHolderSearch}
          autoFocus={true}
          style={{
            paddingRight: 35
          }} />
        <div className={'close'} style={{
          background: `url(${close}) center center no-repeat`
        }} onClick={() =>  dispatch(setSearchVisibility(false))} />
      </form>
    </OutsideClickHandler>
  );
};

export default Search;