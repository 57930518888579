import React from 'react';
import logo from '../image/logo-collega.svg';
import strings from 'values/strings';

const Loading: React.FC = () => {

  return (
    <div className={'loading-wrapper'}>
      <div className={'logo-container'}>
        <img className={'logo'} src={logo} alt="Logo collega"/>
      </div>
      <h1>{strings.loading}</h1>
      <div className={'loader'}/>
    </div>
  );
};

export default Loading;
