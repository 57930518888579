import React, { Fragment, useEffect, useState } from "react";
import SubscriptionCard from "components/subscription/SubscriptionCard";
import { Link, useHistory, useLocation } from "react-router-dom";
import UserService from "services/UserServices";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionInfoType, SubscriptionType } from "values/values";
import { setSubscription } from "store/reducers/SubscriptionReducer";
import { User } from "store/types/User";
import { IUserReducer } from "store/reducers/UserReducer";
import logo from '../image/logo-collega.svg';
import skill from '../image/skill__image.svg';
import _ from 'lodash';
import Storage from "store/Storage";
import { Subscription } from "store/types/Subscriptions";
import collega from '../image/collega.svg';
import professional from '../image/icons/activity/collega-gold.svg';
import gold from '../image/gold.svg';

const Subscriptions: React.FC = () => {
  const history = useHistory();

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

  const [subscriptionInfoResponse, subscriptionInfoError, subscriptionInfoRequest] = UserService.useSubscriptionsInfoAPI()
  const [subscriptionsInfo, setSubscriptionsInfo] = useState<Array<SubscriptionInfo>>([])

  const [user, , userRequest] = UserService.useUserAPI()
  let [skills, , skillsRequest] = UserService.useSearchSkillsAPI()

  const dispatch = useDispatch();
  let location = useLocation()


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let token: string | null = queryParams.get('token')
    skillsRequest('')

    if (token != undefined) {
      Storage.set(Storage.AUTH_TOKEN, token).then(() => {
        userRequest() // Mobile only
      })

    } else {
      subscriptionInfoRequest() // Web only
    }
  }, [location]);



  useEffect(() => {
    if (user == undefined) return
    subscriptionInfoRequest()

  }, [user])

  useEffect(() => {
    if (skills) {
     
    }

  }, [skills])

  useEffect(() => {
    if (subscriptionInfoResponse) {
      filteredList()
    }
  }, [subscriptionInfoResponse])


  const borderColor = (type: SubscriptionInfoType) => {
    switch (type) {
      case SubscriptionInfoType.SKILLS: return 'rgba(32, 138, 134, 1)';
      case SubscriptionInfoType.PROFESSIONAL: return 'rgba(236, 200, 79, 1)';
      case SubscriptionInfoType.GOLD: return 'rgba(171, 130, 83, 1)';
      default: return '';
    }
  }

  const borderActiveColor = (subscription: Subscription) => {
    switch (subscription.type) {
      case SubscriptionType.PROFESSIONAL: return 'rgba(236, 200, 79, 1)';
      case SubscriptionType.SKILLS: return 'rgba(32, 138, 134, 1)';
      case SubscriptionType.GOLD: return 'rgba(171, 130, 83, 1)';
    }

  }

  const filteredList = () => {
    var list: Array<SubscriptionInfo> = []

    if (!User.isGold(currentUser)) {
      let gold = subscriptionInfoResponse.filter((s) => s.type == SubscriptionInfoType.GOLD)
      if (!_.isEmpty(gold)) list.push(_.first(gold)!)
    }

    if (!User.isProfessional(currentUser)) {
      let professional = subscriptionInfoResponse.filter((s) => s.type == SubscriptionInfoType.PROFESSIONAL)
      if (!_.isEmpty(professional)) list.push(_.first(professional)!)
    }

    let skills = subscriptionInfoResponse.filter((s) => s.type == SubscriptionInfoType.SKILLS)
    if (!_.isEmpty(skills)) list.push(_.first(skills)!)

    setSubscriptionsInfo(list)
  }

  let subscriptionIcon = (subscription: Subscription) => {
    switch (subscription.type) {
      case SubscriptionType.PROFESSIONAL: return professional
      case SubscriptionType.SKILLS: return collega
      case SubscriptionType.GOLD: return gold
    }
  }

  const renderActiveSubscription = (subscription: Subscription) => {
    return (
      <div className="card-container-wrapper" key={subscription.id}>
        <div className="card__container" style={{ borderColor: borderActiveColor(subscription), marginBottom: 20, flexGrow: 1 }}>
          <div className="card__header">
            <div className="card__title">
              <img
                src={subscriptionIcon(subscription)} className="subscription-icon"
                alt="Collega Logo" />
              <h3>{subscription.title}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className={'header-subscription'}>
        <Link to={'/dashboard'}><img className={'logo subscription-logo'} src={logo} alt="Logo collega" /></Link>

      </div>
      <div className={"subscriptions-container"}>
        <div className={'wrapper-login'}>
          <div className={'login'} style={{ paddingTop: '8px' }}>
            <div className="collega-details__title">{'Abbonamenti acquistabili'}</div>

            <div className={'login__form'}>
              {subscriptionsInfo && subscriptionsInfo.length > 0 ? (
                subscriptionsInfo.map((subscriptionInfo: SubscriptionInfo) => (
                  (subscriptionInfo.type === "gold" ||
                    ((subscriptionInfo.type === "competenze" || subscriptionInfo.type === "professional") && User.skillsBuyable(currentUser!, skills))) && (
                    <SubscriptionCard
                      key={subscriptionInfo.id}
                      subscription={subscriptionInfo}
                      borderColor={borderColor(subscriptionInfo.type!)}
                      onClick={() => {
                        dispatch(setSubscription(subscriptionInfo));
                        history.push(`/subscription?type=${subscriptionInfo.type}`);
                      }}
                    />
                  )
                ))
              ) : (
                <div className="empty-state-text">
                  Nessuna abbonamento disponibile
                </div>
              )}
              <div className={'login'} style={{ marginTop: 50 }}>
                <div className="collega-details__title">{'I tuoi abbonamenti attivi'}</div>

                <div className="login__form">
                  {currentUser && currentUser.subscriptions.length > 0 ? (
                    currentUser.subscriptions.map((subscription: Subscription) =>
                      renderActiveSubscription(subscription)
                    )
                  ) : (
                    <div className="empty-state-text">
                      Nessuna abbonamento attivo
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={'login__image'}>
              <img style={{ width: 450 }} src={skill} alt="Logo collega" />
            </div>
          </div>
        </div>
      </div>

    </Fragment>

  );
};

export default Subscriptions;
