import axios from 'axios';
import Storage from '../store/Storage';
import { NetworkResponse } from './NetworkResponse';
import _ from 'lodash';

export enum NetworkMethod {
  post = 'POST',
  get = 'GET',
  put = 'PUT',
  delete = 'DELETE'
}

class Network {
  static baseURL = 'https://servizi-staging.collegaonline.it'
  // static baseURL = 'https://servizi-staging.collegaonline.it' // TODO: Move into .env
  // static baseURL = 'http://localhost:3000' // TODO: Move into .env

  static fetchAPI = async (method: any, endpoint: string, auth: boolean, data?: any, params?: any): Promise<NetworkResponse> => {
    await Network.buildHeaders(auth);

    const axiosInstance = axios({
      method,
      baseURL: Network.buildURL(endpoint),
      data,
      params
    });

    try {
      const response = await axiosInstance;
      return new NetworkResponse(response.data, undefined);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        await Storage.set(Storage.AUTH_TOKEN, null)
        await Storage.set(Storage.USER_ID, null)

        window.location.href = '/signin'
      }

      if (error.response && error.response.status >= 500) {
        return new NetworkResponse(undefined, error.response.data, true);
      }

      return new NetworkResponse(undefined, error.response && error.response.data);
    }

  };

  static retrieveError = (field: string, error?: any, path?: string): string | undefined => {
    if (error !== undefined) {

      if (path !== undefined) {
        let i: number = 0;
        let _error = error;

        const segments = path.split('/');
        do {
          if (!_error) {
            return undefined;
          }
          if (i === segments.length) {
            return _error[field];
          }

          _error = _error[segments[i]];

          i++;
        } while (i <= segments.length);
      }

      return _.get(error, field);
    }

    return undefined;
  };


  // Requests
  private static buildURL = (endpoint: string): string => {
    return Network.baseURL + '/api/' + endpoint;
  };

  private static buildHeaders = async (auth: boolean) => {
    const token = await Storage.get(Storage.AUTH_TOKEN);

    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Client-App'] = 'collega-web';

    auth && token ? (axios.defaults.headers["Authorization"] = `Token ${token}`) : delete axios.defaults.headers["Authorization"]
  };
}

export default Network;
