import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IUserReducer } from 'store/reducers/UserReducer';
import _ from 'lodash';
import { Subscription } from 'store/types/Subscriptions';
import moment from 'moment';
import collega from '../../image/collega.svg';
import professional from '../../image/icons/activity/collega-gold.svg';
import gold from '../../image/gold.svg';
import colors from 'values/colors';
import { ActivityModal } from 'store/types/ActivityModal';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import { DateFormat, SubscriptionType } from 'values/values';
import Utility from 'utils/utils';

type Props = {
    onPress: (subscription: Subscription) => void
}

const SubscriptionsEdit: React.FC<Props> = (props) => {

    const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

    let subscriptionIcon = (subscription: Subscription) => {
        switch (subscription.type) {
            case SubscriptionType.PROFESSIONAL: return professional
            case SubscriptionType.SKILLS: return collega
            case SubscriptionType.GOLD: return gold
        }
    }

    const renderSubscription = (subscription: Subscription) => {
        let expiration_date = moment(subscription.expire_at).format(DateFormat.profile);
        let description = subscription.unsubscribed
            ? `L'abbonamento scadrà il ${expiration_date}. Potrai continuare ad utilizzare l'abbonamento fino alla data di scadenza.`
            : `L'abbonamento verrà rinnovato automaticamente il ${expiration_date} al costo di ${subscription.cost}€ + IVA.`;

        return (
            <div className="card-container" style={{ borderColor: colors.border }}>
                <div className="row" style={{ marginBottom: 16 }}>
                    <img src={subscriptionIcon(subscription)} className="subscription-icon" />
                    <h3 className="title" style={{ color: colors.black }}>{subscription.title}</h3>
                </div>
                <p className="pay-method">Modalità di pagamento: <strong>{Utility.paymentMethodTitle(subscription.payment_method!)}</strong></p>
                <p className="description">{description}</p>

                {!subscription.unsubscribed && (
                    <div className="button-container" onClick={() => { props.onPress(subscription) }}>
                        <button className="cancel-button">Disdici abbonamento</button>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="safe-area-container">
            {currentUser && currentUser.subscriptions && <div className="view-container">
                <div>
                    <h2>{'Gestione abbonamenti'}</h2>
                    { currentUser.subscriptions.length> 0 && <h2 style={{fontSize: 16, marginTop: 8}}>{`Hai ${currentUser.subscriptions.length} ${currentUser.subscriptions.length > 1 ? 'abbonamenti attivi' : 'abbonamento attivo'}`}</h2>}
                </div>

                <div className="horizontal-scroll-container">
                    {currentUser.subscriptions.length > 0 ? (
                        // Render the list of subscriptions
                        currentUser.subscriptions.map((subscription, index) => (
                            <div key={index} className="horizontal-item">
                                {renderSubscription(subscription)}
                            </div>
                        ))
                    ) : (
                        <div className="empty-state">
                            <p className='empty-state-text'>Non hai nessun abbonamento attivo.</p>
                        </div>
                    )}
                </div>
            </div>}
        </div >


    );
}

export default SubscriptionsEdit;
