import React, { FC, useState, useEffect } from 'react';
import { User } from 'store/types/User';
import edit from '../../image/edit.svg';
import swap from '../../image/swap.svg';
import ProfileSkills from './ProfileSkills';
import Modal from 'components/common/Modal';
import { Skill } from 'store/types/Profile';
import strings from 'values/strings';
import UserService from 'services/UserServices';
import SkillProfileItem from './SkillProfileItem';
import { SkillsType } from 'values/values';
import { ProfileSwitch } from './ProfileItem';
import ProfileWrapper from './ProfileWrapper';
import _ from 'lodash';
import QualificationOptions from './QualificationOption';
import toast from 'react-hot-toast';

type BasicCoWorkerProps = {
  currentUser: User;
}

const BasicCoWorker: FC<BasicCoWorkerProps> = ({ currentUser }) => {
  const [basicSkillModal, setBasicSkillModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [skillsResponse, , skillsRequest] = UserService.useSearchSkillsAPI();
  const [updateResponse, , updateRequest] = UserService.useUpdateUserMeAPI();
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    skillsRequest('');
  }, []);

  useEffect(() => {
    if (skillsResponse) setIsLoading(false);
  }, [skillsResponse]);

  useEffect(() => {
    if (updateResponse) toast.success("Profilo aggiornato con successo!");
  }, [updateResponse]);


  const basicSkills = currentUser.profile.skills
    .filter(skill => skill.is_main === true)
    .sort((a, b) => a.name === 'Generica' ? -1 : (b.name === 'Generica' ? 1 : 0));


  useEffect(() => {

  }, [checked])

  const handleIsChancellor = async (value: boolean) => {
    const updatedUser = new User();
    Object.assign(updatedUser, currentUser, {
      profile: {
        ...currentUser.profile,
        is_chancellor: value,
      },
    });

    try {
      updateRequest(updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <>
      <div className={"basic-worker-container"}>

        {/* TITLE */}
        <div className={"basic-worker-header"}>
          <span>{strings.profile.basicCoWorker.title}</span>
        </div>

        {/* AdC */}
        <div className={'info__container_secondary'} style={{ paddingRight: 8 }}>
          <ProfileWrapper title={''}>
            <ProfileSwitch
              content={'Notifiche per Attività di Cancelleria'}
              checked={currentUser.profile.is_chancellor ? currentUser.profile.is_chancellor : false}
              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                let value = event.target.checked
                if (value !== undefined)
                  handleIsChancellor(value)
              }}
            />
          </ProfileWrapper>
        </div>

        <div className='secondary-divider' />

        {/* SKILLS */}
        <div className='skill-container'>
          <div className={"basic-worker-header"}>
            <span className='skill-title'>{'Competenza Principale'}</span>
            {basicSkills.length > 1 && (
              <img
                title='Sostituisci la competenza principale'
                src={swap}
                alt="swap-icon"
                onClick={() => {
                  setBasicSkillModal(true)
                }}
              />
            )}
          </div>

          <div className={"skill-container"} style={{ marginTop: 16 }}>
            <div className={"skill-wrapper"}>
              {_.isEmpty(basicSkills.filter((skill) => skill.id != 12)) && <div style={{marginBottom: 16}}>
                <span className={'role'}>{"Nessuna competenza"}</span>
              </div>}

              {basicSkills.filter((skill) => skill.id != 12).map((skill: Skill) => {
                return <SkillProfileItem
                  key={_.uniqueId()}
                  skill={skill}
                  isGold={User.isGold(currentUser)}
                  onPress={() => { }}
                // onPress={() => props.onPress(hasSkillsToSelect())}
                />
              })}
            </div>
          </div>

          {basicSkills.length <= 1 && (
            <div className="add-skill-button">
              <button onClick={() => setBasicSkillModal(true)}>
                {strings.profile.basicCoWorker.addSkillButton}
              </button>
            </div>
          )}
        </div>

        <div className='secondary-divider' />

        {/* Qualifiche */}
        <div className={"qualification-container"} style={{ marginBottom: 8 }}>
          <p>{strings.profile.basicCoWorker.qualificationTitle}</p>
          <div style={{ paddingTop: 4, paddingBottom: 16 }}>
            <span className='span'>{strings.profile.basicCoWorker.qualificationSubtitle}</span>
          </div>
          <div className={"qualification-options-wrapper"}>
            <QualificationOptions currentUser={currentUser} />
          </div>
        </div>
      </div>

      {basicSkillModal && !isLoading &&
        <Modal onClose={() => setBasicSkillModal(false)}>
          <ProfileSkills maxCheck={1} type={SkillsType.BASIC} allSkills={skillsResponse} />
        </Modal>
      }
    </>
  );
};

export default BasicCoWorker;
