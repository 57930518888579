import { useState } from 'react';
import Network, { NetworkMethod } from 'network/Network';
import { Item, ItemCategory } from 'store/types/Item';
import _ from 'lodash';
import { setIsLoading } from 'store/reducers/AppReducer';
import { useDispatch } from 'react-redux';

class CourseService {

  static useCourseCategoriesAPI = (): [Array<Item>, any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    let request = async () => {

      let method = NetworkMethod.get
      let endpoint = 'categories/'
      let params = {
        tree: 'course',
      }

      let response = await Network.fetchAPI(method, endpoint, false, null, params)
      setError(response.error)

      if (response.data) {

        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useCoursesAPI = (): [Array<Item>, any, (page: number, category?: ItemCategory, query?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    let request = async (page: number, category?: ItemCategory, query?: string) => {

      let method = NetworkMethod.get
      let endpoint = 'catalogitems/'
      let params = {
        category: category && category.id,
        tree: 'course',
        page: page,
        search: query,
      }

      let response = await Network.fetchAPI(method, endpoint, false, null, params)
      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useCourseAPI = (): [Item, any, (course: number, category?: ItemCategory) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    let request = async (course: number, category?: ItemCategory) => {

      let method = NetworkMethod.get
      let endpoint = `catalogitems/${course}/`
      let params = {
        category: category && category.id
      }

      let response = await Network.fetchAPI(method, endpoint, true, null, params)
      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  static usePurchasedAPI = (): [Array<Item>, any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    let request = async () => {

      let method = NetworkMethod.get
      let endpoint = 'purchaseditems/'
      let params = {
        tree: 'course'
      }

      let response = await Network.fetchAPI(method, endpoint, true, null, params)
      setError(response.error)

      if (response.data) {

        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  static useOrderAPI = (): [Array<Item>, any, (item: Item, order_id?: number) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();


    let request = async (item: Item, order_id?: number) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.post
      let endpoint = `catalogitems/${item.id}/order/`
      let data = { order: order_id }


      let response = await Network.fetchAPI(method, endpoint, true, data, null)
      dispatch(setIsLoading(false));

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

}

export default CourseService