import React, { ChangeEvent, FocusEvent, MouseEvent } from 'react';
import classNames from 'classnames';
import { add, basket } from '../../image/icons';
import gold from '../../image/gold.svg';
import professional from '../../image/icons/activity/collega-gold.svg';

export interface IInputProps {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value?: string | undefined;
  error?: string;
  autoFocus?: boolean | undefined;
  readOnly?: boolean | undefined;
  disabled?: boolean | undefined;
  cancelable?: boolean;
  onChange?: (event?: ChangeEvent<HTMLInputElement>) => any;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => any;
  onCancel?: (event?: MouseEvent<HTMLButtonElement>) => any;
  style?: React.CSSProperties;
  hasIcon?: boolean
}

const Input: React.FC<IInputProps> = ({ label, type = 'text', name, error, onChange, onBlur, placeholder, value, autoFocus, readOnly, cancelable, onCancel, style, disabled, hasIcon }) => {
  return (
    <>
      <div style={{ display: 'flex', }}>
        {hasIcon && <img src={gold} alt={'collega icon'} style={{ width: 20, height: 20, marginRight: 8, marginTop: 10 }} />}
        {label && (<label>{label}</label>)}
      </div>

      <input
        type={type}
        name={name}
        readOnly={readOnly}
        autoFocus={autoFocus}
        className={classNames({ 'input-error': error, cancelable })}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        style={style}
        disabled={disabled}
      />


      {cancelable &&
        <button className={'input-trash'} onClick={onCancel} type={'button'}
          tabIndex={-1}><img src={basket} alt="basket" /></button>}
      {error && <p className={'error'}>{error}</p>}
    </>
  );
};

interface ICancelableFieldProps extends IInputProps {
  showField: boolean;
  labelAdd: string;
  onCancel: (event?: MouseEvent<HTMLButtonElement>) => any;
  handleShow: (event?: MouseEvent<HTMLButtonElement>) => any;
}

const CancelableInputField: React.FC<ICancelableFieldProps> =
  ({
    showField,
    labelAdd,
    handleShow,
    ...rest
  }) => {
    return (
      <div className={'form__full'}>
        {showField && (
          <Input
            {...rest}
            cancelable={true}
          />
        )}
        {!showField && (
          <button className={'button-secondary icon-right'}
            type={'button'}
            onClick={handleShow}>
            <span className={'txt'}>{labelAdd}</span>
            <span className={'icon'}><img src={add} alt="add" /></span>
          </button>
        )}
      </div>
    );
  };

export { CancelableInputField };

export default Input;
