import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { inRecordCreation } from '../../store/reducers/RecordReducer';
import RecordsService from '../../services/RecordServices';
import strings from '../../values/strings';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';

const RecordModal: React.FC = () => {

  const dispatch = useDispatch();
  const [, , createRequest] = RecordsService.useCreateRecordAPI();


  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.popupNewRecord)
  }, [])

  return (
    <Fragment>
      <div className={'modal opened'}>
        <div className={'modal__content'}>
          <p className={'title'}>{strings.records.modal.title}</p>
          <p className={'copy'}>{strings.records.modal.copy}</p>
          <button className={'button-primary'} onClick={() => {
            AnalyticsService.screenName(AnalyticsScreens.records)
            createRequest();
          }}>
            <span>{strings.records.modal.action.confirm}</span>
          </button>
          <button className={'cancel'} onClick={() => {
            AnalyticsService.screenName(AnalyticsScreens.records)
            dispatch(inRecordCreation(false))
          }}>
            {strings.records.modal.action.cancel}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default RecordModal;
