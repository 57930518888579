import React from 'react';
import 'moment/locale/it'
import { Demand, Candidate } from 'store/types/Demand';
import { ActivitiesContext } from './ActivityRequest';
import moment from 'moment';
import { DemandsStatus, CandidatesStatus } from 'values/values';
import _ from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ActivitiesReducer } from 'store/reducers/ActivitiesReducer';
import strings from 'values/strings';


interface Props {
  demand: Demand,
  candidate?: Candidate
}

let ActivityStatuses: React.FC<Props> = ({ demand, candidate }) => {

  let context = useSelector((state: ActivitiesReducer) => state.activitiesReducer.activitiesContext)

  let statuses = () => {
    switch (context) {
      case ActivitiesContext.demands:
        if (demand.deleted) {
          return [{ label: strings.activityStatuses.demand.label.deleted, class: 'expired' }]
        }
        
        switch (demand.status as DemandsStatus) {
          case DemandsStatus.PENDING:
            let items = []
            let count = demand && demand.active_candidates ? demand.active_candidates.length : 0
            let suffix = count === 1 ? strings.activityStatuses.count :  strings.activityStatuses.counts
            let expiring = moment().diff(moment(demand.datetime), 'hours') > -24

            if (demand && _.isEmpty(demand.active_candidates)) {
              items = [{ label: strings.activityStatuses.demand.label.send, class: 'send' }]
            } else {
              items = [{ label: count + suffix, class: 'colleagues' }]
            }

            if (expiring) items.push({ label: strings.activityStatuses.demand.label.expiring, class: 'expiring' })
            return items

          case DemandsStatus.ASSIGNED: return [{ label: strings.activityStatuses.demand.label.assigned, class: 'assigned' }, { label: strings.activityStatuses.demand.label.execution, class: 'execution' }]
          case DemandsStatus.COMPLETED: return [{ label: strings.activityStatuses.demand.label.ended, class: 'ended' }, { label: strings.activityStatuses.demand.label.toPay, class: 'toPay' }]
          case DemandsStatus.PAID: return [{ label: strings.activityStatuses.demand.label.paid, class: 'paid' }]
          case DemandsStatus.ANNULLED: return [{ label: strings.activityStatuses.demand.label.canceled, class: 'canceled' }]
          case DemandsStatus.EXPIRED: return [{ label: strings.activityStatuses.demand.label.expired, class: 'expired' }]
        }

      case ActivitiesContext.candidates:
        if (candidate && candidate.deleted) {
          return [{ label: strings.activityStatuses.demand.label.deleted, class: 'expired' }]
        }

        if (candidate && candidate.status === CandidatesStatus.DEAD) return [{ label: strings.activityStatuses.demand.label.notAssigned, class: 'notAssigned' }]
       
        switch (demand.status as DemandsStatus) {
          case DemandsStatus.PENDING:
            if (candidate && candidate.status === CandidatesStatus.ACCEPTED) {
              return [{ label: strings.activityStatuses.demand.label.pending, class: 'waiting' }]

            } else if (candidate && candidate.status === CandidatesStatus.REJECTED) {
              return [{ label: strings.activityStatuses.demand.label.rejected, class: 'notAssigned' }]

            } else {
                return [{ label: strings.activityStatuses.demand.label.new, class: 'new' }] // PENDING
            }

          case DemandsStatus.ASSIGNED: return [{ label: strings.activityStatuses.demand.label.assigned, class: 'assigned' }]
          case DemandsStatus.ANNULLED: return [{ label: strings.activityStatuses.demand.label.canceled, class: 'canceled' }]
          case DemandsStatus.EXPIRED: return [{ label: strings.activityStatuses.demand.label.expired, class: 'expired' }]
        }

      case ActivitiesContext.managements:
        if (demand.deleted) {
          return [{ label: strings.activityStatuses.demand.label.deleted, class: 'expired' }]
        }

        switch (demand.status as DemandsStatus) {
          case DemandsStatus.ASSIGNED: return [{ label: strings.activityStatuses.demand.label.assigned, class: 'assigned' }]
          case DemandsStatus.COMPLETED: return [{ label: strings.activityStatuses.demand.label.ended, class: 'ended' }, { label: strings.activityStatuses.demand.label.waiting, class: 'toPay' }]
          case DemandsStatus.PAID: return [{ label: strings.activityStatuses.demand.label.payed, class: 'paid' }]
        }

      default: return []
    }
  }

  return (
    <div className={'activity__status__container'}>
      {statuses().map((status, index) => {
        return <span key={index} className={classNames('label', status.class)}>{status.label}</span>
      })}
    </div>
  )
}

export default ActivityStatuses
