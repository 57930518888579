import { ActivitiesContext } from 'components/activity-request/ActivityRequest';
import { Demand, Candidate } from "store/types/Demand";
import { User } from "store/types/User";
import { DemandsStatus, CandidatesStatus, ManagementsStatus } from 'values/values';

const SET_ACTIVITIES_CONTEXT = 'SET_ACTIVITIES_CONTEXT'
const SET_CURRENT_CANDIDATE_USER = 'SET_CURRENT_CANDIDATE_USER'
const SET_CURRENT_DEMAND = 'SET_CURRENT_DEMAND'
const SET_CURRENT_CANDIDATE = 'SET_CURRENT_CANDIDATE'
const SET_SHOULD_PERFORM_REQUEST = 'SET_SHOULD_PERFORM_REQUEST'

const SET_ACTIVITY_DEMANDS_TAB = 'SET_ACTIVITY_DEMANDS_TAB'
const SET_ACTIVITY_CANDIDATES_TAB = 'SET_ACTIVITY_CANDIDATES_TAB'
const SET_ACTIVITY_ASSIGNED_DEMANDS_TAB = 'SET_ACTIVITY_ASSIGNED_DEMANDS_TAB'

export interface ActivitiesReducer {
    activitiesReducer: ActivitiesReducerState
}

export interface ActivitiesReducerState {
    activitiesContext?: ActivitiesContext
    currentDemand?: Demand
    currentCandidate?: Candidate
    currentCandidateUser?: User
    shouldPerformRequest: any
    demandsTab: DemandsStatus
    candidatesTab: CandidatesStatus
    assignedDemandsTab: ManagementsStatus
}


// ACTIONS

export function setShouldPerformRequest(should: boolean) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_SHOULD_PERFORM_REQUEST,
            should: { value: should, datetime: new Date().getTime() },
        })
    }
}

export function setActivitiesContext(context: ActivitiesContext) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_ACTIVITIES_CONTEXT,
            context: context
        })
    }
}

export function setCurrentDemand(demand?: Demand) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_CURRENT_DEMAND,
            demand: demand
        })
    }
}

export function setCurrentCandidate(candidate?: Candidate) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_CURRENT_CANDIDATE,
            candidate: candidate
        })
    }
}

export function setCurrentCandidateUser(user?: User) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_CURRENT_CANDIDATE_USER,
            user: user
        })
    }
}


export function setActivityDemandsTab(tab?: DemandsStatus) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_ACTIVITY_DEMANDS_TAB,
            demandsTab: tab
        })
    }
}

export function setActivityCandidatesTab(tab?: CandidatesStatus) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_ACTIVITY_CANDIDATES_TAB,
            candidatesTab: tab
        })
    }
}

export function setActivityAssignedDemandsTab(tab?: ManagementsStatus) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_ACTIVITY_ASSIGNED_DEMANDS_TAB,
            assignedDemandsTab: tab
        })
    }
}



// REDUCER
const INITIAL_STATE = { activitiesContext: ActivitiesContext.demands, demandsTab: DemandsStatus.ALL, candidatesTab: CandidatesStatus.ALL, assignedDemandsTab: ManagementsStatus.ALL, shouldPerformRequest: {value: false, datetime: new Date().getTime()} }

export function activitiesReducer(state: ActivitiesReducerState = INITIAL_STATE, action: any) {
    switch (action.type) {
        case SET_SHOULD_PERFORM_REQUEST:
            return {
                ...state,
                shouldPerformRequest: action.should
            }

        case SET_ACTIVITIES_CONTEXT:
            return {
                ...state,
                activitiesContext: action.context
            }

        case SET_CURRENT_DEMAND:
            return {
                ...state,
                currentDemand: action.demand
            }

        case SET_CURRENT_CANDIDATE:
            return {
                ...state,
                currentCandidate: action.candidate
            }

        case SET_CURRENT_CANDIDATE_USER:
            return {
                ...state,
                currentCandidateUser: action.user
            }

        case SET_ACTIVITY_DEMANDS_TAB:
            return {
                ...state,
                demandsTab: action.demandsTab
            }

        case SET_ACTIVITY_CANDIDATES_TAB:
            return {
                ...state,
                candidatesTab: action.candidatesTab
            }

        case SET_ACTIVITY_ASSIGNED_DEMANDS_TAB:
            return {
                ...state,
                assignedDemandsTab: action.assignedDemandsTab
            }

        default: return state
    }
}