import React, { useEffect, useState } from "react";
import SubscriptionListItem from "../SubscriptionListItem";
import strings from "values/strings";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import Utility from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import UserService from "services/UserServices";
import InputAutosuggest from '../../common/AutoSuggest'
import { setCityId, setPrice } from "store/reducers/SubscriptionReducer";
import { IUserReducer } from "store/reducers/UserReducer";

interface Props {
  subscriptionInfo: SubscriptionInfo;
}

const CollegaProfessional: React.FC<Props> = ({ subscriptionInfo }) => {
  const collega = strings.subscriptions.collega;

  const [cityPriceResponse, , cityPriceRequest] = UserService.useCityPriceAPI()
  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
  const subscriptionInfoDescriptionArray = subscriptionInfo != undefined ? subscriptionInfo.description!.split('• ').filter(Boolean) : []

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);


  const dispatch = useDispatch()

  const [city1Price, setCity1Price] = useState<any>()
  const [city2Price, setCity2Price] = useState<any>()
  const [maxPrice, setMaxPrice] = useState<any>()

  const [lawfirmCity2, setLawfirmCity2] = useState<any>({
    query: '',
    object: undefined
  })


  useEffect(() => {
    if (currentUser) {
      cityPriceRequest(currentUser.profile.lawfirm.city!.id!.toString())
    }
  }, [currentUser])

  useEffect(() => {
    if (cityPriceResponse) {
      if (lawfirmCity2.object == undefined) {
        setCity1Price({
          city: currentUser!.profile.lawfirm.city!.id,
          price: cityPriceResponse.price
        })

      } else {
        setCity2Price({
          city: lawfirmCity2.object.id,
          price: cityPriceResponse.price
        })
      }
    }
  }, [cityPriceResponse])

  useEffect(() => {
    if (lawfirmCity2.object != undefined) {
      cityPriceRequest(lawfirmCity2.object.id.toString())
    }
  }, [lawfirmCity2])

  useEffect(() => {
    if (city1Price != undefined) {
      if (city2Price != undefined) {
        if (Number(city1Price.price) >= Number(city2Price.price)) setMaxPrice(city1Price)
        if (Number(city1Price.price) <= Number(city2Price.price)) setMaxPrice(city2Price)

      } else {
        setMaxPrice(city1Price)
      }
    }
  }, [city1Price, city2Price])

  useEffect(() => {
    console.log(maxPrice)
    if (maxPrice != undefined) {
      console.log(maxPrice.price)
      dispatch(setCityId(maxPrice.city))
      dispatch(setPrice(Number(maxPrice.price)))
    }
  }, [maxPrice])

  return (
    <div className="collega-details">
      <div className="collega-details__title">{subscriptionInfo.title}</div>
      <ul className="collega-details__list">

        {subscriptionInfo && subscriptionInfoDescriptionArray!.map((description, index) => (
          <SubscriptionListItem key={index} type={Utility.subscriptionType(subscriptionInfo)} text={description} />
        ))}
      </ul>
      <form className="collega-details__form">
        <div className="select-container">

          <InputAutosuggest
            hasIcon={false}
            label={'Scegli il secondo comune in cui operi'}
            placeholder={'Nessuna città'}
            suggestions={citiesResponse || []}
            onChange={(v) => setLawfirmCity2({ query: v, object: undefined })}
            onSelect={(data) => setLawfirmCity2({ query: data.name, object: data.value })}
            value={lawfirmCity2.query}
            onFetch={citiesRequest}
            error={''} />
        </div>
      </form>
      <span className="collega-details__hint">
        {collega.professional.select.hint}
      </span>

      {maxPrice != undefined && <div className="collega-details__price-container">
        <span className="collega-details__price">
          {Utility.currency(maxPrice.price)}
          <span> al {collega.month} + {"IVA"}</span>
        </span>
        <span className="collega-details__renewal">
          {collega.automaticAnnualRenewal}
        </span>
      </div>}
    </div>
  );
};

export default CollegaProfessional;
