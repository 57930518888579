import React from 'react';
import { Subscription } from 'store/types/Subscriptions';
import { SubscriptionInfoType, SubscriptionType } from 'values/values';

interface Props {
  subscription: Subscription
  onConfirm: () => void
  onCancel: () => void
}

const SubscriptionDoneModal: React.FC<Props> = ({ subscription, onConfirm, onCancel }) => {

  const title = () => {
    switch (subscription!.type) {
      case SubscriptionType.GOLD: return subscription!.title
      case SubscriptionType.PROFESSIONAL: return subscription!.title
      case SubscriptionType.SKILLS: return `${subscription.purchased_skills} ${subscription!.title}`
      case SubscriptionInfoType.SKILLS: return subscription!.title
      default: return '';
    }
  }

  const description = () => {
    switch (subscription!.type) {
      case SubscriptionType.GOLD: return 'Puoi aggiungere una descrizione alle tue competenze e al tuo Studio Legale direttamente dal tuo profilo.'
      case SubscriptionType.PROFESSIONAL: return 'Puoi aggiungere le competenze e selezionare \nil secondo comune in cui operi direttamente \ndal tuo profilo. \nD\’ora in poi (fino alla scadenza \ndell’abbonamento) il tuo profilo verrà messo \nin evidenza nelle liste dei candidati e riceverà \nle richieste in maniera prioritaria.'
      case SubscriptionType.SKILLS: return 'Puoi aggiungere le competenze direttamente dal tuo profilo.'
      case SubscriptionInfoType.SKILLS: return 'Puoi aggiungere le competenze direttamente dal tuo profilo.'
      default: return '';
    }
  }

  return (
    <div className={'activity__modal opened'}>
      {subscription && <div className={'modal__content'}>

        <p className={'title'}>{`Grazie per aver acquistato\n${title()}`}</p>
        <p className={'copy'}>{description()}</p>

        <button className={'button-primary'} onClick={onConfirm}>{"Vai al profilo"}</button>
        <button className={'cancel'} onClick={onCancel}>{"Chiudi"}</button>
      </div>}
    </div>
  );
};

export default SubscriptionDoneModal;
