import React from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';


Sentry.init({
    dsn: "https://b83e22b666ec40668f54fc0ad4170f5e@o394373.ingest.sentry.io/5559639",
    autoSessionTracking: true,
    environment: 'production',
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    tracesSampleRate: 1.0,
  });

  
ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<OnboardingMob />, document.getElementById('mobile'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
