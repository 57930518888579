import React, { useEffect, useState, Fragment } from 'react';
import classNames from 'classnames';
import searchIcon from 'image/icons/news-search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IAgendaReducer, setSearchVisibility } from 'store/reducers/AgendaReducer';
import Search from 'components/search/Search';
import { ItemCategory } from 'store/types/Item';
import { setCurrentCourse } from 'store/reducers/SidePanelReducer';
import CourseService from 'services/CourseService';

interface Props {
  onChangeFilter: (category?: ItemCategory, query?: string) => void
  showCredits?: boolean
}

let CoursesFilter: React.FC<Props> = React.memo((props) => {

  let [categoriesResponse, , categoriesRequest] = CourseService.useCourseCategoriesAPI()

  let [categories, setCategories] = useState<Array<ItemCategory>>()

  let [selectedCategory, setSelectedCategory] = useState<ItemCategory>()

  let [hoverCategory, setHoverCategory] = useState<ItemCategory>()

  let [query, setQuery] = useState<string>()

  const isVisibleSearch = useSelector((state: IAgendaReducer) => state.agenda.isVisibleSearch);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!categories) categoriesRequest()
  }, [])

  useEffect(() => {
    setCategories(categoriesResponse)
  }, [categoriesResponse])

  useEffect(() => {
    props.onChangeFilter(selectedCategory, query)
  }, [selectedCategory, query])

  useEffect(() => {
    if (!isVisibleSearch && query) setQuery(undefined)
  }, [isVisibleSearch])

  let isSelectedCategory = (category: ItemCategory) => {
    if (selectedCategory === undefined) return false
    return category.id === selectedCategory.id
  }

  let isHoverCategory = (category: ItemCategory) => {
    if (hoverCategory === undefined) return false
    return category.id === hoverCategory.id
  }

  let searchFilter = () => {
    return (
      <Fragment>
        {isVisibleSearch && <div className={classNames('courses__header__search', { visible: isVisibleSearch })}>
          <Search callback={setQuery} />
        </div>
        }
      {!isVisibleSearch &&  <img src={searchIcon} onClick={() => dispatch(setSearchVisibility(true))} alt={'search'} />}
      </Fragment>
    )
  }

  let temporalFilter = () => {
    return (
      <div className={'courses__category__container'}>
        {categories && categories.map((category: ItemCategory, index) => {
          return (
            <button
            key={index}
              className={classNames('button-filter')}
              style={{ color: isSelectedCategory(category) || isHoverCategory(category) ? category.foreground_color : undefined, background: isSelectedCategory(category) || isHoverCategory(category) ? category.background_color : undefined,
              pointerEvents: isSelectedCategory(category) ? 'none' : undefined,  touchAction: isSelectedCategory(category) ? 'none' : undefined}}
              onClick={() => {setSelectedCategory(isSelectedCategory(category) ? undefined : category);
                dispatch(setCurrentCourse(undefined))
              }}
              onMouseOver={() => setHoverCategory(isHoverCategory(category) ? undefined : category)}
              onMouseOut={() => setHoverCategory(undefined)}
            >
              {category.name}
            </button>
          )
        })}
      </div>
    )
  }

  return (
    <Fragment>
      <div className={classNames('courses__category__search__container', { creditsFix: props.showCredits })} id={'linesNumber'}>
        {searchFilter()}
        {temporalFilter()}
      </div>

    </Fragment>
  );
});

export default CoursesFilter;

