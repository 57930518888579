import React from 'react';
import logo from '../image/logo-collega.svg';
import {Link} from 'react-router-dom';
import strings from 'values/strings';

const PageError: React.FC = () => {

  return (
    <div className={'page-error'}>
      <div className={'logo-container'}>
        <img className={'logo'} src={logo} alt="Logo collega" />
      </div>
      <h1>{strings.pageError.title}</h1>
      <p>{strings.pageError.subtitle}</p>
      <p className={'small'}>{strings.pageError.copy}</p>
      <p><Link to={'/'}>{strings.pageError.cta}</Link></p>
    </div>
  );
};

export default PageError;
