import React, { useState, useEffect } from 'react'
import strings from 'values/strings';
import { Item } from 'store/types/Item';
import { SidePanelReducer, setCurrentCourse } from 'store/reducers/SidePanelReducer';
import { useSelector, useDispatch } from 'react-redux';
import CourseService from 'services/CourseService';
import _ from 'lodash';
import CourseSidePanel, { ItemPanelCtx } from './CourseSidePanel';
import PurchasedItem from './PurchasedItem';
import empty from 'image/empty.svg';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';

interface Props {
  history?: any
}

const Purchased: React.FC<Props> = ({ history }) => {

  let [purchasedResponse, purchasedError, purchasedRequest] = CourseService.usePurchasedAPI()

  let [isLoading, setIsLoading] = useState(true);
  let [purchased, setPurchased] = useState<Array<Item>>([]);
  let [course, setCourse] = useState<Item>();

  const purchasedCourse = useSelector((state: SidePanelReducer) => state.sidePanelReducer.purchasedCourse);
  let dispatch = useDispatch()


  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.purchasedCourses)
    dispatch(setCurrentCourse(undefined))
    purchasedRequest()
  }, [])

  useEffect(() => {
    if (purchasedCourse) setCourse(purchasedCourse)
  }, [purchasedCourse])

  useEffect(() => {
    if (purchasedResponse) setPurchased([...purchased, ...purchasedResponse])
    setTimeout(() => { setIsLoading(false); }, 500);
  }, [purchasedResponse])


  return (
    <div className={'courses__container'}>

      <h2>{strings.courses.purchased.title}</h2>
      <div className={'divider'}></div>

      {purchased &&
        <div className={'courses__card__container purchased'}>
          <div className={'courses__card__'}>

            {/* LOADING */}
            {isLoading &&
              <div className={'loader_empty_state_container'}>
                <div className={'loader_loading'} />
              </div>
            }

            {/* ITEMS */}
            {!isLoading && <>

              {/* EMPTY STATE */}
              {purchasedResponse && _.isEmpty(purchased) && <div className={'empty__panel'}>
                <div className={'empty__container'}>
                  <img src={empty} alt={''} />
                  <span>{strings.courses.empty}</span>
                  <div className={'button__container'}>
                    <button onClick={() => history.push('/courses')} className={'button-primary'}>{strings.courses.courses}</button>
                  </div>
                </div>
              </div>}

              {purchased.map((item: Item, index) => {
                return <PurchasedItem obj={item} key={index} />
              })}

            </>}
          </div>
        </div>
      }

      {course && <CourseSidePanel context={ItemPanelCtx.course} obj={course} />}

    </div>
  )
}

export default Purchased