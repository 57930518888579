import React, { useState, useEffect } from 'react';
import 'moment/locale/it'
import { News } from 'store/types/News';
import moment from 'moment';
import news_placeholder from '../../image/icons/news_placeholder.svg'
import AnalyticsService, { AnalyticsEvents } from 'services/AnalyticsService';


interface Props {
    news: News
}

let NewsItem: React.FC<Props> = React.memo(({ news }) => {

    let [image, setImage] = useState<string>()

    useEffect(() => {
        setImage(news.image || news_placeholder)
    }, [news.image])


    return (
        <a className={'news__card'} href={news.link} onClick={() => AnalyticsService.event(AnalyticsEvents.newsOpen, { news_id: news.id })} target={'_blank'}>
            <div className={'img__container'}>
                <img src={image} alt={news.long_title} onError={() => setImage(news_placeholder)} />
            </div>

            <div className="text">
                <p className="created">{moment(news.created_at).calendar('', {
                    sameDay: '[Oggi]',
                    nextDay: '[Domani]',
                    nextWeek: 'dddd',
                    lastDay: '[Ieri]',
                    lastWeek: '[Lo scorso] dddd',
                    sameElse: 'DD/MM/YYYY'
                })}</p>
                <p className="title">{news.long_title}</p>
                <p>{news.long_text}</p>
                <p className="author">{news.author}</p>
            </div>
        </a>
    );
});

export default NewsItem;

