import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { Item } from "store/types/Item";


interface Props {
  item: Item
  onSuccess: (orderId: any) => void
  onStart: () => void
}


const PayPal: React.FC<Props> = (props) => {

  let currency_code = 'EUR'
  let staging_client_id = 'AT5SIZKyUguRTJdgo-kCCejjCu6oCQjH8LaSji6cLEldv9aQBYwecp1qAzJOYSG-FOH3jBfepDid-w5P'
  let production_client_id = 'AfMl8sa3LM2Ixtq7JM8m-6XEHsfEJjmmxemWypuzdauazMx2vdwob2KQQxfQ2O-mjGC9tzXcKpFVkYUr'

  let cost = (): number => {
    return props.item.cost == null ? 0 : props.item.cost
  }

  let totalAmount = (): number => {
    // if (props.item.cost == null) return 0
    return Number(cost()) + taxes()
  }

  let taxes = (): number => {
    // if (props.item.cost === undefined) return 0
    return cost() * 0.22
  }

  return (
    <PayPalButton
      options={{
        currency: currency_code,
        clientId: production_client_id,
        disableFunding: 'sofort,mybank,credit,card',
      }}

      createOrder={(data: any, actions: any) => {
        props.onStart()

        return actions.order.create({
          purchase_units: [{
            description: (props.item.name || '').substr(0, 120),
            amount: {
              currency_code: currency_code,
              value: totalAmount().toFixed(2),
              breakdown: {
                item_total: {
                  currency_code: currency_code,
                  value: cost()
                },
                tax_total: {
                  currency_code: currency_code,
                  value: taxes().toFixed(2)
                }
              }
            },
          }],
        });
      }}

      onApprove={(data: any, actions: any) => {
        return actions.order.capture().then(function (details: any) {
          props.onSuccess(data.orderID)
        });
      }}
    />
  )

}

export default PayPal;
