import React, { useRef, useState, Fragment } from 'react';
import add from 'image/icons/add-img.svg'
import change from 'image/icons/change-img.svg'

interface Props {
  image?: any,
  onPickedImage?: (image?: any) => void
}

const ProfileImg: React.FC<Props> = ({ image, onPickedImage }) => {

  const [img, setImg] = useState(false)

  const uploadedImage = useRef<any>(null);
  const imageUploader = useRef<any>(null);

  const handleImageUpload = (e: any) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;

      current.file = file;
      reader.onload = (e: any) => {
        current.src = e.target.result;
        if (onPickedImage) onPickedImage(current.src)
      };

      reader.readAsDataURL(file);
      setImg(true)
    }
  };

  // RENDER

  return (
    <Fragment>
      {image ?
        <div style={{ width: "64px", position: 'relative' }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            ref={imageUploader}
            style={{ display: "none" }}
          />
          <div className={'profile__img'} onClick={() => imageUploader.current.click()} >
            <img src={image} ref={uploadedImage} alt={'profile'} />
          </div>
          <img src={change} style={{ position: 'absolute', right: '0px', top: '47px', cursor: 'pointer' }} onClick={() => imageUploader.current.click()} alt={'change'} />
        </div>
        :
        <div style={img ? { width: "64px", position: 'relative' } : { width: "59px" }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            ref={imageUploader}
            style={{ display: "none" }}
          />
          <div className={img ? 'profile__img' : 'profile__img  add'} onClick={() => imageUploader.current.click()} >
            <img src={add} ref={uploadedImage} alt={'add'} />
          </div>
          {img ? <img src={change} style={{ position: 'absolute', right: '0px', top: '47px', cursor: 'pointer' }} onClick={() => imageUploader.current.click()} alt={'change'} /> : <h4>Aggiungi Immagine</h4>}
        </div>
      }

    </Fragment>
  )
}

export default ProfileImg;