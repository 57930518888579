import React, { useState, Fragment, useEffect } from 'react';
import { User } from 'store/types/User';
import Checkboxes from './checkbox/Checkboxes';
import Input from 'components/common/Input';
import strings from 'values/strings';
import { FormFields, ExternalLinks, MonthType, DateFormat } from 'values/values';
import Utility from 'utils/utils';
import { ISignUpError } from 'page/signup/interface/SignupInterface';
import { useDispatch, useSelector } from 'react-redux';
import gold from 'image/icons/activity/collega-gold.svg';
import { NavLink } from 'react-router-dom';
import ProfileImg from './ProfileImg';
import Network from 'network/Network';
import UserService from 'services/UserServices';
import InputAutosuggest from '../common/AutoSuggest'
import { updateTempUser, IUserReducer, createTempUser } from '../../store/reducers/UserReducer';
import { Skill } from 'store/types/Profile';
import moment from 'moment';
import SelectOption from 'store/types/SelectionOption';
import Select from 'components/common/Select';
import EventsService from 'services/EventService';
import smoothscroll from 'smoothscroll-polyfill';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
smoothscroll.polyfill();

var _ = require('lodash');

interface Props {
  currentUser: User;
}


const ProfileSteps: React.FC<Props> = ({ currentUser }) => {

  enum UserProfileSteps { First = 1, Second, Third, Fourth, Fifth, Sixth }

  const [isLoading, setIsLoading] = useState(false);

  let [step, setStep] = useState<UserProfileSteps>(UserProfileSteps.First)
  const [error, setError] = useState();
  const [personalInfo, setPersonalInfo] = useState(false);

  const [birthDate, setBirthDate] = useState<any>({
    day: undefined,
    month: undefined,
    year: undefined
  });

  const [coaOffice, setCoaOffice] = useState<any>({
    query: '',
    object: undefined
  });

  const [lawfirmCity, setLawfirmCity] = useState<any>({
    query: '',
    object: undefined
  })

  const [officesResponse, , officesRequest] = EventsService.useSearchOfficesAPI(true);
  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
  let [updateResponse, updateError, updateRequest] = UserService.useUpdateUserAPI(false)
  const [__, ___, userRequest] = UserService.useUserAPI()  // Used before going back to Profile, to update Redux (since update above is not updating Redux)

  const dispatch = useDispatch();

  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);

  let sendAnalyticsScreenName = (position: number) => {
    switch (position) {
      case UserProfileSteps.First: AnalyticsService.screenName(AnalyticsScreens.profileCompletion1); break
      case UserProfileSteps.Second: AnalyticsService.screenName(AnalyticsScreens.profileCompletion2); break
      case UserProfileSteps.Third: AnalyticsService.screenName(AnalyticsScreens.profileCompletion3); break
      case UserProfileSteps.Fourth: AnalyticsService.screenName(AnalyticsScreens.profileCompletion4); break
      case UserProfileSteps.Fifth: AnalyticsService.screenName(AnalyticsScreens.profileCompletion5); break
      case UserProfileSteps.Sixth: AnalyticsService.screenName(AnalyticsScreens.profileCompletion6); break
    }
  }

  const months = [
    new SelectOption<MonthType>(1, 'Gennaio'),
    new SelectOption<MonthType>(2, 'Febbraio'),
    new SelectOption<MonthType>(3, 'Marzo'),
    new SelectOption<MonthType>(4, 'Aprile'),
    new SelectOption<MonthType>(5, 'Maggio'),
    new SelectOption<MonthType>(6, 'Giugno'),
    new SelectOption<MonthType>(7, 'Luglio'),
    new SelectOption<MonthType>(8, 'Agosto'),
    new SelectOption<MonthType>(9, 'Settembre'),
    new SelectOption<MonthType>(10, 'Ottobre'),
    new SelectOption<MonthType>(11, 'Novembre'),
    new SelectOption<MonthType>(12, 'Dicembre'),
  ];


  useEffect(() => {
    dispatch(createTempUser(currentUser))

    setLawfirmCity({ query: currentUser.profile.lawfirm.city!.name, object: currentUser.profile.lawfirm.city })

    if (currentUser.birth_date) {
      let date = moment(currentUser.birth_date)
      setBirthDate({ day: date.get('D').toString(), month: date.get('M') + 1, year: date.year().toString() })
    }

    if (currentUser.profile.coa_office) {
      setCoaOffice({
        query: currentUser.profile.coa_office.name,
        object: currentUser.profile.coa_office
      })
    }

  }, [currentUser])


  useEffect(() => {
    if (tempUser) {
      updateProfile(FormFields.coa_office, coaOffice.object)
    }
  }, [coaOffice])

  useEffect(() => {
    if (tempUser) {
      updateLegalOffice(FormFields.city, lawfirmCity.object)
    }
  }, [lawfirmCity])

  useEffect(() => {
    if (_.isEmpty(birthDate.year) || birthDate.year.length < 4 || _.isUndefined(birthDate.month) || _.isEmpty(birthDate.day)) { dispatch(updateTempUser(FormFields.birth_date, null)); return }

    let date = birthDate.year + '-' + birthDate.month + '-' + birthDate.day
    let momentDate = moment(date, 'YYYY-MM-DD').format(DateFormat.server)
    dispatch(updateTempUser(FormFields.birth_date, momentDate))
  }, [birthDate])

  useEffect(() => {
    if (updateResponse) setStep(UserProfileSteps.Sixth)
  }, [updateResponse])

  useEffect(() => {
    setError({ ...updateError })
  }, [updateError])

  useEffect(() => {
    setError(undefined)
  }, [tempUser])

  useEffect(() => {
    sendAnalyticsScreenName(step)

    if (step === 3) {
      document.querySelectorAll<HTMLElement>('.step3').forEach(node => node.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' }))
    } else if (step === 4) {
      document.querySelectorAll<HTMLElement>('.step4').forEach(node => node.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' }))
    } else if (step === 5) {
      document.querySelectorAll<HTMLElement>('.step5').forEach(node => node.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' }))
    }
  }, [step])

  let updateProfile = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile,
      [field]: value
    }

    await dispatch(updateTempUser(FormFields.profile, data))
  }

  let updateLegalOffice = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile.lawfirm,
      [field]: value
    }

    await updateProfile(FormFields.lawfirm, data)
  }

  let monthValue = () => {
    if (!birthDate.month) return undefined
    return months.find((months) => months.value === birthDate.month)
  }



  // RENDER

  if (!currentUser) { return null }

  let renderStep1 = () => {
    return (
      <div className={'profile__step'}>
        <h3><span>{strings.profileSteps.step1.title}</span>{strings.profileSteps.step1.title2}</h3>
        <ProfileImg
          image={tempUser!.profile.picture}
          onPickedImage={(picture) => updateProfile(FormFields.picture, picture)} />
      </div>
    )
  }

  let renderStep2 = () => {
    return (
      <Fragment>
        {renderStep1()}
        <div className={'profile__step'}>
          <h3><span>{strings.profileSteps.step2.title}</span>{strings.profileSteps.step2.title2} {!tempUser!.is_certified && "(max 2)"}</h3>
          <Checkboxes
            skills={tempUser!.profile.skills}
            max={tempUser!.is_certified ? undefined : 2}
            // onChangeSelection={(skills: Array<Skill>) => {
            //   updateProfile(FormFields.skills, skills)
            // }}
             />
        </div>
      </Fragment>
    )
  }

  let renderStep3 = () => {
    return (
      <Fragment>
        {renderStep2()}
        <div className={'profile__step step3'}>
          <h3><span>{strings.profileEdit.step.personal.title}</span>{strings.profileEdit.step.personal.title2}</h3>
          <form>

            <div>
              <label className={'full'}>La tua data di nascita</label>
              <div className={'date-container'}>
                <input
                  placeholder={'GG'}
                  value={birthDate.day}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      if (!event.target.value) {
                        setBirthDate({ ...birthDate, day: '' })

                      } else if (!isNaN(Number(event.target.value))) {
                        const value = +event.target.value;

                        if (value < 1) {
                          setBirthDate({ ...birthDate, day: '1' })

                        } else if (value > 31) {
                          setBirthDate({ ...birthDate, day: '31' })

                        } else {
                          setBirthDate({ ...birthDate, day: event.target.value })
                        }
                      }
                    }
                  }}
                  onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                    event.target.select();
                  }} />

                <div className={'select-container'} style={{ width: '100%', margin: '0px 8px' }}>
                  <Select<SelectOption>
                    placeholder={'MESE'}
                    defaultValue={monthValue()}
                    value={monthValue()}
                    options={months}
                    onChange={(month: SelectOption) => setBirthDate({ ...birthDate, month: month.value })} />
                </div>

                <input type="text" placeholder={'AAAA'}
                  value={birthDate.year}
                  onChange={
                    (event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) {
                        if (!event.target.value) {
                          setBirthDate({ ...birthDate, year: '' })

                        } else if (!isNaN(Number(event.target.value))) {
                          setBirthDate({ ...birthDate, year: event.target.value })
                        }
                      }
                    }
                  }
                  onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                    event.target.select();
                  }}
                />
              </div>
            </div>

            <div>
              <Input
                label={strings.fieldLabelFiscalCodeSummary}
                placeholder={strings.fieldPlaceholderFiscalCode}
                value={tempUser!.fiscal_code}
                onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) dispatch(updateTempUser(FormFields.fiscal_code, event.target.value.toUpperCase()))
                  setIsLoading(false)
                }}
                error={_.get(error, FormFields.fiscal_code)}
              />
            </div>

          </form>
        </div>
        {personalInfo &&
          <div className={'profile__step step4'}>
            <h3><span>{strings.profileEdit.step.professional.title}</span>{strings.profileEdit.step.professional.title2}</h3>
            <form>
              <div>
                <Input
                  label={strings.profileEdit.step.professional.input.vat.label}
                  placeholder={strings.profileEdit.step.professional.input.vat.placeholder}
                  value={tempUser!.profile.vat}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateProfile(FormFields.vat, event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(error, FormFields.profile), FormFields.vat)}
                />
              </div>
              <div>
                <Input
                  label={strings.profileEdit.step.professional.input.pec.label}
                  placeholder={strings.profileEdit.step.professional.input.pec.placeholder}
                  value={tempUser!.profile.pec}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateProfile(FormFields.pec, event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(error, FormFields.profile), FormFields.pec)}
                />
              </div>
              <div>
                <Input
                  label={strings.profileEdit.step.professional.input.register_number.label}
                  placeholder={strings.profileEdit.step.professional.input.register_number.placeholder}
                  value={tempUser!.profile.register_number}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateProfile(FormFields.register_number, event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(error, FormFields.profile), FormFields.register_number)}
                />
              </div>
              <div>
                <InputAutosuggest
                  label={strings.profileEdit.step.professional.input.coa.label}
                  placeholder={strings.profileEdit.step.professional.input.coa.placeholder}
                  suggestions={officesResponse || []}
                  onChange={(v) => {
                    setCoaOffice({ query: v, object: undefined })
                    setIsLoading(false)
                  }}
                  onSelect={(data) => setCoaOffice({ query: data.name, object: data.value })}
                  value={coaOffice.query}
                  onFetch={officesRequest}
                  error={_.get(_.get(error, FormFields.profile), FormFields.coa_office)}
                />
              </div>
            </form>
          </div>
        }
      </Fragment>
    )
  }

  let renderStep4 = () => {
    return (
      <Fragment>
        {renderStep3()}
      </Fragment>
    )
  }

  let renderStep5 = () => {
    return (
      <Fragment>
        {renderStep4()}
        <div className={'profile__step step5'}>
          <h3><span>{strings.profileEdit.step.lawfirm.title}</span>{strings.profileEdit.step.lawfirm.title2}</h3>
          <form>
            <div>
              <Input
                label={strings.profileEdit.step.lawfirm.input.name.label}
                placeholder={strings.profileEdit.step.lawfirm.input.name.placeholder}
                value={tempUser!.profile.lawfirm.name}
                onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) updateLegalOffice(FormFields.name, event.target.value)
                  setIsLoading(false)
                }}
                error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.name)}
              />
            </div>
            <div>
              <Input
                label={strings.profileEdit.step.lawfirm.input.address.label}
                placeholder={strings.profileEdit.step.lawfirm.input.address.placeholder}
                value={tempUser!.profile.lawfirm.address}
                onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) updateLegalOffice(FormFields.address, event.target.value)
                  setIsLoading(false)
                }}
                error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.address)}
              />
            </div>
            <div>
              <InputAutosuggest
                label={strings.profileEdit.step.lawfirm.input.city.label}
                placeholder={strings.profileEdit.step.lawfirm.input.city.placeholder}
                suggestions={citiesResponse || []}
                onChange={(v) => {
                  setLawfirmCity({ query: v, object: undefined })
                  setIsLoading(false)
                }}
                onSelect={(data) => setLawfirmCity({ query: data.name, object: data.value })}
                value={lawfirmCity.query}
                onFetch={citiesRequest}
                error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.city)} />
            </div>
            <div>
              <Input
                label={strings.profileEdit.step.lawfirm.input.cap.label}
                placeholder={strings.profileEdit.step.lawfirm.input.cap.placeholder}
                value={tempUser!.profile.lawfirm.zip}
                onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) updateLegalOffice(FormFields.zip, event.target.value)
                  setIsLoading(false)
                }}
                error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.zip)}
              />
            </div>
            <div>
              <Input
                label={strings.profileEdit.step.lawfirm.input.phone.label}
                placeholder={strings.profileEdit.step.lawfirm.input.phone.placeholder}
                value={tempUser!.profile.lawfirm.phone}
                onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) updateLegalOffice(FormFields.phone, event.target.value)
                  setIsLoading(false)
                }}
                error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.phone)}
              />
            </div>
            <div>
              <Input
                label={strings.profileEdit.step.lawfirm.input.fax.label}
                placeholder={strings.profileEdit.step.lawfirm.input.fax.placeholder}
                value={tempUser!.profile.lawfirm.fax}
                onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) updateLegalOffice(FormFields.fax, event.target.value)
                  setIsLoading(false)
                }}
                error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.fax)}
              />
            </div>
          </form>
        </div>
      </Fragment>
    )
  }

  let onCertified = () => {
    let userID = new Buffer(String(currentUser.id!)).toString('base64')
    let createdAt = new Buffer(currentUser.created_at!).toString('base64')
    let url = Network.baseURL + ExternalLinks.PaymentStart + userID + '/' + createdAt + '/'
    window.open(url, "_blank")
  }

  let renderStep6 = () => {
    return (
      <Fragment>
        <div className={'card certify__container'}>
          <div className={'element'}>
            <div className={'element__img'}>
              <img src={gold} alt="collega" />
              <h3>{strings.profileSteps.step6.title}</h3>
            </div>
            <p>{strings.profileSteps.step6.subtitle}<b>{strings.profileSteps.step6.subtitle2}</b>{strings.profileSteps.step6.subtitle3}<b>{strings.profileSteps.step6.subtitle4}</b>, <b>{strings.profileSteps.step6.subtitle5}</b>{strings.profileSteps.step6.subtitle6}</p>

            <div className={'button__'}>
              <NavLink to={'/profile'} onClick={() => userRequest()}>
                <button className={'button-secondary'} >{strings.profileSteps.step6.ctaUndo}</button>
              </NavLink>
              <button className={'button-primary'} onClick={onCertified} >{strings.profileSteps.step6.cta}</button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  let renderSteps = () => {
    switch (step) {
      case 1: return renderStep1()
      case 2: return renderStep2()
      case 3: return renderStep3()
      case 4: return renderStep4()
      case 5: return renderStep5()
      case 6: return renderStep6()
    }
  }

  let onNext = async () => {
    if (step === UserProfileSteps.Fifth) {
      setIsLoading(true)
      updateRequest(tempUser!)
    } else if (step === UserProfileSteps.Third) {
      setPersonalInfo(true)
      setStep(step + 1)
    } else if (step === UserProfileSteps.Sixth) {

    } else {
      setStep(step + 1)
    }
  }

  let renderProgress = () => {
    return <progress value={step / UserProfileSteps.Sixth} />
  }

  let enabled = () => {
    if (!tempUser) return false
    let validFirst = tempUser && tempUser.profile // && tempUser.profile.picture
    let validSecond = tempUser!.profile.skills.length > 0
    let validThird = tempUser!.birth_date && tempUser!.fiscal_code
    let validForth = tempUser!.profile.vat && Utility.validateEmail(tempUser!.profile.pec) && tempUser!.profile.register_number && tempUser!.profile.coa_office
    let validFifth = tempUser!.profile.lawfirm.name && tempUser!.profile.lawfirm.address && tempUser!.profile.lawfirm.city && tempUser!.profile.lawfirm.zip && tempUser!.profile.lawfirm.phone && tempUser!.profile.lawfirm.fax

    switch (step) {
      case 1: return validFirst
      case 2: return validFirst && validSecond
      case 3: return validFirst && validSecond && validThird
      case 4: return validFirst && validSecond && validThird && validForth
      case 5: return validFirst && validSecond && validThird && validForth && validFifth
    }
  }

  return (
    <div className={'profile__edit__container'}>
      <h2>{step !== 6 ? strings.profileSteps.title : strings.profileSteps.complete}</h2>

      {renderProgress()}

      {tempUser && <div className={'__edit__container'}>
        <div className={'profile__steps'}>
          {renderSteps()}
        </div>

        {step !== 6 &&
          <div className={'button__next'}>
            <button className={error || !enabled() ? `button-primary disabled` : `button-primary`}
              style={isLoading ? { width: '158.83px' } : undefined}
              onClick={error || !enabled() ? undefined : onNext}>{isLoading && !error ? <span className={'button-loading'} /> : step === 5 ? strings.profileSteps.save : strings.profileSteps.next}</button>
          </div>
        }
      </div>}
    </div>
  );


};

export default ProfileSteps;
