export default class Office {
  id?: number;
  name?: string;

  constructor(office: string | Partial<Office>) {
    if (office instanceof String) {
      this.name = String(office);
    } else {
      Object.assign(this, office);
    }
  }
}
