import React from 'react';
import { MenuItem } from '../../store/types/MenuItem';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import greyArrow45 from 'image/icons/nav/arrow-grey-45.svg';

interface IMenuElement {
  item: MenuItem;
  target?: string;
  isDynamic?: boolean
}

const MenuElement: React.FC<IMenuElement> = ({ item, isDynamic, target }) => {

  if (target || item.target) {
    return (
      <a href={item.link} target={target || item.target} className={classNames(item.icon ? 'with-icon' : 'with-arrow')} onClick={() => item.analyticsEvent()}>
        {item.icon && <img src={item.icon} alt={'icon'}/>}
        <span dangerouslySetInnerHTML={{ __html: item.title }} />
        {(item.arrow || isDynamic) && <img src={isDynamic ? greyArrow45 : item.arrow} alt={'arrow'}/>}
      </a>
    )
  }

  return (
    <NavLink to={item.link} className={classNames(item.icon ? 'with-icon' : 'with-arrow')}>
      {item.icon && <img src={item.icon} alt={'icon'}/>}
      <span dangerouslySetInnerHTML={{ __html: item.title }} />
      {(item.arrow || isDynamic) && <img src={isDynamic ? greyArrow45 : item.arrow} alt={'arrow'}/>}
    </NavLink>
  );
};

export default MenuElement;
