import React from 'react';
import { Item } from 'store/types/Item';
import _ from 'lodash';
import strings from 'values/strings';
import Utility from 'utils/utils';

interface Props {
  obj: Item
  history?: any
}

let PackageItem: React.FC<Props> = React.memo(({ obj, history }) => {

  let fullCost = obj.package!.full_cost || 0
  let cost = obj.cost || 0
  let discount = ((fullCost - cost) / fullCost) * 100

  return (
    <div className={'package__card'}>

      <p className="title">{obj.name}</p>
      <p className="full_price">{Utility.currency(obj.package!.full_cost)}</p>
      <p className="price">{Utility.currency(obj.cost)}</p>
      <p className="discount">{`Risparmi il ${discount.toFixed(0)}%`}</p>

      <button className={'button-primary'} onClick={() => { history.push(`/package/${obj.id}`) }}>{strings.courses.showMore}</button>

    </div>
  );
});

export default PackageItem;

