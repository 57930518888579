import React from 'react';
import arrow from 'image/icons/nav/arrow-grey.svg';
import gold from '../../image/gold.svg';
import professional from '../../image/icons/activity/collega-gold.svg';
import { SubscriptionType } from 'values/values';

interface Props {
  label?: string,
  content?: any,
  hide?: any,
  terms?: string,
  onClick?: () => void
  hasIcon?: boolean
  iconType?: string
}

const ProfileItem: React.FC<Props> = ({ label, content, hide, terms, onClick, hasIcon, iconType }) => {
  const icon = () => {
    switch (iconType) {
      case SubscriptionType.GOLD: return gold
      case SubscriptionType.PROFESSIONAL: return professional
      default: break;
    }
  }
  return (
    <>
      {terms ?
        <a className={'terms__item'} href={terms} target={'_blank'} tabIndex={-1}>
          <span className={'content'}>{content.toString()}</span>
          <img src={arrow} alt={'arrow'} style={{ cursor: 'pointer' }} />
        </a>
        :
        <div className={'info__item'}>
          <div style={{ display: 'flex' }}>
            {hasIcon && <img src={icon()} alt={'collega icon'} style={{ width: 20, height: 20, marginRight: 8 }} />}
            <span className={'label'}>{label}</span>
          </div>
          <span className={'content'} onClick={onClick}>{content != undefined ? content.toString() : ''}{hide}</span>
        </div>
      }
    </>
  );



};

export default ProfileItem;

interface SProps {
  content?: string,
  checked?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ProfileSwitch: React.FC<SProps> = ({ content, checked, onChange }) => {
  return (
    <div className={'switch__item'}>
      <span className={'content'}>{content}</span>
      <label className="switch">
        <input type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <span className="slider" />
      </label>
    </div>
  );



};

export { ProfileSwitch };