import React, { useState, useEffect } from 'react';
import Checkboxes from './checkbox/Checkboxes';
import { User } from 'store/types/User';
import { useSelector } from 'react-redux';
import { IUserReducer } from 'store/reducers/UserReducer';
import { Skill } from 'store/types/Profile';
import Storage from '../../store/Storage';
import UserService from 'services/UserServices';
import { useHistory } from 'react-router-dom';
import strings from 'values/strings';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import _ from 'lodash';
import { ActivityModal } from 'store/types/ActivityModal';
import ConfirmModalSecondary from 'components/request-activities/modal/ConfirmModalSecondary';
import { SkillsType } from 'values/values';
import toast, { Toaster } from 'react-hot-toast';

type ProfileSkillsProps = {
  maxCheck?: number;
  type?: SkillsType
  allSkills?: Array<Skill>;
  isButtonDisabled?: boolean;
  onPress?: () => void
}

const ProfileSkills: React.FC<ProfileSkillsProps> = ({ maxCheck, type, allSkills, isButtonDisabled }) => {

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const [skills, setSkills] = useState<Array<Skill>>([]);
  const [otherSelectedSkills, setOtherSelectedSkills] = useState<Array<Skill>>([]);


  const [updateResponse, , updateRequest] = UserService.useUpdateUserMeAPI();
  const history = useHistory();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [showWarnings, setShowWarnings] = useState(false);

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.profileSkills);
  }, []);



  useEffect(() => {
    if (currentUser) {
      const filteredSkills = currentUser.profile.skills.filter(skill => skill.type == type);
      // const filteredOtherSkills = currentUser.profile.skills.filter(skill => type === SkillsType.BASIC ? !skill.is_main : skill.is_main);
      const filteredOtherSkills = currentUser.profile.skills

      // const _userTypedSkills = allSkills!.filter(s => currentUser.profile.skills.some(x => s.id === x.id && x.type == skillsType()))
      // const _filteredAllSkills = _userTypedSkills.concat(allSkills!.filter(s => !currentUser.profile.skills.some(x => s.id === x.id)))
      // setFilteredAllSkills(_filteredAllSkills ? _filteredAllSkills : [])

      setSkills(filteredSkills);
      setOtherSelectedSkills(filteredOtherSkills);
      setIsSaveButtonDisabled(filteredSkills.length <= 0);
    }
  }, [currentUser, type]);

  useEffect(() => {
    if (updateResponse) {
      toast.success("Competenze aggiornate!")
      history.replace('/profile');
    }
  }, [updateResponse, history]);

  const warningsPopup: ActivityModal = {
    title: 'Attenzione',
    copy: 'Sei sicuro di voler modificare queste competenze? Ricorda che non potrai più modificarle fino alla scadenza dell’abbonamento.',
    cta: 'Salva',
    ctaUndo: 'Annulla',

    onConfirm: (async () => {
      const user: User | undefined = _.cloneDeep(currentUser);
      const updatedSkills = [
        ...otherSelectedSkills,
        ...skills.map(skill => ({
          ...skill,
          is_main: isMain(),
          description: '',
          type: skillsType()
        }))
      ];
      _.set(user!.profile, 'skills', updatedSkills);
      updateRequest(user!);
      await Storage.set(Storage.SHOW_SKILLS_BUTTONS, false);
      setShowWarnings(false)
    }),
    onCancel: () => setShowWarnings(false)
  }

  const isMain = () => {
    if (type === SkillsType.BASIC) {
      return true
    } else if (type === SkillsType.SKILLS) {
      return false
    } else if (type === SkillsType.PROFESSIONAL) {
      return false
    }
  }

  const skillsType = () => {
    if (type === SkillsType.BASIC) {
      return 'BASIC'
    } else if (type === SkillsType.SKILLS) {
      return 'ADDITIONAL_SKILLS'
    } else if (type === SkillsType.PROFESSIONAL) {
      return 'PROFESSIONAL'
    }
  }

  const onSave = async (skills: Array<Skill>) => {
    const user: User = _.cloneDeep(currentUser!)

    _.set(user.profile, 'skills', skills);
    updateRequest(user);
    await Storage.set(Storage.SHOW_SKILLS_BUTTONS, false);
  };

  return (
    <div className={'profile__edit__container'}>
      <div>
        <h2>{type === 'basic' ? strings.profileSkills.basicTitle : strings.profileSkills.specificTitle}</h2>
        <h3 style={{ fontFamily: 'Helvetica' }}>{type === 'basic' ? '' : `Puoi indicarne massimo ${maxCheck}`}</h3>
      </div>
      <Checkboxes
        skills={skills} // Competenze selezionate nel tipo corrente
        onSave={(skills) => { onSave(skills) }}
        max={maxCheck}
        type={type} // Tipo corrente
        otherSelectedSkills={otherSelectedSkills} // Competenze già selezionate per altri tipi.
        allSkills={allSkills} // Tutte le competenze escluse quelle appartenenti ad altri tipi.
      />

      {showWarnings && <ConfirmModalSecondary content={warningsPopup} />}
    </div>

  );
}

export default ProfileSkills;
