import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import AppReducer from './AppReducer';
import RecordsReducer from './RecordReducer';
import AgendaReducer from './AgendaReducer';
import { notificationReducer } from './NotificationsReducer';
import { requestReducer } from './RequestReducer';
import { activitiesReducer } from './ActivitiesReducer';
import { sidePanelReducer } from './SidePanelReducer';
import SubscriptionReducer from './SubscriptionReducer';


const reducers = combineReducers({
  user: UserReducer,
  subscription: SubscriptionReducer,
  app: AppReducer,
  record: RecordsReducer,
  agenda: AgendaReducer,
  notificationsReducer: notificationReducer,
  requestReducer: requestReducer,
  activitiesReducer: activitiesReducer,
  sidePanelReducer: sidePanelReducer,

});

export default reducers;
