import React, { FC } from 'react'

type CtaContainerProps = {
    buttonText: string, 
    onClick: () => void, 
    secondaryButton?: { text: string, onClick: () => void } 
}

const CtaContainer: FC<CtaContainerProps> = ({ buttonText, onClick, secondaryButton }) => {
  return (
    <div className="cta-container">
        <button className="btn-primary" onClick={onClick}>
            <p>{buttonText}</p>
        </button>
        {secondaryButton && (
            <button className="btn-secondary" onClick={secondaryButton.onClick}>
              <p>{secondaryButton.text}</p>
            </button>
        )}
    </div>
  )
}

export default CtaContainer