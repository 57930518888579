import React, { useEffect } from 'react';
import 'moment/locale/it'
import hammer from 'image/icons/activity/activity-hammer.svg';
import paper from 'image/icons/activity/activity-paper.svg';
import domiciled from 'image/icons/activity/activity-domiciled.svg';
import arrow from 'image/icons/green-arrow-right.svg';
import border from 'image/icons/activity/activity-border.svg';
import { Demand, Candidate } from 'store/types/Demand';
import { ActivitiesContext } from './ActivityRequest';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { ActivitiesReducer, setCurrentDemand, setCurrentCandidateUser } from 'store/reducers/ActivitiesReducer';
import { useDispatch } from 'react-redux';
import ActivityStatuses from './ActivityStatuses';
import { setCurrentCandidate, setShouldPerformRequest } from '../../store/reducers/ActivitiesReducer';
import ActivitiesService from 'services/ActivitiesService';
import { User } from 'store/types/User';
import Utility from 'utils/utils';
import AnalyticsService, { AnalyticsEvents } from 'services/AnalyticsService';

interface Props {
  demand: Demand,
  candidate?: Candidate
}


let ActivityCard: React.FC<Props> = ({ demand, candidate }) => {

  let context = useSelector((state: ActivitiesReducer) => state.activitiesReducer.activitiesContext)
  let currentActivity = useSelector((state: ActivitiesReducer) => state.activitiesReducer.currentDemand)
  let dispatch = useDispatch()

  let [deleteDemandsResponse, , deleteDemandsRequest] = ActivitiesService.useDeleteDemandAPI()
  let [deleteCandidatesResponse, , deleteCandidatesRequest] = ActivitiesService.useDeleteCandidateAPI()
  let [deleteAssignedDemandsResponse, , deleteAssignedDemandsRequest] = ActivitiesService.useDeleteAssignedDemandAPI()




  let dateTime = () => {
    switch (context) {
      case ActivitiesContext.demands: return moment(demand.created_at).calendar()
      case ActivitiesContext.candidates: return moment(demand.created_at).calendar() + ' da ' + User.fullNameMasked(demand.user!)
      case ActivitiesContext.managements: return moment(demand.created_at).calendar() + ' da ' + User.fullName(demand.user!)
    }
  }

  let validator = () => {
    switch (context) {
      case ActivitiesContext.demands: return demand !== undefined
      case ActivitiesContext.candidates: return demand !== undefined && demand !== undefined
      case ActivitiesContext.managements: return demand !== undefined
    }
  }


  let cardImg = () => {
    switch (demand.type) {
      case 'CHANCELLERY': return paper
      case 'DOMICILIARY': return domiciled
      case 'SUBSTITUTE': return hammer
    }
  }

  let cardBackground = () => {
    switch (context) {
      case ActivitiesContext.demands: return 'activity__card__container request'
      case ActivitiesContext.candidates: return 'activity__card__container available'
      case ActivitiesContext.managements: return 'activity__card__container assigned'
    }
  }

  let deleteRequest = (demand?: Demand, candidate?: Candidate) => {
    switch (context) {
      case ActivitiesContext.demands: return deleteDemandsRequest(demand!)
      case ActivitiesContext.candidates: return deleteCandidatesRequest(candidate!)
      case ActivitiesContext.managements: return deleteAssignedDemandsRequest(demand!)
    }
  }

  let deleteResponse = () => {
    switch (context) {
      case ActivitiesContext.demands: return deleteDemandsResponse
      case ActivitiesContext.candidates: return deleteCandidatesResponse
      case ActivitiesContext.managements: return deleteAssignedDemandsResponse
    }
  }

  useEffect(() => {
    if (deleteResponse()) dispatch(setShouldPerformRequest(true))
  }, [deleteResponse()])


  return (
    <div className={currentActivity && demand && currentActivity.id === demand.id ? `${cardBackground()} ${'selected'}` : cardBackground()} onClick={() => {
      AnalyticsService.event(AnalyticsEvents.activityOpen, { activity_id: context === ActivitiesContext.candidates ? candidate!.id : demand!.id })

      dispatch(setCurrentCandidate(candidate))
      dispatch(setCurrentCandidateUser(undefined))
      dispatch(setCurrentDemand(demand))
    }} >

      {validator() && <div className={'item__container'} >
        <div className={context !== ActivitiesContext.demands ? 'img__container oval' : 'img__container'}>
          <img src={cardImg()} alt={''} />
        </div>

        <div className={'text__container'}>
          <span className={'date'}>{dateTime()}</span>
          <span className={'title'}>{Utility.localised(demand.type)}</span>
          <span className={'record'}>{Demand.at(demand)}</span>
          <span className={'event'}>{demand.office ? demand.office.name : ''}</span>
          <span className={'subtitle'}>{demand.activity}</span>

          <ActivityStatuses
            demand={demand}
            candidate={candidate} />

        </div>

        <div className='arrows'>
          <img src={arrow} alt={'arrow'} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      }

      <img className={'rightBorder'} src={border} alt={'border'} />
    </div>
  );
}

export default ActivityCard;
