import React, { FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUserReducer, updateTempUser } from '../../store/reducers/UserReducer';
import strings from '../../values/strings';
import Input from '../../components/common/Input';
import { FormFields } from '../../values/values';
import Utility from '../../utils/utils';
import { IStepFourth } from './interface/SignupInterface';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';

const StepFourth: React.FC<IStepFourth> = ({ phone, next }) => {

  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
  const dispatch = useDispatch();

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.register4)
  }, [])

  return (
    <form onSubmit={preventSubmit(next)}>
      <div className={'title_container'}>
        <h1 className={'title'}>{strings.signUp.headerTitle}</h1>
        <a className={'help'} href={'mailto:' + strings.navMenu.support_email.address + '?subject=' + strings.signUp.help_subject} target={'_blank'}>{strings.signUp.help}</a>
      </div>
      <Input
        label={strings.fieldLabelPhone}
        placeholder={strings.fieldPlaceholderPhone}
        value={tempUser!.phone}
        autoFocus={true}
        onChange={
          (event?: React.ChangeEvent<HTMLInputElement>) => {
            if (event) {
              dispatch(updateTempUser(FormFields.phone, event.target.value));
              if (Utility.validatePhone(event.target.value)) {
                phone.setValidity(true);
              }
            }
          }
        }
        onBlur={
          (event?: React.FocusEvent<HTMLInputElement>) => {
            if (event) {
              phone.setValidity(Utility.validatePhone(event.target.value));
            }
          }
        }
        error={!phone.valid ? strings.errors.invalidPhone : undefined}
      />
      <button type={'submit'} className={'button-placeholder-submit'} tabIndex={-1} />
    </form>
  );
};

export default StepFourth;
