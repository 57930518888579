import React, { useState, useEffect, useRef } from 'react';
import { User } from 'store/types/User';

import Input from 'components/common/Input';
import strings from 'values/strings';
import { IUserReducer, createTempUser } from 'store/reducers/UserReducer';
import { FormFields, RoleType, MonthType, DateFormat } from 'values/values';
import Utility from 'utils/utils';
import { ISignUpError } from 'page/signup/interface/SignupInterface';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Checkboxes from './checkbox/Checkboxes';
import ProfileImg from './ProfileImg';
import UserService from 'services/UserServices';
import InputAutosuggest from '../common/AutoSuggest'
import classNames from 'classnames';
import Select from '../../components/common/Select';
import SelectOption from '../../store/types/SelectionOption';
import { Skill } from 'store/types/Profile';
import EventsService from '../../services/EventService';
import moment from 'moment';
import { updateTempUser } from '../../store/reducers/UserReducer';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import TextArea from 'components/common/TextArea';
import toast from 'react-hot-toast';
var _ = require('lodash');

const options = [
  new SelectOption<RoleType>(RoleType.LAWYER, 'Avvocato'),
  new SelectOption<RoleType>(RoleType.ESTABLISHED, 'Avvocato stabilito'),
  new SelectOption<RoleType>(RoleType.PRACTICING, 'Praticante avvocato'),
  new SelectOption<RoleType>(RoleType.QUALIFIED, 'Praticante avvocato abilitato')
];

const months = [
  new SelectOption<MonthType>(1, 'Gennaio'),
  new SelectOption<MonthType>(2, 'Febbraio'),
  new SelectOption<MonthType>(3, 'Marzo'),
  new SelectOption<MonthType>(4, 'Aprile'),
  new SelectOption<MonthType>(5, 'Maggio'),
  new SelectOption<MonthType>(6, 'Giugno'),
  new SelectOption<MonthType>(7, 'Luglio'),
  new SelectOption<MonthType>(8, 'Agosto'),
  new SelectOption<MonthType>(9, 'Settembre'),
  new SelectOption<MonthType>(10, 'Ottobre'),
  new SelectOption<MonthType>(11, 'Novembre'),
  new SelectOption<MonthType>(12, 'Dicembre'),
];

interface Props {
  currentUser: User
}

const ProfileEdit: React.FC<Props> = ({ currentUser }) => {

  let passwordRef = useRef<any>()

  const [officesResponse, , officesRequest] = EventsService.useSearchOfficesAPI(true);
  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
  let [updateResponse, updateError, updateRequest] = UserService.useUpdateUserMeAPI()
  const [pwdUpdateResponse, pwdUpdateError, pwdUpdateRequest] = UserService.useChangePasswordAPI();

  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);

  const [isLoading, setIsLoading] = useState(false);

  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEMail, setIsValidEmail] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(undefined);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [unHidePassword, setUnHidePassword] = useState(false);
  const [isValidFiscalCode, setIsValidFiscalCode] = useState(true);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [error, setError] = useState();

  // const initError: ISignUpError = {};
  // const [serverError, setServerError] = useState({ initError });


  const [birthDate, setBirthDate] = useState<any>({
    day: undefined,
    month: undefined,
    year: undefined
  });

  const [coaOffice, setCoaOffice] = useState<any>({
    query: '',
    object: undefined
  });

  const [lawfirmCity, setLawfirmCity] = useState<any>({
    query: '',
    object: undefined
  })

  const [lawfirmCity2, setLawfirmCity2] = useState<any>({
    query: '',
    object: undefined
  })


  const dispatch = useDispatch();
  const history = useHistory();

  const unHide = () => {
    setUnHidePassword(!unHidePassword);
  };


  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.profileEdit)
  }, [])

  useEffect(() => {
    if (tempUser) {
      updateProfile(FormFields.coa_office, coaOffice.object)
    }
  }, [coaOffice])

  useEffect(() => {
    if (tempUser) {
      updateLegalOffice(FormFields.city, lawfirmCity.object)
    }
  }, [lawfirmCity])

  useEffect(() => {
    if (tempUser) {
      updateLegalOffice(FormFields.city2, lawfirmCity2.object)
    }
  }, [lawfirmCity2])


  useEffect(() => {
    if (_.isEmpty(birthDate.year) || birthDate.year.length < 4 || _.isUndefined(birthDate.month) || _.isEmpty(birthDate.day)) { dispatch(updateTempUser(FormFields.birth_date, null)); return }

    let date = birthDate.year + '-' + birthDate.month + '-' + birthDate.day
    let momentDate = moment(date, 'YYYY-MM-DD').format(DateFormat.server)
    dispatch(updateTempUser(FormFields.birth_date, momentDate))
  }, [birthDate])


  useEffect(() => {
    if (pwdUpdateResponse) {
      updateRequest(tempUser!)
    }
  }, [pwdUpdateResponse])

  useEffect(() => {
    setError({ ...updateError })
  }, [updateError])

  useEffect(() => {
    if (pwdUpdateError) {
      setPasswordError(pwdUpdateError.new_password2)
    }
  }, [pwdUpdateError])



  useEffect(() => {
    dispatch(createTempUser(currentUser))

    if (currentUser.profile.coa_office) setCoaOffice({ query: currentUser!.profile.coa_office!.name, object: currentUser!.profile.coa_office })
    setLawfirmCity({ query: currentUser!.profile.lawfirm.city!.display_name, object: currentUser!.profile.lawfirm.city })
    if (currentUser.profile.lawfirm.city2) setLawfirmCity2({ query: currentUser!.profile.lawfirm.city2!.display_name, object: currentUser!.profile.lawfirm.city2 })

    if (currentUser.birth_date) {
      let date = moment(currentUser.birth_date)
      setBirthDate({ day: date.get('D').toString(), month: date.get('M') + 1, year: date.year().toString() })
    }

  }, [currentUser])

  useEffect(() => {
    if (updateResponse) {
      toast.success("Dati del profilo aggiornati!") 
      history.goBack()
    }

  }, [updateResponse])

  useEffect(() => {
    setError(undefined)
  }, [tempUser])

  let updateProfile = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile,
      [field]: value
    }

    await dispatch(updateTempUser(FormFields.profile, data))
  }

  let updateLegalOffice = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile.lawfirm,
      [field]: value
    }

    await updateProfile(FormFields.lawfirm, data)
  }

  let typeValue = () => {
    if (!tempUser!.profile.type) return undefined
    return options.find((option) => option.value === tempUser!.profile.type)
  }

  let monthValue = () => {
    if (!birthDate.month) return undefined
    return months.find((months) => months.value === birthDate.month)
  }

  let onSave = async () => {
    setIsLoading(true)
    if (password.length > 0) {
      pwdUpdateRequest(password);
    } else {
      updateRequest(tempUser!)
    }
  }

  const dividerColor = () => {
    return User.isGold(currentUser) ? '#A17644' : User.isProfessional(currentUser) ? '#f6d560' : '#208a86';
  }

  // RENDER

  if (!tempUser) { return null }

  return (
    <div className={'profile__edit__container'}>
      <h2>{strings.profileEdit.title}</h2>
      <div className={'divider'} style={{ backgroundColor: dividerColor() }}></div>
      <div className={'__edit__container'}>
        <div className={'profile__steps'}>
          <div className={'profile__step'}>
            <h3>{strings.profileEdit.step.img}</h3>
            <ProfileImg
              image={currentUser.profile.picture}
              onPickedImage={(picture) => updateProfile(FormFields.picture, picture)} />
          </div>

          <div className={'profile__step'}>
            <h3><span>{strings.profileEdit.step.qualification.title}</span>{strings.profileEdit.step.qualification.title2}</h3>
            <form>
              <div className={'select-container'}>
                <Select<SelectOption>
                  label={strings.fieldLabelFirstRoleName}
                  placeholder={strings.profileEdit.step.qualification.placeholder}
                  value={typeValue()}
                  defaultValue={typeValue()}
                  options={options}
                  onChange={(option: SelectOption) => updateProfile(FormFields.type, option.value)} />
              </div>
            </form>
          </div>
          {/* <div className={'profile__step'}>
            <h3><span>{strings.profileEdit.step.skills.title}</span>{strings.profileEdit.step.skills.title2}  {!tempUser.is_certified && "(max 2)"}</h3>
            <Checkboxes
              skills={tempUser.profile.skills}
              max={tempUser.is_certified ? undefined : 2}
              onChangeSelection={(skills: Array<Skill>) => {
                updateProfile(FormFields.skills, skills)
              }} />

          </div> */}
          <div className={'profile__step'}>
            <h3><span>{strings.profileEdit.step.personal.title}</span>{strings.profileEdit.step.personal.title2}</h3>
            <form>
              <div>
                <Input
                  label={strings.fieldLabelFirstNameSummary}
                  placeholder={strings.fieldPlaceholderFirstName}
                  value={tempUser!.first_name}
                  onChange={
                    (event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) dispatch(updateTempUser(FormFields.first_name, _.isEmpty(event.target.value) ? null : event.target.value))
                      setIsLoading(false)
                    }}
                  error={_.get(error, FormFields.first_name)}
                />
              </div>
              <div>
                <Input
                  label={strings.fieldLabelLastNameSummary}
                  placeholder={strings.fieldPlaceholderLastName}
                  value={tempUser!.last_name}
                  onChange={
                    (event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) dispatch(updateTempUser(FormFields.last_name, _.isEmpty(event.target.value) ? null : event.target.value))
                      setIsLoading(false)
                    }}
                  error={_.get(error, FormFields.last_name)}
                />
              </div>

              <div>
                <Input
                  label={strings.fieldLabelEmailSummary}
                  placeholder={strings.fieldPlaceholderEmail}
                  value={tempUser!.email}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) dispatch(updateTempUser(FormFields.email, _.isEmpty(event.target.value) ? null : event.target.value))
                    setIsLoading(false)
                  }}
                  error={_.get(error, FormFields.email)}
                />
              </div>

              <div className={'password-container'} ref={passwordRef}>
                <span className={classNames('unhide', { hide: unHidePassword })} onClick={unHide} />
                <Input
                  label={strings.fieldLabelPasswordSummary}
                  type={(!unHidePassword ? 'password' : 'text')}
                  placeholder={strings.fieldPlaceholderPassword}
                  value={password}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    setPasswordError(undefined)
                    if (event) setPassword(event.target.value)
                    setIsLoading(false)
                  }}
                  // onBlur={
                  //   (event?: React.FocusEvent<HTMLInputElement>) => {
                  //     if (event) {
                  //       if (event.target.value.length > 0) {
                  //         setIsValidPassword(Utility.validatePassword(event.target.value));
                  //       } else {
                  //         setIsValidPassword(true);
                  //       }
                  //     }
                  //   }
                  // }
                  error={passwordError}
                />
              </div>

              <div>
                <label className={'full'}>{strings.profileEdit.step.personal.birth}</label>
                <div className={'date-container'}>
                  <input
                    placeholder={strings.profileEdit.step.personal.day}
                    value={birthDate.day}
                    onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) {
                        if (!event.target.value) {
                          setBirthDate({ ...birthDate, day: '' })

                        } else if (!isNaN(Number(event.target.value))) {
                          const value = +event.target.value;

                          if (value < 1) {
                            setBirthDate({ ...birthDate, day: '1' })

                          } else if (value > 31) {
                            setBirthDate({ ...birthDate, day: '31' })

                          } else {
                            setBirthDate({ ...birthDate, day: event.target.value })
                          }
                        }
                      }
                    }}
                    onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                      event.target.select();
                    }} />

                  <div className={'select-container'} style={{ width: '100%', margin: '0px 8px' }}>
                    <Select<SelectOption>
                      placeholder={strings.profileEdit.step.personal.month}
                      defaultValue={monthValue()}
                      value={monthValue()}
                      options={months}
                      onChange={(month: SelectOption) => setBirthDate({ ...birthDate, month: month.value })} />
                  </div>

                  <input type="text" placeholder={strings.profileEdit.step.personal.year}
                    value={birthDate.year}
                    onChange={
                      (event?: React.ChangeEvent<HTMLInputElement>) => {
                        if (event) {
                          if (!event.target.value) {
                            setBirthDate({ ...birthDate, year: '' })

                          } else if (!isNaN(Number(event.target.value))) {
                            setBirthDate({ ...birthDate, year: event.target.value })
                          }
                        }
                      }
                    }
                    onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                      event.target.select();
                    }}
                  />
                </div>
              </div>

              <div>
                <Input
                  label={strings.fieldLabelFiscalCodeSummary}
                  placeholder={strings.fieldPlaceholderFiscalCode}
                  value={tempUser.fiscal_code}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) dispatch(updateTempUser(FormFields.fiscal_code, _.isEmpty(event.target.value) ? null : event.target.value.toUpperCase()))
                    setIsLoading(false)
                  }}
                  error={_.get(error, FormFields.fiscal_code)}
                />
              </div>

              <div>
                <Input
                  label={strings.fieldLabelPhoneSummary}
                  placeholder={strings.fieldPlaceholderPhone}
                  value={tempUser!.phone}
                  onChange={
                    (event?: React.ChangeEvent<HTMLInputElement>) => {
                      if (event) dispatch(updateTempUser(FormFields.phone, _.isEmpty(event.target.value) ? null : event.target.value))
                      setIsLoading(false)
                    }}
                  error={_.get(error, FormFields.phone)}
                />
              </div>
            </form>
          </div>
          <div className={'profile__step'}>
            <h3><span>{strings.profileEdit.step.professional.title}</span>{strings.profileEdit.step.professional.title2}</h3>
            <form>
              <div>
                <Input
                  disabled={true}
                  label={`${strings.profileEdit.step.professional.place}*`}
                  value={tempUser.profile.lawfirm.city!.display_name}
                />
              </div>

              {User.isProfessional(currentUser) && <div>
                <InputAutosuggest
                  hasIcon={true}
                  label={strings.profileEdit.step.lawfirm.input.city2.label}
                  placeholder={strings.profileEdit.step.lawfirm.input.city2.placeholder}
                  suggestions={citiesResponse || []}
                  onChange={(v) => {
                    setLawfirmCity2({ query: v, object: undefined })
                    setIsLoading(false)
                  }}
                  onSelect={(data) => setLawfirmCity2({ query: data.name, object: data.value })}
                  value={lawfirmCity2.query}
                  onFetch={citiesRequest}
                  error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.city2)} />
              </div>}

              <div>
                <Input
                  label={strings.profileEdit.step.professional.input.vat.label}
                  placeholder={strings.profileEdit.step.professional.input.vat.placeholder}
                  value={tempUser.profile.vat}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateProfile(FormFields.vat, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(error, FormFields.profile), FormFields.vat)}
                />
              </div>
              <div>
                <Input
                  label={strings.profileEdit.step.professional.input.pec.label}
                  placeholder={strings.profileEdit.step.professional.input.pec.placeholder}
                  value={tempUser.profile.pec}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateProfile(FormFields.pec, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(error, FormFields.profile), FormFields.pec)}
                />
              </div>
              <div>
                <Input
                  label={strings.profileEdit.step.professional.input.register_number.label}
                  placeholder={strings.profileEdit.step.professional.input.register_number.placeholder}
                  value={tempUser.profile.register_number}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateProfile(FormFields.register_number, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(error, FormFields.profile), FormFields.register_number)}
                />
              </div>
              <div>
                <InputAutosuggest
                  label={strings.profileEdit.step.professional.input.coa.label}
                  placeholder={strings.profileEdit.step.professional.input.coa.placeholder}
                  suggestions={officesResponse || []}
                  onChange={(v) => setCoaOffice({ query: v, object: undefined })}
                  onSelect={(data) => {
                    setCoaOffice({ query: data.name, object: data.value })
                    setIsLoading(false)
                  }}
                  value={coaOffice.query}
                  onFetch={officesRequest}
                  error={_.get(_.get(error, FormFields.profile), FormFields.coa_office)}
                />
              </div>
            </form>
          </div>
          <div className={'profile__step'}>
            <h3><span>{strings.profileEdit.step.lawfirm.title}</span>{strings.profileEdit.step.lawfirm.title2}</h3>

            <form>
              <div>
                <Input
                  label={strings.profileEdit.step.lawfirm.input.name.label}
                  placeholder={strings.profileEdit.step.lawfirm.input.name.placeholder}
                  value={tempUser.profile.lawfirm.name}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateLegalOffice(FormFields.name, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.name)}
                />
              </div>


              {/* Description */}
              {User.isGold(currentUser) && <div>
                {/* <Input
                 
                  hasIcon={true}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateLegalOffice(FormFields.description, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}

                  error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.description)}
                  label={strings.profileEdit.step.lawfirm.input.description.label}
                  placeholder={strings.profileEdit.step.lawfirm.input.description.placeholder}
                  value={tempUser.profile.lawfirm.description}
                /> */}
                <TextArea
                  hasIcon={true}
                  label={strings.profileEdit.step.lawfirm.input.description.label}
                  placeholder={strings.profileEdit.step.lawfirm.input.description.placeholder}
                  value={tempUser.profile.lawfirm.description}
                  onChange={(event?: React.ChangeEvent<HTMLTextAreaElement>) => {
                    if (event) updateLegalOffice(FormFields.description, _.isEmpty(event.target.value) ? null : event.target.value)
                      setIsLoading(false)
                  }}
                  error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.description)}
                />
              </div>}
             
              {/* Lawyer */}
              <div>
                <Input
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateLegalOffice(FormFields.lawyer, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.lawyer)}
                  label={strings.profileEdit.step.lawfirm.input.lawyer.label}
                  placeholder={strings.profileEdit.step.lawfirm.input.lawyer.placeholder}
                  value={tempUser.profile.lawfirm.lawyer}
                />
              </div>

              <div>
                <Input
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateLegalOffice(FormFields.address, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.address)}
                  label={strings.profileEdit.step.lawfirm.input.address.label}
                  placeholder={strings.profileEdit.step.lawfirm.input.address.placeholder}
                  value={tempUser.profile.lawfirm.address}
                />
              </div>

              <div>
                <Input
                  disabled={true}
                  label={`${strings.profileEdit.step.lawfirm.input.city.label}*`}
                  placeholder={strings.profileEdit.step.lawfirm.input.city.placeholder}
                  value={tempUser.profile.lawfirm.city!.display_name}
                />
              </div>
              <div>
                <Input
                  // disabled={true}
                  label={`${strings.profileEdit.step.lawfirm.input.cap.label}*`}
                  placeholder={strings.profileEdit.step.lawfirm.input.cap.placeholder}
                  value={tempUser.profile.lawfirm.zip}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateLegalOffice(FormFields.zip, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                />
              </div>
              <div>
                <Input
                  label={strings.profileEdit.step.lawfirm.input.phone.label}
                  placeholder={strings.profileEdit.step.lawfirm.input.phone.placeholder}
                  value={tempUser.profile.lawfirm.phone}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateLegalOffice(FormFields.phone, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                  // onBlur={(event?: React.FocusEvent<HTMLInputElement>) => {
                  //   if (event) setError(!Utility.validatePhone(tempUser!.profile.lawfirm.phone) ? _.set({ profile: { lawfirm: { phone: "Numero di telefono non valido" } } }) : undefined)
                  // }}
                  error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.phone)}
                />
              </div>
              <div>
                <Input
                  label={strings.profileEdit.step.lawfirm.input.fax.label}
                  placeholder={strings.profileEdit.step.lawfirm.input.fax.placeholder}
                  value={tempUser.profile.lawfirm.fax}
                  onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) updateLegalOffice(FormFields.fax, _.isEmpty(event.target.value) ? null : event.target.value)
                    setIsLoading(false)
                  }}
                  error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.fax)}
                />
              </div>
            </form>

          </div>
          <p className={'disclaimer'}>{strings.profileEdit.step.lawfirm.disclaimer}</p>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
            <NavLink to={'/profile'}>
              <button className={'button-secondary'} >{strings.profileEdit.ctaUndo}</button>
            </NavLink>

            <button
              className={'button-primary'}
              style={{ width: '160px' }}
              disabled={error}
              onClick={onSave}>{isLoading && !error ? <span className={'button-loading'} /> : strings.profileEdit.cta}</button>
          </div>

        </div>
      </div>
    </div >
  );
};

export default ProfileEdit;
