import Modal from 'components/common/Modal'
import React, { FC, useState, useRef, useEffect } from 'react'
import Dots from './components/Dots'
import CtaContainer from './components/CtaContainer'

export type Step = {
  title: string
  description: string
  newUserDescription?: string
  image: string
  buttonText: string
  onClick?: () => void
  secondaryButton?: {
    text: string
    onClick: () => void
  }
}

interface Props {
  isNewUser?: boolean
  steps: Step[]
  onModalSeen?: () => void
  handleStepChange?: (nextStep: number) => void
}


const UpdatesModal: FC<Props> = ({ isNewUser, steps }) => {
  const [currentStep, setCurrentStep] = useState<number>(isNewUser ? steps.length - 1 : 0)
  const modalRef = useRef<HTMLDivElement>(null)


  const handleStepChange = (nextStep: number) => {
    setCurrentStep(nextStep)
  }


  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    };
  }, []);


  const handleClick = (clickAction: () => void) => {
    if (currentStep === steps.length - 1) {
      clickAction()
    } else {
      handleStepChange(currentStep + 1)
    }
  }

  const { title, description, image, buttonText, onClick, secondaryButton } = steps[currentStep]

  return (
    <Modal >
      <div
        className={`updates-modal-container`}
        ref={modalRef}>
        <img src={image} draggable={false} alt="update step" />
        <div className="text-container">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>

        {steps.length > 1 && !isNewUser && <Dots currentStep={currentStep} steps={steps} handleStepChange={handleStepChange} />}

        <CtaContainer
          buttonText={buttonText}
          onClick={() => handleClick(onClick!)}
          secondaryButton={secondaryButton && {
            text: secondaryButton.text,
            onClick: () => handleClick(secondaryButton.onClick)
          }}
        />
      </div>
    </Modal>
  )
}

export default UpdatesModal
