import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import MenuService from '../services/MenuServices';
import CommitmentWrapper from '../components/commitment/CommitmentWrapper';
import AgendaDetail from '../components/commitment/CommitmentRightDetail';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IAppReducer } from '../store/reducers/AppReducer';
import { IAgendaReducer } from '../store/reducers/AgendaReducer';
import RecordWrapper from '../components/record/RecordWrapper';
import RecordModal from '../components/record/RecordModal';
import { IRecordsReducer } from '../store/reducers/RecordReducer';
import RecordActionSection from '../components/record/RecordActionSection';
import { IUserReducer } from '../store/reducers/UserReducer';
import GlobalErrorModal from '../components/GlobalErrorModal';
import CommitmentDeleteModal from '../components/commitment/CommitmentDeleteModal';
import NavMenu from '../components/menu/NavMenu';
import Dashboard from 'components/dashboard/Dashboard';
import RequestActivities from 'components/request-activities/RequestActivities';
import ActivityRequest, { ActivitiesContext } from 'components/activity-request/ActivityRequest';
import News from 'components/news/News';
import UserProfile from 'components/profile/UserProfile';
import ProfileSteps from 'components/profile/ProfileSteps';
import ProfileEdit from 'components/profile/ProfileEdit';
import ProfileSkills from 'components/profile/ProfileSkills';
import ProfileLocations from 'components/profile/ProfileLocations';
import Empty from 'components/Empty/Empty';
import Header from 'components/header/Header';
import Courses from 'components/courses/Courses';
import Purchased from 'components/courses/Purchased';
import RequestActivitiesGuest from 'components/request-activities/RequestActivitiesGuest';

interface RouterProps {
  id: string,
  type?: string
}

interface Props extends RouteComponentProps<RouterProps> { }

const AppWrapper: React.FC<Props> = ({ match, history }) => {
  const [, , menuRequest] = MenuService.useMenuAPI();

  const errorGlobal = useSelector((state: IAppReducer) => state.app.error500);
  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const inModalCreation = useSelector((state: IRecordsReducer) => state.record.inCreation);
  const inModalDelete = useSelector((state: IAgendaReducer) => state.agenda.modalDeleteEvent);

  useLayoutEffect(() => {
    menuRequest()
  }, []);


  // RENDER
  return (
    <Fragment>
      <Header />

      <Switch>
        <Route path={'*'}>
          <div className={'agenda'}>

            <NavMenu />

            <Switch>
              <Route path="/empty">
                <Empty />
              </Route>

              <Route path="/request-activities">
                {currentUser !== undefined ?
                  <RequestActivities currentUser={currentUser}/>
                  :
                  <RequestActivitiesGuest type={match.params.type}/>
                }
              </Route>

              <Route path="/dashboard">
                <Dashboard currentUser={currentUser}/>
              </Route>

              <Route path="/activity-request">
                <ActivityRequest context={ActivitiesContext.demands} />
              </Route>

              <Route path="/activity-proposal">
                <ActivityRequest context={ActivitiesContext.candidates} />
              </Route>

              <Route path="/activity-assignments">
                <ActivityRequest context={ActivitiesContext.managements} />
              </Route>

              <Route path="/agenda">
                <CommitmentWrapper />
                <AgendaDetail />
              </Route>

              <Route path="/records">
                <RecordWrapper />
                <RecordActionSection />
              </Route>

              <Route path="/news">
                <News />
              </Route>

              <Route path="/courses">
                <Courses history={history} id={match.params.id} />
              </Route>

              <Route path="/package">
                <Courses history={history} id={match.params.id} />
              </Route>

              <Route path="/purchased">
                <Purchased history={history} />
              </Route>

              {currentUser && <>
                <Route path="/profile">
                  <UserProfile currentUser={currentUser} />
                </Route>
                <Route path="/profile-complete">
                  {!currentUser.profile.is_complete && <ProfileSteps currentUser={currentUser} />}
                  {currentUser.profile.is_complete && <UserProfile currentUser={currentUser} />}
                </Route>
                <Route path="/profile-edit">
                  <ProfileEdit currentUser={currentUser} />
                </Route>
                <Route path="/profile-skills">
                  <ProfileSkills />
                </Route>
                <Route path="/profile-locations">
                  <ProfileLocations currentUser={currentUser} />
                </Route>
              </>}

            </Switch>

          </div>
          {inModalCreation && <RecordModal />}
        </Route>
      </Switch>
      {errorGlobal && <GlobalErrorModal />}
      {inModalDelete && <CommitmentDeleteModal />}
    </Fragment>
  );
};

export default AppWrapper;
