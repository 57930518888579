import Event from '../types/Event';
import { ResourceType } from '../../values/values';

const SET_FILTERS = 'SET_FILTERS';
const SET_SEARCH_VISIBILITY = 'SET_SEARCH_VISIBILITY';
const TOGGLE_IS_VISIBLE_FILTERS = 'TOGGLE_IS_VISIBLE_FILTERS';
const SET_CURRENT_DAY = 'SET_CURRENT_DAY';
const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT';

const SET_EVENTS = 'SET_EVENTS';
const RESET_EVENTS = 'RESET_EVENTS';
const ADD_EVENT = 'ADD_EVENT';
const UPDATE_EVENT = 'UPDATE_EVENT';
const DELETE_EVENT = 'DELETE_EVENT';

const CHECK_IS_VISIBLE_FIRST = 'CHECK_IS_VISIBLE_FIRST';
const SET_VISIBILITY_ON_DAY = 'SET_VISIBILITY_ON_DAY';
const SET_CHANGE_DAY_TYPE = 'SET_CHANGE_DAY_TYPE';

const SET_SHOW_DELETE_MODAL = 'SET_SHOW_DELETE_MODAL';

const SET_SEARCHED_EVENTS = 'SET_SEARCHED_EVENTS';
const SET_SEACH_PAGE = 'SET_SEACH_PAGE';
const SET_END_REACHED = 'SET_END_REACHED';
const RESET_SEARCHED_EVENTS = 'RESET_SEARCHED_EVENTS';

export enum UpdateDaysType {
  FirstTime, Calendar, Arrow, SentinelTop, SentinelDown
}

export interface IAgendaReducer {
  agenda: IAgendaReducerState;
}

interface IAgendaDay {
  [key: string]: Event[];
}

export interface IAgendaReducerState {
  isVisibleFilters: boolean;
  isVisibleSearch: boolean;
  filters?: { [key: string]: boolean };
  events: IAgendaDay;
  isVisibleDay: { [key: string]: boolean };
  event?: Event;
  day?: string;
  onTopDay: string;
  updateType: UpdateDaysType;
  searchedEvents?: Event[];
  modalDeleteEvent: boolean;
  searchPage: number
  endReached: boolean
}

const INITIAL_STATE: IAgendaReducerState = {
  isVisibleFilters: false,
  isVisibleSearch: false,
  events: {},
  isVisibleDay: {},
  onTopDay: '',
  updateType: UpdateDaysType.FirstTime,
  modalDeleteEvent: false,
  searchPage: 1,
  endReached: false

};

let checkFirstTimeout: NodeJS.Timeout | null = null;

// ACTIONS
export function showDeleteModal(youWantDelete: boolean) {
  return async (dispatch: any) => {
    return dispatch({ type: SET_SHOW_DELETE_MODAL, youWantDelete });
  };
}

export function setSearchVisibility(isVisible: boolean) {
  return {
    type: SET_SEARCH_VISIBILITY,
    isVisible
  };
}

export function setSearchedEvents(events?: Event[], reset?: boolean) {
  return async (dispatch: any) => {

    if (reset) {
      await dispatch({
        type: RESET_SEARCHED_EVENTS
      });

      await dispatch({
        type: SET_SEACH_PAGE,
        page: 1
      });
    }

    await dispatch({
      type: SET_SEARCHED_EVENTS,
      events: events,
      reset: reset
    });
  };
}

export function setSearchPage(page: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_SEACH_PAGE,
      page: page
    });
  };
}

export function setEndReached(endReached: boolean) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_END_REACHED,
      endReached: endReached
    });
  };
}

export function setFilters(filters?: { [key: string]: boolean }) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_FILTERS,
      filters
    });
  };
}

export function selectDayOnCalendar(day: string) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_CHANGE_DAY_TYPE,
      updateType: UpdateDaysType.Calendar
    });
    await dispatch(setCurrentDay(day));
    dispatch(setSearchedEvents());
    dispatch(setSearchVisibility(false));
  };
}

export function setCurrentDay(day?: string) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_CURRENT_DAY,
      day
    });
  };
}

export function toggleIsVisibleFilters() {
  return async (dispatch: any) => {
    await dispatch({
      type: TOGGLE_IS_VISIBLE_FILTERS
    });
  };
}

export function checkIsVisibleFirst() {
  return async (dispatch: any) => {
    await dispatch({
      type: CHECK_IS_VISIBLE_FIRST
    });
  };
}

export function setVisibilityOnDay(day: string, visibility: boolean) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_VISIBILITY_ON_DAY,
      day,
      visibility
    });
    if (checkFirstTimeout != null) {
      clearTimeout(checkFirstTimeout);
      checkFirstTimeout = null;
    }
    checkFirstTimeout = setTimeout(() => dispatch(checkIsVisibleFirst()), 100);
  };
}

export function setCurrentEvent(event?: Event) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_CURRENT_EVENT,
      event
    });
  };
}

export function setEvents(events: Record<string, Array<Partial<Event>>>) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_EVENTS,
      events
    });

    await dispatch(checkIsVisibleFirst());
  };
}

export function resetEvents() {
  return async (dispatch: any) => {
    await dispatch({
      type: RESET_EVENTS,
    });

    await dispatch(checkIsVisibleFirst());
  };
}

export function addEvent(event: Event) {
  return async (dispatch: any) => {

    await dispatch({
      type: ADD_EVENT,
      event
    });

    // Add also Fulfillment's Notice
    if (event.resourcetype === ResourceType.Fulfillment) {
      const notice = event.lawyerevent!.fulfillment!.notice;

      if (notice) {
        await dispatch({
          type: ADD_EVENT,
          event: notice
        });
      }
    }
  };
}

export function updateEvent(oldEvent: Event, newEvent: Event) {
  return async (dispatch: any) => {
    await dispatch(deleteEvent(oldEvent));

    await dispatch(addEvent(newEvent));

    dispatch(setCurrentEvent(new Event(newEvent)));
  };
}

export function deleteEvent(event: Event) {
  return async (dispatch: any) => {

    await dispatch({
      type: DELETE_EVENT,
      event
    });

    // Delete also Fulfillment's Notice
    if (event.resourcetype === ResourceType.Fulfillment) {
      const notice = event.lawyerevent!.fulfillment!.notice;
      if (!notice) {
        return;

      }
      await dispatch({
        type: DELETE_EVENT,
        event: notice
      });

    }
    if (event.resourcetype === ResourceType.Notice) {
      const fullfillment = event.fulfillment;
      if (fullfillment) {
        await dispatch({
          type: DELETE_EVENT,
          event: fullfillment
        });
      }
    }
  };
}

// REDUCER
export default function (state: IAgendaReducerState = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_SEARCH_VISIBILITY:
      return {
        ...state,
        isVisibleSearch: action.isVisible
      };

    case SET_SEARCHED_EVENTS:
      let _array: any = state.searchedEvents ? state.searchedEvents : action.events

      // if (!_array) return {
      //   ...state,
      //   searchedEvents: undefined
      // };

      if (!_array) return {
        ...state
      }

      let __array = !_array || !action.events ? _array : _array.concat(
        action.events.filter((event: Event) => {
          return _array.indexOf(event) === -1
        })
      )

      return {
        ...state,
        searchedEvents: __array ? __array.map((e: Partial<Event>) => new Event(e)) : __array
      };

    case SET_SEACH_PAGE:
      return {
        ...state,
        searchPage: action.page
      };

    case SET_END_REACHED:
      return {
        ...state,
        endReached: action.endReached
      };

    case RESET_SEARCHED_EVENTS:
      return {
        ...state,
        searchedEvents: undefined
      };

    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      };

    case SET_CHANGE_DAY_TYPE:
      return {
        ...state,
        updateType: action.updateType
      };

    case SET_CURRENT_DAY:
      return {
        ...state,
        day: action.day
      };

    case SET_CURRENT_EVENT:
      return {
        ...state,
        event: action.event
      };

    case SET_EVENTS:
      Object.keys(action.events).forEach((date: string) => {
        action.events[date] = action.events[date].map((ev: any) => new Event(ev));
      });

      return {
        ...state,
        events: {
          ...state.events,
          ...action.events
        },
        isVisibleDay: {
          ...Object.keys(action.events).reduce((result: any, key: string) => ({ ...result, [key]: false }), {}),
          ...state.isVisibleDay
        }
      };
    case RESET_EVENTS:
      return {
        ...state,
        events: {},
        isVisibleDay: {}
      };
    case CHECK_IS_VISIBLE_FIRST: {
      return {
        ...state,
        onTopDay: Object.keys(state.isVisibleDay).filter(key => state.isVisibleDay[key]).sort()[0]
        // day: d
      };
    }
    case SET_VISIBILITY_ON_DAY:
      return {
        ...state,
        isVisibleDay: {
          ...state.isVisibleDay,
          [action.day]: action.visibility
        }
      };
    case ADD_EVENT:
      if (state.events[action.event.date]) {
        return {
          ...state,
          events: {
            ...state.events,
            [action.event.date]: [
              ...[new Event(action.event)],
              ...state.events[action.event.date]
            ]
          }
        };
      } else {
        return state;
      }
    case UPDATE_EVENT:
      if (state.events[action.event.date]) {
        return {
          ...state,
          events: {
            ...state.events,
            [action.event.date]: [
              ...[new Event(action.event)],
              ...state.events[action.event.date].filter((ev) => ev.id !== action.event.id)
            ]
          },
          event: new Event(action.event)
        };
      } else {
        return {
          ...state,
          event: new Event(action.event)
        };
      }
    case DELETE_EVENT:
      if (state.events[action.event.date]) {
        return {
          ...state,
          events: {
            ...state.events,
            [action.event.date]: [
              ...state.events[action.event.date].filter((ev) => ev.id !== action.event.id)
            ]
          },
          event: undefined
        };
      } else {
        return {
          ...state,
          event: undefined
        };
      }

    case TOGGLE_IS_VISIBLE_FILTERS:
      return {
        ...state,
        isVisibleFilters: !state.isVisibleFilters
      };
    case SET_SHOW_DELETE_MODAL:
      return {
        ...state,
        modalDeleteEvent: action.youWantDelete
      }
    default:
      return state;
  }
}
