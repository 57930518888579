import React from 'react';
import { ActivityModal } from 'store/types/ActivityModal';

interface Props {
  content: ActivityModal
  ended?: boolean
}

const ConfirmModal: React.FC<Props> = ({ content, ended }) => {

  return (
    <div className={'activity__modal opened'}>
      <div className={!ended ? 'modal__content' : 'modal__content ended'}>
        {content.certified && content.certified}
        {content.img && <div className={'img__container'}>
          <img src={content.img} alt="card1" />
        </div>}
        {content.title && <p className={'title'}>{content.title}</p>}
        {content.copy && <p className={'copy'}>{content.copy}</p>}
        {content.copy2 && <p className={'copy'}>{content.copy2}</p>}
        {content.cta && content.onConfirm && <button className={content.certified ? 'cancel' : 'button-primary'} onClick={content.onConfirm}>
          <span>{content.cta}</span>
        </button>}
        {content.certified && <div style={{ marginBottom: 36 }} />}
        {content.ctaUndo && content.onCancel && <button className={content.certified ? 'button-primary' : 'cancel'} onClick={content.onCancel}>
          {content.ctaUndo}
        </button>}

      </div>
    </div>
  );
};

export default ConfirmModal;
