import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Network, { NetworkMethod } from '../network/Network';
import { User, LastPayment } from '../store/types/User';
import Storage from '../store/Storage';
import { setCurrentUser } from '../store/reducers/UserReducer';
import { setError500, setIsLoading } from '../store/reducers/AppReducer';
import SelectOption from '../store/types/SelectionOption';
import Office from '../store/types/Office';
import City from '../store/types/City';
import AnalyticsService from 'services/AnalyticsService';
import { SubscriptionInfo } from 'store/types/SubscriptionInfo';
import { SubscriptionDiscount } from 'store/types/SubscriptionDiscount';
import { Price } from 'store/types/Price';


class UserService {

  // LOGIN
  static useLogin = (): [User, any, (email: string, password: string) => void] => { // Chained requests
    const [error, setError] = useState();
    const [token, loginError, loginRequest] = UserService.useLoginAPI();
    const [user, userError, userRequest] = UserService.useUserAPI();

    const request = async (email: string, password: string) => {
      loginRequest(email, password);
    };

    useEffect(() => {
      if (token) {
        userRequest();
      }

    }, [token]);

    useEffect(() => {
      setError(loginError);

    }, [loginError]);


    useEffect(() => {
      setError(userError);

    }, [userError]);

    return [user, error, request];
  };

  static useRegister = (): [User, any, (tempUser: User) => void] => { // Chained requests
    const [error, setError] = useState();
    const [token, registerError, registerRequest] = UserService.useRegisterAPI();
    const [user, userError, userRequest] = UserService.useUserAPI();

    const request = async (tempUser: User) => {
      registerRequest(tempUser);
    };

    useEffect(() => {
      if (token) {
        userRequest();
      }

    }, [token]);

    useEffect(() => {
      setError(registerError);

    }, [registerError]);


    useEffect(() => {
      setError(userError);

    }, [userError]);

    return [user, error, request];
  };

  //LIGHT REGISTER

  static useLightRegister = (): [string, any, (tempUser: Partial<User>, id: string | undefined) => void] => { // Chained requests
    const [error, setError] = useState();
    const [token, registerError, registerRequest] = UserService.useLightRegisterAPI();

    const request = async (tempUser: Partial<User>, id: string | undefined) => {
      const lightuser: Partial<User> = {
        first_name: tempUser.first_name,
        last_name: tempUser.last_name,
        email: tempUser.email,
        phone: tempUser.phone,
        profile: tempUser.profile,
        privacy: tempUser.privacy,
        terms: tempUser.terms,
        temp_demand: id
      }
      registerRequest(lightuser);
    };

    useEffect(() => {
      setError(registerError);

    }, [registerError]);

    return [token, error, request];
  };

  private static useLightRegisterAPI = (): [string, any, (tempUser: Partial<User>) => void] => {
    const [stateResponse, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (tempUser: Partial<User>) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'auth/light-register/';
      const data = JSON.stringify(tempUser);

      const response = await Network.fetchAPI(method, endpoint, false, data, null);
      dispatch(setIsLoading(false));

      setError(response.error);

      if (response.data) {
        setResponse(response.data.key);
      }
      if (response.error500) {
        dispatch(setError500(true));
      }
    };

    return [stateResponse, error, request];
  };

  // REGISTER
  static useUserAPI = (): [User, any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async () => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.get;
      const endpoint = 'users/me/';

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        dispatch(setCurrentUser(networkResponse.data));

        Storage.set(Storage.USER_ID, networkResponse.data.id)
        AnalyticsService.sendUID()

        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };

  static useUpdateUserMeAPI = (updateRedux: boolean = true): [User, any, (user: User) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (user: User) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.put;
      const endpoint = 'users/me/';
      const data = JSON.stringify(user);

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
        if (updateRedux) dispatch(setCurrentUser(networkResponse.data));
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };

  static useUpdateUserAPI = (updateRedux: boolean = true): [User, any, (user: User) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (user: User) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.put;
      const endpoint = 'auth/user/';
      const data = JSON.stringify(user);

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
        if (updateRedux) dispatch(setCurrentUser(networkResponse.data));
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };

  // USER VIA AUTH
  static useResetPasswordAPI = (): [boolean, any, (email: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (email: string) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'auth/password/reset/';
      const data = JSON.stringify({ email });

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };

  static useChangePasswordAPI = (): [boolean, any, (password: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (password: string) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'auth/password/change/';
      const data = JSON.stringify({
        new_password1: password,
        new_password2: password
      });

      const networkResponse = await Network.fetchAPI(method, endpoint, true, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };

  static useSearchSectionsAPI = (): [SelectOption[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = 'aigasections/';
      const params = {
        search: query
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const sections = (networkResponse.data as any[]).map((section: Office) => new SelectOption(section, section.name));
        setResponse(sections);
      }
    };

    return [response, error, request];
  };


  // CANCEL SUBSCRIPTION

  static useCancelSubscriptionAPI = (): [LastPayment, any, (paymentId?: number) => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()

    const dispatch = useDispatch()

    let request = async (paymentId?: number) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.get
      let endpoint = 'payments/' + paymentId + '/cancel/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)
      if (response.error) {
        dispatch(setError500(true));
      }

      if (response.data) {
        setResponse(response.data)
      }

    }

    return [response, error, request]
  }


  static useSearchCitiesAPI = (): [SelectOption[], any, (query: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const request = async (query: string) => {

      const method = NetworkMethod.get;
      const endpoint = 'cities/';
      const params = {
        search: query
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, true, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        const cities = (networkResponse.data as any[]).map((city: City) => new SelectOption(city, city.display_name));
        setResponse(cities);
      }
    };

    return [response, error, request];
  };
  // UPDATE USER
  private static useLoginAPI = (): [string, any, (email: string, password: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (email: string, password: string) => {
      dispatch(setIsLoading(true));

      const method = 'POST';
      const endpoint = 'auth/login/';
      const data = JSON.stringify({
        'email': email,
        'password': password
      });

      const networkResponse = await Network.fetchAPI(method, endpoint, false, data, null);
      dispatch(setIsLoading(false));

      setError(networkResponse.error);

      if (networkResponse.data) {
        await Storage.set(Storage.AUTH_TOKEN, networkResponse.data.key);
        setResponse(networkResponse.data.key);
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }

    };

    return [response, error, request];
  };
  // FORGOT PASSWORD
  private static useRegisterAPI = (): [string, any, (tempUser: User) => void] => {
    const [stateResponse, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const request = async (tempUser: User) => {
      dispatch(setIsLoading(true));

      const method = NetworkMethod.post;
      const endpoint = 'auth/register/';
      const data = JSON.stringify(tempUser);

      const response = await Network.fetchAPI(method, endpoint, false, data, null);
      dispatch(setIsLoading(false));

      setError(response.error);

      if (response.data) {
        await Storage.set(Storage.AUTH_TOKEN, response.data.key);
        setResponse(response.data.key);
      }
      if (response.error500) {
        dispatch(setError500(true));
      }
    };

    return [stateResponse, error, request];
  };


  static useSearchSkillsAPI = (): [Array<SelectOption>, any, (query: string) => void] => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState();

    let request = async (query: string) => {

      let method = NetworkMethod.get
      let endpoint = 'skills/'
      let params = {
        search: query
      }

      let response = await Network.fetchAPI(method, endpoint, true, null, params)
      setError(response.error)

      if (response.data) {
        const options = (response.data as any[])
          .filter((skill) => skill.id != 12)
          .filter((skill) => skill.name != 'Attività di cancelleria')
        // .map((skill: Skill) => new SelectOption(skill, skill.name));
        setResponse(options);
      }
    }

    return [response, error, request]
  }

  static useSetSeenPopups = (): [any, any, any] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    let request = async (request: Request) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.post;
      let endpoint = "popups/seen/";
      // let data = JSON.stringify(request);

      let response = await Network.fetchAPI(method, endpoint, true, { "popup_id": 1 }, null);
      dispatch(setIsLoading(false));

      setError(response.error);

      if (response.data) {
        setResponse(response.data);
      }
    };

    return [response, error, request];
  }

  // DELETE SUBSCRIPTION PAYMENT

  static useCancelSubscriptionUserAPI = (): [any, any, (subscriptionId?: number) => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()

    const dispatch = useDispatch()

    let request = async (subscriptionId?: number) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.post
      let endpoint = 'payments/' + subscriptionId + '/cancel/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }

    }

    return [response, error, request]
  }

  static useSubscriptionsInfoAPI = (): [Array<SubscriptionInfo>, any, () => void] => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState();



    let request = async () => {
      let method = NetworkMethod.get
      let endpoint = 'payment-types/info/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useValidateCouponCodeAPI = (): [SubscriptionDiscount, any, (couponCode: string, planId: string) => void] => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState();

    let request = async (couponCode: string, planId: string) => {

      let method = NetworkMethod.get
      let endpoint = 'coupon-codes/' + couponCode + '/validate/' + planId

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useCityPriceAPI = (): [Price, any, (cityId: string) => void] => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState();

    let request = async (cityId: string) => {

      let method = NetworkMethod.get
      let endpoint = `payment-variants/professional-city/${cityId}/`

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useUpdateCity2API = (): [any, any, (cityId: string, paymentId: string) => void] => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState();

    let request = async (cityId: string, paymentId: string) => {
      let method = NetworkMethod.put
      let endpoint = 'payment-variants/change-city/'
      let data = {
        "payment_id": paymentId,
        "city_id": cityId
      }
      let response = await Network.fetchAPI(method, endpoint, true, data, null)
      setError(response.error)

      if (response) {
        setResponse(response)
      }
    }

    return [response, error, request]
  }

}

export default UserService;
