import React, { useEffect, useState, Fragment } from 'react';
import 'moment/locale/it'
import classNames from 'classnames';
import searchIcon from 'image/icons/news-search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IAgendaReducer, setSearchVisibility } from 'store/reducers/AgendaReducer';
import Search from 'components/search/Search';
import { ActivitiesReducer, setActivityDemandsTab, setActivityAssignedDemandsTab } from 'store/reducers/ActivitiesReducer';
import { ActivitiesContext } from './ActivityRequest';
import { DemandsStatus, CandidatesStatus, ManagementsStatus } from 'values/values';
import { setActivityCandidatesTab } from '../../store/reducers/ActivitiesReducer';
import strings from 'values/strings';
var _ = require('lodash');

interface Props {
  onChangeStatus: (tag: any) => void
  onChangeCharacter: (query?: string) => void
  onSelect: () => void
}

let ActivityFilter: React.FC<Props> = React.memo((props) => {

  let [query, setQuery] = useState<string>()

  const isVisibleSearch = useSelector((state: IAgendaReducer) => state.agenda.isVisibleSearch);
  let context = useSelector((state: ActivitiesReducer) => state.activitiesReducer.activitiesContext)

  let demandsTab = useSelector((state: ActivitiesReducer) => state.activitiesReducer.demandsTab)
  let candidatesTab = useSelector((state: ActivitiesReducer) => state.activitiesReducer.candidatesTab)
  let assignedDemandsTab = useSelector((state: ActivitiesReducer) => state.activitiesReducer.assignedDemandsTab)

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isVisibleSearch && query) setQuery(undefined)
  }, [isVisibleSearch])

  useEffect(() => {
    props.onChangeCharacter(query)
    props.onSelect()
  }, [query])

  let initialValue = () => {
    switch (context) {
      case ActivitiesContext.demands: return demandsTab
      case ActivitiesContext.candidates: return candidatesTab
      case ActivitiesContext.managements: return assignedDemandsTab
    }
  }

  let updateValue = (value: any) => {
    switch (context) {
      case ActivitiesContext.demands: return dispatch(setActivityDemandsTab(value))
      case ActivitiesContext.candidates: return dispatch(setActivityCandidatesTab(value))
      case ActivitiesContext.managements: return dispatch(setActivityAssignedDemandsTab(value))
    }
  }

  let keys = () => {
    switch (context) {
      case ActivitiesContext.demands: return _.keys(DemandsStatus)
      case ActivitiesContext.candidates: return _.keys(CandidatesStatus)
      case ActivitiesContext.managements: return _.keys(ManagementsStatus)
    }
  }

  let localised = (string?: string) => {
    switch (context) {
      case ActivitiesContext.demands:
        switch (string) {
          case DemandsStatus.PENDING: return strings.activityFilter.demands.pending
          case DemandsStatus.ASSIGNED: return strings.activityFilter.demands.assigned
          case DemandsStatus.COMPLETED: return strings.activityFilter.demands.completed
          case DemandsStatus.PAID: return strings.activityFilter.demands.paid
          case DemandsStatus.ANNULLED: return strings.activityFilter.demands.anulled
          case DemandsStatus.EXPIRED: return strings.activityFilter.demands.expired
          default: return strings.activityFilter.all
        }
      case ActivitiesContext.candidates:
        switch (string) {
          case CandidatesStatus.PENDING: return strings.activityFilter.candidates.pending
          case CandidatesStatus.ACCEPTED: return strings.activityFilter.candidates.accepted
          case CandidatesStatus.REJECTED: return strings.activityFilter.candidates.rejected
          case CandidatesStatus.DEAD: return strings.activityFilter.candidates.dead
          default: return strings.activityFilter.all
        }
      case ActivitiesContext.managements:
        switch (string) {
          case ManagementsStatus.ASSIGNED: return strings.activityFilter.managements.assigned
          case ManagementsStatus.COMPLETED: return strings.activityFilter.managements.completed
          case ManagementsStatus.PAID: return strings.activityFilter.managements.paid
          default: return strings.activityFilter.all
        }
    }
  }

  useEffect(() => {
    props.onChangeStatus(initialValue())
    props.onSelect()
  }, [initialValue()])

  let isSelectedRange = (range: string) => {
    return range === initialValue()
  }
  
  let statusesFilter = () => {
    return (
      <div className={'activity__filter'}>
        {keys().map((item: any, index: number) => {
          return (
            <button
              key={index}
              className={classNames('button-filter date', { active: isSelectedRange(item) })}
              onClick={() => {
                updateValue(item)
              }}>
              {localised(item)}
            </button>
          )
        })}
      </div>
    )
  }

  let searchFilter = () => {
    return (
      <Fragment>
        <div className={classNames('activity__header__search', { visible: isVisibleSearch })}>
          {isVisibleSearch && <Search callback={setQuery} />}
        </div>
        <img src={searchIcon} onClick={() => {
          dispatch(setSearchVisibility(true))
          }} style={{ cursor: 'pointer' }} alt={'search'}/>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={'activity__filter__search__container'}>
        {statusesFilter()}
        {searchFilter()}
      </div>
    </Fragment>
  );
});

export default ActivityFilter;

