import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Onboarding from '../page/Onboarding'
import SignUp from '../page/SignUp'
import SignIn from '../page/SignIn'
import Forgot from '../page/Forgot'
import Loading from '../page/Loading'
import PageError from '../page/PageError'
import Subscriptions from 'page/Subscriptions'
import { useDispatch, useSelector } from 'react-redux'
import { IUserReducer } from '../store/reducers/UserReducer'
import UserService from '../services/UserServices'
import Storage from '../store/Storage'
import { IAppReducer, setIsReady } from '../store/reducers/AppReducer'
import AppWrapper from '../page/AppWrapper'
import Logout from '../page/Logout'
import OnboardingMob from '../page/OnboardingMob'
import { SidePanelReducer } from '../store/reducers/SidePanelReducer';
import SSO from '../page/SSO';
import AccountConfirm from 'components/subscription/collega/AccountConfirm'
import SubscriptionPlan from 'components/subscription/SubscriptionPlan'
import SubscriptionActivate from 'page/SubscriptionActivate'
import Subscription from 'components/subscription/collega/Subscription'

interface IAuthMiddlewarePropsExtends {
  exact?: boolean
  path: string
  responsive?: boolean
  component: React.ComponentType<any>
}

const AuthMiddleware = ({ component: Component, ...rest }: IAuthMiddlewarePropsExtends) => {
  const authenticated = !!useSelector((state: IUserReducer) => state.user.currentUser)

  return (
    <LayoutMiddleware {...rest} component={(props) => {
      if (authenticated) return <Component {...props} />
      return <Redirect to={'/'} />
    }} />
  )
}

const GuestMiddleware = ({ component: Component, ...rest }: IAuthMiddlewarePropsExtends) => {
  const authenticated = !!useSelector((state: IUserReducer) => state.user.currentUser)
  return (
    <LayoutMiddleware {...rest} component={(props) => {
      if (!authenticated) return <Component {...props} />
      return <Redirect to={'/'} />
    }} />
  )
}

const LayoutMiddleware = ({ component: Component, ...rest }: IAuthMiddlewarePropsExtends) => {
  const [responsive, setResponsive] = useState<boolean>()

  // SCREEN SIZE LOGIC
  const mq = window.matchMedia('(max-width: 940px)')

  useEffect(() => {
    // initial check to toggle something on or off
    toggle()
    try {
      // returns true when window is <= 940px
      mq.addEventListener('change', toggle)
      // unmount cleanup handler
      return () => { mq.removeEventListener('change', toggle) }
    } catch (e1) {
      //SAFARI
      try {
        mq.addListener(toggle)
        return () => { mq.removeListener(toggle) }
      } catch (e2) {
        console.error(e2);
      }
    }
  }, [])

  // toggle something based on matchMedia event
  const toggle = () => {
    console.log(mq.matches)
    setResponsive(mq.matches)
  }
  // SCREEN SIZE END


  return (
    <Route {...rest} render={(props) => {
      if (!responsive || responsive && rest.responsive) return <Component {...props} />
      return <OnboardingMob />
    }} />
  )
}



function AppRouter() {
  const authenticated = !!useSelector((state: IUserReducer) => state.user.currentUser)
  const isReady = !!useSelector((state: IAppReducer) => state.app.isReady)
  const currentCourse = useSelector((state: SidePanelReducer) => state.sidePanelReducer.currentCourse)
  const currentPackage = useSelector((state: SidePanelReducer) => state.sidePanelReducer.currentPackage)

  const [user, , userRequest] = UserService.useUserAPI()

  const dispatch = useDispatch()


  useEffect(() => {
    loadStorageData()
  }, [])

  useEffect(() => {
    if (user && authenticated) {
      dispatch(setIsReady())
    }
  }, [user, authenticated])


  const loadStorageData = async () => {
    const _authToken = await Storage.get(Storage.AUTH_TOKEN)
    if (!_authToken) {
      dispatch(setIsReady())
    } else {
      userRequest()
    }
  }

  if (!isReady) return <Loading />

  return (
    <Router>
      <Switch>

        {/* BOTH */}
        <Route path="/" exact={true} render={() => {
          if (authenticated && currentCourse) return <Redirect to={'/courses/' + currentCourse.id} />
          if (authenticated && currentPackage) return <Redirect to={'/package/' + currentPackage.id} />
          if (authenticated) return <Redirect to={'dashboard'} />
          return <LayoutMiddleware path={'/'} component={Onboarding} />
        }} />

        {/* BOTH */}
        <GuestMiddleware path="/signin" exact={true} responsive={true} component={SignIn} />
        <GuestMiddleware path="/forgot" exact={true} responsive={true} component={Forgot} />

        <AuthMiddleware path="/empty" component={AppWrapper} />
        <AuthMiddleware path="/dashboard" component={AppWrapper} />
        <AuthMiddleware path="/activity-request" component={AppWrapper} />
        <AuthMiddleware path="/activity-proposal" component={AppWrapper} />
        <AuthMiddleware path="/activity-assignments" component={AppWrapper} />
        <AuthMiddleware path="/agenda" component={AppWrapper} />
        <AuthMiddleware path="/records" component={AppWrapper} />
        <AuthMiddleware path="/news" component={AppWrapper} />

        {/* Subscription */}
        <LayoutMiddleware path="/subscriptions" exact={true} responsive={true} component={Subscriptions} />
        <LayoutMiddleware path="/subscription" exact={true} responsive={true} component={Subscription} />
        <LayoutMiddleware path="/account-confirm" exact={true} responsive={true} component={AccountConfirm} />
        <LayoutMiddleware path="/subscription-plan" exact={true} responsive={true} component={SubscriptionPlan} />
        <LayoutMiddleware path="/subscription-activate" exact={true} responsive={true} component={SubscriptionActivate} />

        {/* BOTH */}
        <LayoutMiddleware path="/sso/:token?" responsive={true} component={SSO} />

        {/* Handled by Shop website */}
        {/* <LayoutMiddleware path="/courses/:id" responsive={true} component={AppWrapper} /> */}
        {/* <LayoutMiddleware path="/package/:id" responsive={true} component={AppWrapper} /> */}
        {/* <LayoutMiddleware path="/courses" responsive={true} component={AppWrapper} /> */}

        <LayoutMiddleware path="/request-activities/:type?" responsive={true} component={AppWrapper} />
        <AuthMiddleware path="/purchased" responsive={true} component={AppWrapper} />

        <AuthMiddleware path="/profile" component={AppWrapper} />
        <AuthMiddleware path="/profile-complete" component={AppWrapper} />
        <AuthMiddleware path="/profile-edit" component={AppWrapper} />
        <AuthMiddleware path="/profile-skills" component={AppWrapper} />
        <AuthMiddleware path="/profile-locations" component={AppWrapper} />

        {/* BOTH */}
        <AuthMiddleware path="/logout" exact={true} responsive={true} component={Logout} />

        {/* BOTH */}
        {/* SignUp check if can be GuestMiddleware */}
        <LayoutMiddleware path="/signup" exact={true} responsive={true} component={SignUp} />
        <LayoutMiddleware path="/loading" exact={true} responsive={true} component={Loading} />
        <LayoutMiddleware path="/404" exact={true} responsive={true} component={PageError} />
        <Route component={() => <Redirect to={'404'} />} />

        {/* MOBILE */}
        <Route component={OnboardingMob} />

      </Switch>
    </Router>
  )
}

export default AppRouter
