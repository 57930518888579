import React from 'react';
import empty from 'image/empty.svg';
// import empty from 'image/empty-copy.svg';
import strings from 'values/strings';

const Empty: React.FC = () => {
  return (
    <div className={'empty__container'}>
        <img src={empty} alt={''} />
       <span>{strings.empty.title}</span>
    </div>
  );
};

export default Empty;
