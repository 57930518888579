import React, { useState, useEffect } from 'react';
import close from 'image/icons/close.svg';
import { useSelector, useDispatch } from 'react-redux';
import { SidePanelReducer, setCurrentCourse, setPurchasedCourse, setCurrentPackage } from 'store/reducers/SidePanelReducer';
import { Item, ProfessorItem, ResourceItem } from 'store/types/Item';
import { ReactComponent as Credits } from 'image/icons/credits.svg';
import { ReactComponent as Price } from 'image/icons/price.svg';
import { ReactComponent as Ticket } from 'image/icons/ticket.svg';
import { ReactComponent as Duration } from 'image/icons/duration.svg';
import { ReactComponent as Registration } from 'image/icons/registration.svg';
import { ReactComponent as Deadline } from 'image/icons/deadline.svg';
import coursePay from 'image/coursePay.svg';
import strings from 'values/strings';
import { ActivityModal } from 'store/types/ActivityModal';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import { IUserReducer, updateTempUser, createTempUser } from 'store/reducers/UserReducer';
import Input from 'components/common/Input';
import _ from 'lodash';
import { FormFields } from 'values/values';
import UserService from 'services/UserServices';
import moment from 'moment';
import smoothscroll from 'smoothscroll-polyfill';
import Paypal from './PayPal';
import CourseService from 'services/CourseService';
import greenArrow45 from 'image/icons/nav/arrow-green-45.svg';
import { isMobile } from 'react-device-detect';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import Utility from 'utils/utils';

smoothscroll.polyfill();

export enum ItemPanelCtx { package, course }

interface Props {
  obj: Item
  context: ItemPanelCtx
  history?: any
}

const CourseSidePanel: React.FC<Props> = ({ obj, history, context }) => {

  const [openList, setOpenList] = useState(false)
  const [error, setError] = useState();
  const [toPay, setToPay] = useState(false);
  const [subscription, setSubscription] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showPayPal, setShowPayPal] = useState<boolean>(true)

  const { category, course, last_purchased } = obj

  let [updateResponse, updateError, updateRequest] = UserService.useUpdateUserAPI()
  let [orderResponse, orderError, orderRequest] = CourseService.useOrderAPI()
  let [userResponse, userError, userRequest] = UserService.useUserAPI()

  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const currentCourse = useSelector((state: SidePanelReducer) => state.sidePanelReducer.currentCourse);
  const currentPackage = useSelector((state: SidePanelReducer) => state.sidePanelReducer.currentPackage);
  const purchasedCourse = useSelector((state: SidePanelReducer) => state.sidePanelReducer.purchasedCourse);

  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(createTempUser(currentUser))
  }, [currentUser])

  useEffect(() => {
    setOpenList(false)
    setSubscription(false)
    setToPay(false)
    setShowPayPal(true)

    if (currentCourse) AnalyticsService.screenName(AnalyticsScreens.courseDetail)
    if (currentPackage) AnalyticsService.screenName(AnalyticsScreens.packageDetail)

  }, [currentCourse, currentPackage])

  useEffect(() => {
    setOpenList(false)

    if (purchasedCourse) AnalyticsService.screenName(AnalyticsScreens.purchasedCourseDetail)
  }, [purchasedCourse])

  useEffect(() => {
    if (orderResponse) {
      setIsOrderLoading(false)
      setShowReview(true)

      userRequest()
    }
  }, [orderResponse])

  useEffect(() => {
    if (orderError) setIsOrderLoading(false)
  }, [orderError])

  useEffect(() => {
    if (tempUser && tempUser.profile.is_complete) {
      setToPay(true)
    }
  }, [currentCourse, currentPackage, tempUser])

  useEffect(() => {
    setError({ ...updateError })
  }, [updateError])

  useEffect(() => {
    setError(undefined)
  }, [tempUser])

  useEffect(() => {
    if (updateResponse !== undefined) {
      setIsLoading(false)
      setToPay(true)
    }
  }, [updateResponse])

  useEffect(() => {
    document.querySelectorAll<HTMLElement>('.topPanel').forEach(node => node.scroll({ top: 0, left: 0, behavior: 'smooth' }))
  }, [currentCourse, currentPackage, purchasedCourse])

  const courseDuration = () => {
    const result: Array<any> = []
    course && course.resources && course.resources.forEach((item) => result.push(item.duration))
    const sum = !_.isEmpty(result) ? result.reduce((a, b) => a + b) : 0
    return sum
  }

  const subscribeType: ActivityModal = {
    title: strings.courses.modal.title,
    copy: strings.courses.modal.copy,
    cta: strings.courses.modal.cta,
    ctaUndo: strings.courses.modal.ctaUndo,
    onConfirm: () => history.push('/signin'),
    onCancel: () => setSubscription(false),
  }

  let purchased = _.isEmpty(last_purchased)
  let expiring = course && moment().isBetween(moment(course.accreditation_limit).subtract(7, 'days'), moment(course.accreditation_limit))
  let deadline = last_purchased && last_purchased.course && last_purchased.course.deadline
  let expired = moment().isAfter(deadline);


  //UPDATE PROFILE
  let updateProfile = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile,
      [field]: value
    }

    await dispatch(updateTempUser(FormFields.profile, data))
  }

  let updateLegalOffice = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile.lawfirm,
      [field]: value
    }

    await updateProfile(FormFields.lawfirm, data)
  }

  let onSave = async () => {
    setIsLoading(true)
    updateRequest(tempUser!)
  }

  let handleExternalLink = (link?: string) => {
    if (isMobile) {
      let temp = window.location.href; window.open(`_blank_${link}`); window.open(temp)
    } else {
      window.open(link, '_blank')
    }
  }

  const infoContainer = () => {
    return (
      <div className={'info__container'}>

        <div className={`info__item ${context === ItemPanelCtx.course ? 'info_price_container' : null}`}>
          <Price className={'info_price '} fill={category && category.background_color} />
          {obj.cost
            ? <p className="info">{strings.courses.price} {Utility.currency(obj.cost)} {' + IVA'}</p>
            : obj.package_available
              ? <p className="info">{'Puoi scegliere di iscriverti a questo corso senza pagare, grazie al pacchetto acquistato'}</p>
              : <p className="info">{strings.courses.price} {strings.courses.free}</p>
          }
        </div>

        {course && course.cfp &&
          <div className={'info__item'}>
            <Credits fill={category && category.background_color} />
            <p className="info">{strings.courses.credits} {course.cfp}</p>
          </div>
        }

        {course && courseDuration() != null && <div className={'info__item'}>
          <Duration fill={category && category.background_color} />
          <p className="info">{strings.courses.duration} {courseDuration()} {strings.courses.minutes}</p>
        </div>}

        {course && course.duration &&
          <div className={'info__item'}>
            <Registration fill={category && category.background_color} />
            <p className="info">{strings.courses.registration} {course.duration} {strings.courses.days}</p>
          </div>
        }
        {course && course.accreditation_limit &&
          <div className={'info__item'}>
            <Deadline fill={category && category.background_color} />
            <p className="info">{strings.courses.deadline} <span style={{ color: expiring ? '#F37E6F' : undefined }}>{moment(course.accreditation_limit).format("DD/MM/YYYY")}</span></p>
          </div>
        }
      </div>
    )
  }


  const packageOverview = () => {
    return (
      <div className={currentUser ? purchased ? 'overview__container topPanel' : 'overview__container purchased topPanel' : 'overview__container topPanel'}>

        <p className={'title'}>{obj.name}</p>
        <p className={'content'}>{'Con l’acquisto dei pacchetti potrai iscriverti ai corsi utilizzando i CFP senza ulteriori pagamenti. Ad esempio, con 8 CFP potrai iscriverti a 8 corsi con 1 credito formativo ciascuno, oppure a 4 corsi con 2 crediti formativi ciascuno e via dicendo…'}</p>

        {infoContainer()}

        <div className={currentPackage ? 'button__container' : 'button__container close'}>
          <button className={'button-primary'} onClick={() => setSubscription(true)}>{'Acquista'}</button>
        </div>

      </div>
    )
  }


  const courseOverview = () => {
    return (
      <div className={currentUser ? purchased ? 'overview__container topPanel' : 'overview__container purchased topPanel' : 'overview__container topPanel'}>

        {category && <div className={'ticket'} style={{ color: `${category.foreground_color}`, background: `${category.background_color}` }}>
          <Ticket fill={category && category.foreground_color} width={'9px'} height={'13px'} />
          <p>{category.name}</p>
        </div>}

        <p className={'title'}>{obj.name}</p>
        <p className={'content'}>{obj.short_description}</p>

        {infoContainer()}

        {obj.long_description &&
          <div className={openList ? 'list__container show__list' : 'list__container'}>
            <p className={'name'}>{strings.courses.objective}</p>
            <div className={'hList'} dangerouslySetInnerHTML={{ __html: obj.long_description }} />

            {!openList && <p className={'show'} onClick={() => setOpenList(true)}>{strings.courses.showMore}</p>}
            {openList && obj.course && obj.course.attachment && <p className={'show'} onClick={() => handleExternalLink(obj.course!.attachment)}>{strings.courses.showAttachment}</p>}
          </div>
        }
        {course && course.professors && !_.isEmpty(course.professors) &&
          <div className={'list__container'} >
            <p className={'name'}>{strings.courses.professors}</p>
            {course.professors.map((item: ProfessorItem, index) => {
              return <p className={'professor'} key={index}>{item.name}</p>
            })}
          </div>
        }
        <div className={'list__container'} style={{ marginBottom: '8px' }}>
          <p className={'name'}>{strings.courses.courseList}</p>
          {course && course.resources && course.resources.map((item: ResourceItem, index) => {
            return (
              <div className={'resource__container'} key={index}>
                {purchased ?
                  <p className={'resource'}><span>{index + 1}.</span> {item.name}</p>
                  :
                  expired ?
                    <p className={'resource'}><span>{index + 1}.</span> {item.name}</p>
                    :
                    <a onClick={() => handleExternalLink(item.link)} >
                      <p className={'resource link'}><span>{index + 1}.</span> {item.name}</p>
                      <img src={greenArrow45} alt={'arrow'} />
                    </a>
                }

                {item.duration != null && <p className={'duration'} >{strings.courses.lesson} {item.duration} {strings.courses.minutes}</p>}
              </div>
            )
          })}
        </div>
        {!currentUser ?
          <div className={currentCourse ? 'button__container' : 'button__container close'}>
            <button className={'button-primary'} onClick={() => setSubscription(true)}>{strings.courses.subscribe}</button>
          </div>
          : purchased &&
          <div className={currentCourse ? 'button__container' : 'button__container close'}>
            <button className={'button-primary'} onClick={() => setSubscription(true)}>{strings.courses.subscribe}</button>
          </div>
        }
      </div>
    )
  }

  const courseForm = () => {
    if (currentCourse) AnalyticsService.screenName(AnalyticsScreens.coursesForm)
    if (currentPackage) AnalyticsService.screenName(AnalyticsScreens.packageForm)

    return (
      <div className={'scroll__container'}>
        <form onSubmit={(event) => event!.preventDefault()}>
          <p className={'content billing'}>{strings.courses.form.label}</p>
          <div>
            <Input
              label={strings.courses.form.address}
              placeholder={strings.profileEdit.step.lawfirm.input.address.placeholder}
              value={tempUser!.profile.lawfirm.address}
              onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                if (event) updateLegalOffice(FormFields.address, _.isEmpty(event.target.value) ? null : event.target.value)
                setIsLoading(false)
              }}
              error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.address)}
            />
          </div>
          <div>
            <Input
              label={strings.courses.form.cf}
              placeholder={strings.fieldPlaceholderFiscalCode}
              value={tempUser!.fiscal_code}
              onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                if (event) dispatch(updateTempUser(FormFields.fiscal_code, _.isEmpty(event.target.value) ? null : event.target.value.toUpperCase()))
                setIsLoading(false)
              }}
              error={_.get(error, FormFields.fiscal_code)}
            />
          </div>
          <div>
            <Input
              label={strings.courses.form.vat}
              placeholder={strings.profileEdit.step.professional.input.vat.placeholder}
              value={tempUser!.profile.vat}
              onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                if (event) updateProfile(FormFields.vat, _.isEmpty(event.target.value) ? null : event.target.value)
                setIsLoading(false)
              }}
              error={_.get(_.get(error, FormFields.profile), FormFields.vat)}
            />
          </div>
          <div>
            <Input
              label={strings.courses.form.pec}
              placeholder={strings.profileEdit.step.professional.input.pec.placeholder}
              value={tempUser!.profile.pec}
              onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                if (event) updateProfile(FormFields.pec, _.isEmpty(event.target.value) ? null : event.target.value)
                setIsLoading(false)
              }}
              error={_.get(_.get(error, FormFields.profile), FormFields.pec)}
            />
          </div>
          {tempUser!.profile.lawfirm.address && tempUser!.fiscal_code && tempUser!.profile.pec ?
            <button
              className={'button-primary'}
              disabled={error}
              onClick={onSave}>{isLoading && !error ? <span className={'button-loading'} /> : (obj.package_available ? strings.courses.form.proceedFree : strings.courses.form.proceed)}</button>
            :
            <button
              className={'button-primary'}
              disabled={true}>{strings.courses.form.proceed}</button>
          }
        </form>
      </div>
    )
  }

  const courseSubscription = () => {
    if (currentCourse) AnalyticsService.screenName(AnalyticsScreens.coursesPayPal)
    if (currentPackage) AnalyticsService.screenName(AnalyticsScreens.packagePayPal)

    return (
      <div className={'scroll__container'}>
        <p className={'content subscribe'}>{context == ItemPanelCtx.course ? strings.courses.subscribing : 'Acquista'}</p>
        <p className={'title'}>{obj.name}</p>

        {infoContainer()}

        {/* PayPal */}
        {(obj.course || obj.package) && (obj.cost != null && obj.cost > 0) &&
          <div style={{ visibility: showPayPal ? 'visible' : 'hidden' }}>
            <Paypal
              item={obj}
              onStart={() => {
                setShowPayPal(false)
              }}
              onSuccess={(orderId: any) => {
                setIsOrderLoading(true)
                orderRequest(obj, orderId)
              }} />
          </div>
        }

        {/* Free */}
        {course && (obj.cost == null || obj.cost == 0) &&
          <div className={'button__container'}>
            <button className={'button-primary'}
              onClick={() => {
                setIsOrderLoading(true)
                orderRequest(obj, undefined)
              }}>
              {obj.package_available ? strings.courses.subscribe : strings.courses.subscribeFree}
            </button>
          </div>
        }
        {/* {orderError && <p className={'content alert'}>{'Si è verificato un problema, riprovare.'}</p>} */}
        {expiring && <p className={'content alert'}>{strings.courses.alert}</p>}
      </div>
    )
  }

  const courseReview = () => {
    AnalyticsService.screenName(AnalyticsScreens.coursesSuccess)

    return (
      <div className={'scroll__container'}>
        {isOrderLoading ? <div className={'loader_empty_state_container'}>
          <div className={'loader_loading'} />
        </div> :
          <>
            <img src={coursePay} alt={'course'} style={{ marginBottom: '32px' }} />
            <p className={'title'}>{strings.courses.complete}</p>
            <p className={'content'}>{strings.courses.pay}</p>
            <div className={'button__container'} style={{ marginTop: '48px' }}>
              <button onClick={() => history.push('/purchased')} className={'button-primary'}>{strings.courses.toCourses}</button>
            </div>
          </>}

      </div>
    )
  }


  const packageReview = () => {
    AnalyticsService.screenName(AnalyticsScreens.packageSuccess)

    return (
      <div className={'scroll__container'}>
        {isOrderLoading ? <div className={'loader_empty_state_container'}>
          <div className={'loader_loading'} />
        </div> :
          <>
            <img src={coursePay} alt={'course'} style={{ marginBottom: '32px' }} />
            <p className={'title'}>{'Pacchetto acquistato!'}</p>
            <p className={'content'}>{strings.courses.pay}</p>

            <div className={'button__container'} style={{ marginTop: '48px' }}>
              <button onClick={() => {setShowReview(false); history.push('/courses')}} className={'button-primary'}>{'Torna ai corsi'}</button>
            </div>

          </>}

      </div>
    )
  }



  let renderCourse = () => {
    return (
      <div className={(purchasedCourse || currentCourse) ? 'courses__side__panel__container' : 'courses__side__panel__container closed'}>
        <div className={'close-right'}>

          <img src={close} style={{ cursor: 'pointer' }} alt={'close'} onClick={() => {
            if (currentCourse) AnalyticsService.screenName(AnalyticsScreens.courses)
            if (purchasedCourse) AnalyticsService.screenName(AnalyticsScreens.purchasedCourses)

            dispatch(setCurrentCourse(undefined));
            dispatch(setPurchasedCourse(undefined));
            setSubscription(false)

            history && history.push('/courses')
          }} />

          <p className={'label back'} onClick={() => {
            dispatch(setCurrentCourse(undefined));
            dispatch(setPurchasedCourse(undefined));
            dispatch(setCurrentPackage(undefined));
            setSubscription(false)
            history && history.push('/courses')
          }} >{strings.courses.back}</p>

          {currentUser && !purchased && <div>
            {!expired
              ? <p className={'label purchased'}>{strings.courses.purchasedTicket}</p>
              : <p className={'label expired'}>{strings.courses.expiredTicket}</p>
            }
          </div>}
        </div>

        {currentUser ? !subscription && courseOverview() : courseOverview()}

        {currentUser && subscription && !toPay && courseForm()}
        {(!orderResponse && !isOrderLoading) && subscription && toPay && courseSubscription()}
        {(orderResponse || isOrderLoading) && courseReview()}
        {!currentUser && subscription && <ConfirmModal content={subscribeType} />}

      </div>
    )
  }

  let renderPackage = () => {
    return (
      <div className={currentPackage ? 'courses__side__panel__container' : 'courses__side__panel__container closed'}>
        <div className={'close-right'}>

          <img src={close} style={{ cursor: 'pointer' }} alt={'close'} onClick={() => {
            AnalyticsService.screenName(AnalyticsScreens.courses)

            dispatch(setCurrentPackage(undefined));
            setSubscription(false)

            history && history.push('/courses')
          }} />

          <p className={'label back'} onClick={() => {
            dispatch(setCurrentCourse(undefined));
            dispatch(setPurchasedCourse(undefined));
            dispatch(setCurrentPackage(undefined));
            setSubscription(false)
            history && history.push('/courses')
          }} >{strings.courses.back}</p>

        </div>

        {currentUser ? !subscription && packageOverview() : packageOverview()}

        {currentUser && subscription && !toPay && courseForm()}
        {(!showReview && !isOrderLoading) && subscription && toPay && courseSubscription()}
        {(showReview || isOrderLoading) && packageReview()}
        {!currentUser && subscription && <ConfirmModal content={subscribeType} />}

      </div>
    )
  }


  return (
    <>
      {context === ItemPanelCtx.course && renderCourse()}
      {context === ItemPanelCtx.package && renderPackage()}
    </>
  )
};

export default CourseSidePanel;
