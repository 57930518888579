import { useState } from "react";
import Network, { NetworkMethod } from "network/Network";
import { News, NewsCategory } from "store/types/News";
// import { setIsLoading } from "store/reducers/appReducer";
var _ = require('lodash');

class NewsService {

  static useNewsAPI = (): [Array<News>, any, (page: number, category?: NewsCategory, date_min?: string, date_max?: string, query?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    let request = async (page: number, category?: NewsCategory, date_min?: string, date_max?: string, query?: string) => {

      let method = NetworkMethod.get
      let endpoint = 'articles/?page=' + page
      let params = {
        date_min: date_min
      }
      if (category) _.set(params, 'category', category.id)
      if (query) _.set(params, 'search', query)
      if (date_max) _.set(params, 'date_max', date_max)

      let response = await Network.fetchAPI(method, endpoint, true, null, params)

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useNewsCategoriesAPI = (): [Array<NewsCategory>, any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    let request = async () => {

      let method = NetworkMethod.get
      let endpoint = 'articles/categories/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


}

export default NewsService