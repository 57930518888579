
import React, { useEffect, useState } from 'react';
import Select from 'components/common/Select';
import InputAutosuggest from '../common/AutoSuggest'
import Input from 'components/common/Input';

import SelectOption from 'store/types/SelectionOption';
import strings from 'values/strings';

import { ExternalLinks, FormFields, RoleType } from 'values/values';
import UserService from 'services/UserServices';
import { useDispatch, useSelector } from 'react-redux';
import { IUserReducer, updateTempUser } from 'store/reducers/UserReducer';
import classNames from 'classnames';
var _ = require('lodash');

interface Props {
  registerError: any
}

const options = [
  new SelectOption<RoleType>(RoleType.LAWYER, 'Avvocato'),
  new SelectOption<RoleType>(RoleType.ESTABLISHED, 'Avvocato stabilito'),
  new SelectOption<RoleType>(RoleType.PRACTICING, 'Praticante avvocato'),
  new SelectOption<RoleType>(RoleType.QUALIFIED, 'Praticante avvocato abilitato')
];

const LightSignUp: React.FC<Props> = ({ registerError }) => {
  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
  const [lawfirmCity, setLawfirmCity] = useState<any>({
    query: '',
    object: undefined
  })

  const [error, setError] = useState();
  const [oulineCheckboxTerms, setOutlineCheckboxTerms] = useState(false);
  const [oulineCheckboxPrivacy, setOutlineCheckboxPrivacy] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (registerError) {
      setError(registerError)
    }
  }, [registerError])

  useEffect(() => {
    setError(undefined)
  }, [tempUser])

  useEffect(() => {
    if (tempUser) {
      updateLegalOffice(FormFields.city, lawfirmCity.object)
    }
  }, [lawfirmCity])

  let updateLegalOffice = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile.lawfirm,
      [field]: value
    }

    await updateProfile(FormFields.lawfirm, data)
  }

  let updateProfile = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile,
      [field]: value
    }

    await dispatch(updateTempUser(FormFields.profile, data))
  }

  let typeValue = () => {
    if (!tempUser!.profile.type) return undefined
    return options.find((option) => option.value === tempUser!.profile.type)
  }

  const toggleTerms = (force = false) => {
    dispatch(updateTempUser(FormFields.terms, force || !tempUser!.terms));
  };

  const togglePrivacy = (force = false) => {
    dispatch(updateTempUser(FormFields.privacy, force || !tempUser!.privacy));
  };

  return (
    <div className={'profile__step'}>
      <form onSubmit={e => e.preventDefault()}>
        <div className={'light_form_input_container'}>
          <div className={'select-container'}>
            <Select<SelectOption>
              label={strings.fieldLabelFirstRoleName}
              placeholder={strings.profileEdit.step.qualification.placeholder}
              value={typeValue()}
              defaultValue={typeValue()}
              options={options}
              onChange={(option: SelectOption) => updateProfile(FormFields.type, option.value)}
            />
          </div>
        </div>
        <div className={'light_form_inputs_container'}>
          <div>
            <Input
              label={strings.fieldLabelFirstNameRegister}
              placeholder={strings.fieldPlaceholderFirstName}
              value={tempUser!.first_name}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) dispatch(updateTempUser(FormFields.first_name, _.isEmpty(event.target.value) ? null : event.target.value))
                }}
              error={_.get(error, FormFields.first_name)}
            />
          </div>
          <div>
            <Input
              label={strings.fieldLabelLastNameRegister}
              placeholder={strings.fieldPlaceholderLastName}
              value={tempUser!.last_name}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) dispatch(updateTempUser(FormFields.last_name, _.isEmpty(event.target.value) ? null : event.target.value))
                }}
              error={_.get(error, FormFields.last_name)}
            />
          </div>
          <div>
            <Input
              label={strings.fieldLabelEmailRegister}
              placeholder={strings.fieldPlaceholderEmail}
              value={tempUser!.email}
              onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                if (event) dispatch(updateTempUser(FormFields.email, _.isEmpty(event.target.value) ? null : event.target.value))
              }}
              error={_.get(error, FormFields.email)}
            />
          </div>
          <div>
            <Input
              label={strings.fieldLabelPhone}
              placeholder={strings.fieldPlaceholderPhone}
              value={tempUser!.phone}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) dispatch(updateTempUser(FormFields.phone, _.isEmpty(event.target.value) ? null : event.target.value))
                }}
              error={_.get(error, FormFields.phone)}
            />
          </div>
        </div>
        <div className={'light_form_input_container'}>
          <InputAutosuggest
            label={strings.fieldLabelCity}
            placeholder={strings.profileEdit.step.lawfirm.input.city.placeholder}
            suggestions={citiesResponse || []}
            onChange={(v) => {
              setLawfirmCity({ query: v, object: undefined })
            }}
            onSelect={(data) => setLawfirmCity({ query: data.name, object: data.value })}
            value={lawfirmCity.query}
            onFetch={citiesRequest}
            error={_.get(_.get(_.get(error, FormFields.profile), FormFields.lawfirm), FormFields.city)}
          />
        </div>
        <div className={'light_form_input_container'}>
          <div className={'checkbox-container'}>
            <div className={'checkbox-content'}>
              <div
                className={classNames('check', { checked: tempUser!.terms, outline: oulineCheckboxTerms })}
                onClick={() => toggleTerms()}
              >
                <input
                  defaultChecked={tempUser!.terms}
                  type={'checkbox'}
                  onFocus={() => setOutlineCheckboxTerms(true)}
                  onBlur={() => setOutlineCheckboxTerms(false)}
                />
                <span />
              </div>
              <a href={ExternalLinks.Terms} target={'_blank'} tabIndex={-1}
                onClick={() => toggleTerms()}>{strings.fieldLabelTerms}</a>
            </div>

            <div className={'checkbox-content'}>
              <div className={classNames('check', { checked: tempUser!.privacy, outline: oulineCheckboxPrivacy })}
                onClick={() => togglePrivacy()}>
                <input
                  defaultChecked={tempUser!.privacy}
                  type={'checkbox'}
                  onFocus={() => setOutlineCheckboxPrivacy(true)}
                  onBlur={() => setOutlineCheckboxPrivacy(false)}
                />
                <span />
              </div>
              <a href={ExternalLinks.Privacy} target={'_blank'} tabIndex={-1}
                onClick={() => togglePrivacy()}>{strings.fieldLabelPrivacy}</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default LightSignUp