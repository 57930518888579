export enum CardDeeplink {
    PROFILE = 'PROFILE',
    AGENDA = 'AGENDA',
    COURSES = 'COURSES',
    SUBSCRIPTIONS = 'SUBSCRIPTIONS'
}

export class DynamicCard {
    id?: number
    title?: string
    subtitle?: string
    cta?: string

    closable!: boolean
    deeplink?: string
    link?: string
    
    color_start!: string
    color_end!: string
}