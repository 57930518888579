import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import NotificationService from 'services/NotificationService';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationsReducer, resetNotificationsStore } from 'store/reducers/NotificationsReducer';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ResourceType } from 'values/values';
import { selectEvent, setIsFromNotifications } from 'store/reducers/AppReducer';
import { setCurrentEvent } from 'store/reducers/AgendaReducer';
import { ActivitiesContext, activityLink } from 'components/activity-request/ActivityRequest';
import { setActivitiesContext, setCurrentDemand, setCurrentCandidate } from 'store/reducers/ActivitiesReducer';
import { greyArrow } from 'image/icons';
import { Notification } from '../../store/types/Notification';
import Event from '../../store/types/Event';
import { IUserReducer } from 'store/reducers/UserReducer';
import arrow from 'image/icons/green-arrow-right.svg';
import { CardDeeplink } from 'store/types/DynamicCard';
import AnalyticsService, { AnalyticsScreens, AnalyticsEvents } from 'services/AnalyticsService';
declare var googletag: any

enum NotificationStatus {
  read = 'READ',
  unread = 'UNREAD'
}

const Notifications: React.FC = () => {

  let [, , unreadNotificationsRequest] = NotificationService.useUnreadNotificationsAPI()
  let [notificationsResponse, , notificationsRequest] = NotificationService.useNotificationsAPI()
  let [, , readallRequest] = NotificationService.useNotificationsReadallAPI()

  let [notificationPage, setNotificationPage] = useState<number>(1)
  const [isOpenNotificationsMenu, setIsOpenNotificationsMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let notifications = useSelector((state: NotificationsReducer) => state.notificationsReducer != undefined ? state.notificationsReducer.notifications : [])
  let unreadNotifications = useSelector((state: NotificationsReducer) => state.notificationsReducer.unreadNotifications)
  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

  const dispatch = useDispatch();
  let history = useHistory();




  useEffect(() => {
    getUnreadNotifications()
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    if (document.getElementById('div-gpt-ad-notifications') !== undefined) {
      script.innerHTML = "googletag.cmd.push(function() {googletag.display(`div-gpt-ad-notifications`); });"
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      //TODO ADV REFRESH
      googletag.cmd.push(function () { googletag.pubads().refresh(); })
      document.body.removeChild(script);
    }

  }, [isLoading])

  useEffect(() => {
    getUnreadNotifications()
  }, [window.location.pathname]);

  let getUnreadNotifications = () => {
    setTimeout(() => {
      unreadNotificationsRequest()
    }, 0);
  }

  useEffect(() => {
    setIsLoading(false)
    if (!notificationsResponse) return
  }, [notificationsResponse]);


  let askForPage = async (page: number, reset?: boolean) => {
    if (reset) {
      await dispatch(resetNotificationsStore())
    }
    await setNotificationPage(page)
    await notificationsRequest(page)

    readallRequest()
  }

  let loadMore = async () => {
    if (_.isEmpty(notificationsResponse)) return
    askForPage(notificationPage + 1)
  }

  const openNotificationsMenu = async () => {
    AnalyticsService.screenName(AnalyticsScreens.notification)

    setIsOpenNotificationsMenu(!isOpenNotificationsMenu);
    setIsLoading(true)

    askForPage(1, true)
  };

  const closeNotificationsMenu = () => {
    // AnalyticsService.screenName(AnalyticsScreens.notification)

    setIsOpenNotificationsMenu(false);
  };


  let openNotification = async (notification: Notification) => {
    setIsOpenNotificationsMenu(false);

    AnalyticsService.event(AnalyticsEvents.notificationOpen, { notification_id: notification.id })

    // AGENDA

    if (notification.link && notification.link.includes('/shipments/')) {
      window.open(notification.link, '_blank')

    } else if (notification.event) {
      closeNotificationsMenu()

      // Navigate
      if (window.location.pathname !== '/agenda') {
        await history.push('/agenda')
      }

      // Open panel
      let _event = new Event(notification.event)
      if (_event.resourcetype !== ResourceType.News) {
        dispatch(selectEvent());
        dispatch(setCurrentEvent(_event));

      } else {
        if (_event.news) {
          const win = window.open(_event.news.link, '_blank');
          if (win) {
            win.focus();
          }
        }
      }

      // RECORDS
    } else if (notification.record) {
      closeNotificationsMenu()

      window.open(notification.record.link + '/' + notification.record.code, '_blank');

      //// DEMAND
    } else if (notification.demand) {
      let owner = notification.demand.user
      let context = owner!.id === currentUser!.id ? ActivitiesContext.demands : ActivitiesContext.managements

      // Navigate
      if (window.location.pathname !== activityLink(context)) {
        await history.push(activityLink(context))
      }

      await dispatch(setIsFromNotifications(true))
      await dispatch(setActivitiesContext(context))
      await dispatch(setCurrentDemand(notification.demand))

      //// CANDIDATE
    } else if (notification.candidate) {
      let context = ActivitiesContext.candidates

      // Navigate
      if (window.location.pathname !== activityLink(context)) {
        await history.push(activityLink(context))
      }

      await dispatch(setIsFromNotifications(true))
      await dispatch(setActivitiesContext(context))
      await dispatch(setCurrentDemand(notification.candidate.demand))
      await dispatch(setCurrentCandidate(notification.candidate))

      //// LINK
    } else if (notification.link) {
      window.open(notification.link, '_blank')

      //// DEEPLINK
    } else if (notification.deeplink) {
      if (notification.deeplink === CardDeeplink.COURSES) history.push('/courses')
      if (notification.deeplink === CardDeeplink.PROFILE) history.push('/profile')
      if (notification.deeplink === CardDeeplink.AGENDA) history.push('/agenda')
      if (notification.deeplink === CardDeeplink.SUBSCRIPTIONS) history.push('/subscriptions')
    }
  }

  let handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) loadMore()
  }

  return (
    <div className={unreadNotifications > 0 ? 'cta-notifications unread' : 'cta-notifications'} onClick={openNotificationsMenu} >
      {unreadNotifications > 0 && <div className={'notification_badge'}>
        <span className={'notification_text'}>
          {unreadNotifications}
        </span>
      </div>}
      {isOpenNotificationsMenu && (
        <OutsideClickHandler onOutsideClick={() => closeNotificationsMenu()}>
          <div className={'menu-notifications'}>

            <div className={'menu-notifications-wrapper'} onScroll={handleScroll} >
              <h2>Notifiche</h2>

              <div id={'div-gpt-ad-notifications'} style={{ width: '100%', maxHeight: 150 }} />

              {isLoading &&
                <div className={'loader_empty_state_container'}>
                  <div className={'loader_loading'} />
                </div>}

              {!isLoading && _.isEmpty(notifications) &&
                <div className={'loader_empty_state_container'}>
                  <span className={'empty_state_text'}>Non ci sono notifiche!</span>
                </div>}

              {notifications && !_.isEmpty(notifications) && notifications.map((notification: Notification, index) => {
                return (
                  <Fragment key={index.toString()}>
                    <div key={index} className={notification.status === NotificationStatus.unread ? 'container unread' : 'container'} onClick={() => openNotification(notification)}>
                      <div className={'sub_container'}>
                        <div className={'notification_item_container'} >
                          <div className={'img__container'}>
                            <img src={notification.image} alt={'oval'} />
                          </div>
                          <div className={'text__container'}>
                            <span className={'date'}>{moment(notification.created_at).calendar()}</span>
                            <span className={notification.status === NotificationStatus.unread ? 'unread' : 'read'}>{notification.title}</span>
                            {notification.record && <span className={'record'}>{notification.record}</span>}
                            {notification.event && <span className={'event'}>{notification.event}</span>}
                            {notification.subtitle && <span className={'subtitle'}>{notification.subtitle}</span>}
                          </div>
                        </div>
                        <img src={notification.status === NotificationStatus.unread ? arrow : greyArrow} style={{ cursor: 'pointer' }} alt={'arrow'} />
                      </div>
                    </div>
                    <div className={'divider'}></div>
                  </Fragment>
                )
              })}
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default Notifications