import React from "react";
import paypalIcon from "../../image/icons/paypal.svg";
import { classNames } from "react-select/src/utils";

interface PaymentButtonProps {
  text: string;
  onClick: () => void;
  withIcon?: boolean;
  icon?: string;
  fgColor?: string
  bgColor?: string
  extraClass?: string
  disabled?: boolean
}

const PaymentButton: React.FC<PaymentButtonProps> = ({
  fgColor,
  bgColor,
  text,
  onClick,
  withIcon = false,
  extraClass,
  disabled = false
}) => {
  return (
    <button disabled={disabled} style={{ opacity: disabled == true ? 0.4 : 1 }} className={`payment-button ${extraClass}`} onClick={onClick}>
      {withIcon && <img src={paypalIcon} alt="payment icon" />}
      {text}
    </button>
  );
};

export default PaymentButton;
