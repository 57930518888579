import React, { FC } from 'react'
import plusIcon from '../../image/plus-icon.svg'
import strings from 'values/strings'
import goal from '../../image/goal-icon.svg'
import { SubscriptionInfoType } from 'values/values'

type EmptyAdditionalSkillBannerProps = {

  action: () => void
}

const EmptyAdditionalSkillBanner: FC<EmptyAdditionalSkillBannerProps> = ({ action }) => {
  return (
    <div className="banner-container" style={{ cursor: 'pointer' }} onClick={action}>

      <div className="banner-content">
        <img src={goal} alt="goal-icon" height={32} />
        <div className="banner-title">{'Acquista Competenze ulteriori'}</div>
        <div className="banner-subtitle">{'Acquista competenze ulteriori\nda associare al tuo profilo.'}</div>
      </div>

      <button className="banner-button">
        <img src={plusIcon} alt="plus-icon" />
      </button>
    </div>
  )
}

export default EmptyAdditionalSkillBanner