import React from 'react';
import imagePreview from '../../image/preview-verbal.svg';
import { Record } from '../../store/types/Record';
import moment from 'moment';
import { DateFormat } from '../../values/values';
import strings from '../../values/strings';
import AnalyticsService, { AnalyticsEvents } from 'services/AnalyticsService';

interface IRecordDetail {
  record: Record;
}

const RecordDetail: React.FC<IRecordDetail> = ({ record }) => {
  return (
    <>
      <div className={'previewVerbal'}>
        <img src={imagePreview} alt="verbale" />
        <p className={'title'}>{moment(record.created_at).format(DateFormat.full)}</p>
        <p className={'subtitle'}>{strings.records.add.share}</p>
        <p className={'caption'}>{strings.records.add.link}</p>
        <a href={record.link}>{record.link}</a>
        <p className={'caption'}>{strings.records.add.accessCode}</p>
        <p className={'code'}>{record.code}</p>
        <form target={'_blank'} method={'get'} action={`${record.link}/${record.code}`}>
          <button className={'button-primary'} onClick={() => AnalyticsService.event(AnalyticsEvents.recordOpen, { record_id: record.id })}>
            <span>{strings.records.add.go}</span>
          </button>
        </form>
      </div>
    </>
  );
};

export default RecordDetail;
