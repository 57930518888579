import {User} from './User';
import Office from './Office';
import {Resource} from './Resource';
import {EventStatus} from '../../values/values';
import {TypedSerializer} from 'ts-typed';

export class LawyerEvent {
  user!: User;


  status?: string;
  hearing?: Resource;
  appointment?: Resource;
  fulfillment?: Resource;

  private _part1?: any;
  private _part2?: any;
  private _activity?: any;
  private _judge?: any;
  private _office?: any;

  get part1(): string | null {
    return this._part1;
  }

  set part1(value: string | null) {
    this._part1 = value;
  }

  get part2(): string | null {
    return this._part2;
  }

  set part2(value: string | null) {
    this._part2 = value;
  }

  get activity(): string | null {
    return this._activity;
  }

  set activity(value: string | null) {
    this._activity = value;
  }

  get judge(): string | null {
    return this._judge;
  }

  set judge(value: string | null) {
    this._judge = value;
  }

  get office(): Office | null {
    return this._office;
  }

  set office(value: Office | null) {
    this._office = value;
  }

  constructor(object: Partial<LawyerEvent>) {
    // this.user = new User();
    this.status = EventStatus.TODO;

    const {office, hearing, appointment, fulfillment, ...layerEventParam} = object;
    Object.assign(this, layerEventParam);
    this.hearing = hearing ? new Resource(hearing) : undefined;
    this.appointment = appointment ? new Resource(appointment) : undefined;
    this.fulfillment = fulfillment ? new Resource(fulfillment) : undefined;
    this.office = office ? new Office(office) : null;
  }

  /**
   * toString equivalent, allows you to remove the _ prefix from props.
   *
   */
  toJSON(): LawyerEvent {
    return TypedSerializer.serialize(this);
  }
}
