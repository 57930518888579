import React from "react";

interface SubscriptionPlanRadioButtonProps {
  id: string;
  title: string;
  subtitle: string;
  originalPrice?: string;
  discountedPrice?: string;
  isActive: boolean;
  onClick: () => void;
}

const SubscriptionPlanRadioButton: React.FC<
  SubscriptionPlanRadioButtonProps
> = ({
  id,
  title,
  subtitle,
  originalPrice,
  discountedPrice,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={`radio-button ${isActive ? "radio-button--active" : ""}`}
      onClick={onClick}
    >
      <div className="radio-button__icon">
        {isActive ? (
          <span className="radio-button__checked-icon">&#10003;</span>
        ) : (
          <span className="radio-button__circle-icon" />
        )}
      </div>
      <div className="radio-button__content">
        <div className="radio-button__title">{title}</div>
        <div className="radio-button__subtitle">{subtitle}</div>
      </div>
      <div className="radio-button__prices">
        {originalPrice && (
          <div className="radio-button__original-price">{originalPrice}</div>
        )}
        <div className="radio-button__discounted-price">{discountedPrice}</div>
      </div>
    </div>
  );
};

export default SubscriptionPlanRadioButton;
