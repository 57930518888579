import React, {Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {setError500} from '../store/reducers/AppReducer';
import strings from 'values/strings';

const GlobalErrorModal: React.FC = () => {

  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className={'modal opened'}>
        <div className={'modal__content'}>
          <p className={'title'}>{strings.globalError.modal.title}</p>
          <p className={'copy'}>{strings.globalError.modal.copy}</p>
          <button className={'button-primary'} onClick={() => {dispatch(setError500(false));}}>
            <span>{strings.globalError.modal.cta}</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default GlobalErrorModal;
