import React, { useEffect } from 'react';
import Storage from '../store/Storage';
import logo from '../image/logo-collega.svg';
import strings from 'values/strings';
import { RouteComponentProps, Router } from 'react-router-dom';
import queryString from 'query-string';
import UserService from 'services/UserServices';
import _ from 'lodash';


interface RouterProps {
  token: string
}

interface Props extends RouteComponentProps<RouterProps> { }

const SSO: React.FC<Props> = ({ match, history }) => {

  const [userResponse, userError, userRequest] = UserService.useUserAPI();


  useEffect(() => {
    if (userResponse) {
      let route: any = _.get(queryString.parse(history.location.search), 'callback')
      history.push(`/${route}`)
    }
  }, [userResponse])

  let setUser = async (token: string) => {
    await Storage.set(Storage.AUTH_TOKEN, token)
    userRequest()
  }

  useEffect(() => {
    setUser(match.params.token)
  }, []);


  return (
    <div className={'loading-wrapper'}>
      <div className={'logo-container'}>
        <img className={'logo'} src={logo} alt="Logo collega" />
      </div>
      <h1>{strings.loading}</h1>
      <div className={'loader'} />
    </div>
  );
};

export default SSO;
