import { useState } from "react";
import Network, { NetworkMethod } from "network/Network";
import { useDispatch } from "react-redux";
import { setIsLoading } from "store/reducers/AppReducer";
import { ExternalLinks } from "values/values";
import { FormData as FRMDATA } from "components/subscription/collega/AccountConfirm";
import moment from "moment";
import Storage from "store/Storage";
import axios from "axios";
import _ from "lodash";


class SubscriptionService {


  static oAuthAPI = () => {
    const PAYPAL_CLIENT_ID = 'ARmGgW6I6aeGLR0rQJSZChK0VpdNrh-myeY3swjnVjScACKPFDO76ni_B-Z0YqZ_KVT4d2EAZRo9ss3j'
    const PAYPAL_CLIENT_SECRET = 'EGGoNTJ4Xis4HvDYyxiN5pQ1r607SjUXW8vbkTEXQj_nml3wP6OARs3xZXKpqV7QXtU7p3W_SqC9hWK2'

    const auth = Buffer.from(
      PAYPAL_CLIENT_ID + ":" + PAYPAL_CLIENT_SECRET,
    ).toString("base64");

    const url = "https://api.paypal.com/v1/oauth2/token";
    let method = NetworkMethod.post
    let formData = new URLSearchParams()
    formData.append('grant_type', "client_credentials")
    let options: any = {
      method: method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Authorization": `Basic ${auth}`
      },
      data: formData,
      url
    };
    axios(options).then(res => {
      Storage.set(Storage.PAYPAL_OAUTH_TOKEN, res.data.access_token)
    })
      .catch(er => {
        console.log("Error:", er);
      });
  };

  static useStripePaymentIntentStatusAPI = (): [any, any, (paymentIntentId: number) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();


    let request = async (paymentIntentId: number) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.get
      let endpoint = `stripe/payment-intents/${paymentIntentId}/`

      // return
      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false));

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useStripePaymentIntentAPI = (): [any, any, (payment_method: string, subscription_id: number, plan_id: number, variant_id?: number, discount_code?: string, city_id?: number, SDI?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();


    let request = async (payment_method: string, subscription_id: number, plan_id: number, variant_id?: number, discount_code?: string, city_id?: number, SDI?: string) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.post
      let endpoint = 'stripe/create/'


      var data: any = {
        "payment_method_id": payment_method,
        "payment_type_id": subscription_id,
        "plan_id": plan_id,
        "payment_method": "credit_card" // opzioni: [paypal, credit_card, bank_transfer]
      }

      if (variant_id) {
        data["payment_variant_id"] = variant_id
      }

      if (discount_code && discount_code !== 'undefined') {
        data["code"] = discount_code
      }

      if (SDI != undefined && SDI !== 'undefined' && !_.isEmpty(SDI)) {
        data["sdi"] = SDI
      } else {
        data["sdi"] = '0000000'
      }

      if (city_id) {
        data["city_id"] = city_id
      }

      // return
      let response = await Network.fetchAPI(method, endpoint, true, data, null)
      dispatch(setIsLoading(false));

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  static usePPSetupTokenAPI = (): [any, any, (auth_token: string, subscription_id: number, plan_id: number, payment_method: string, variant_id?: number, discount_code?: string, city_id?: number, SDI?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();


    let request = async (auth_token: string, subscription_id: number, plan_id: number, payment_method: string, variant_id?: number, discount_code?: string, city_id?: number, SDI?: string) => {
      dispatch(setIsLoading(true));

      let oAuthToken = await Storage.get(Storage.PAYPAL_OAUTH_TOKEN)

      let method = NetworkMethod.post
      let endpoint = 'https://api.paypal.com/v3/vault/setup-tokens'
      let data = JSON.stringify({
        "payment_source": {
          "paypal": {
            "experience_context": {
              "shipping_preference": "SET_PROVIDED_ADDRESS",
              "payment_method_preference": "IMMEDIATE_PAYMENT_REQUIRED",
              "brand_name": "Collega",
              "locale": "it-IT",
              "return_url": `${ExternalLinks.AppAddress}/subscription-activate?auth_token=${auth_token}&subscription_id=${subscription_id}&plan_id=${plan_id}&payment_method=${payment_method}&variant_id=${variant_id}&discount_code=${discount_code}&city_id=${city_id}&sdi=${SDI}`,
              "cancel_url": `${ExternalLinks.AppAddress}/subscription-activate`
            },
            "description": "MERCHANT",
            "usage_pattern": "IMMEDIATE",
            "usage_type": "MERCHANT",
            "customer_type": "CONSUMER"
          }
        }
      });

      let options: any = {
        method: method,
        headers: {
          'Content-type': 'application/json',
          "Client-App": "collega-web",
          "Authorization": `Bearer ${oAuthToken}`
        },
        data: data,
        url: endpoint
      };

      await axios(options).then((response) => {
        dispatch(setIsLoading(false));
        if (response.data) {
          setResponse(response.data)
        }
      })
        .catch((er) => {
          dispatch(setIsLoading(false));
          console.log("ERROR", er)
          setError(er)
        })
    }

    return [response, error, request]
  }


  static useActivateSubscriptionAPI = (): [any, any, (subscription_id?: number, token?: string, plan_id?: number, payment_method?: string, variant_id?: number, discount_code?: string, city_id?: number, ccFormData?: any, SDI?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();


    let request = async (subscription_id?: number, token?: string, plan_id?: number, payment_method?: string, variant_id?: number, discount_code?: string, city_id?: number, ccFormData?: any, SDI?: string) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.post
      let endpoint = `payment-types/${subscription_id}/activate/`


      var data: any = {
        "plan_id": plan_id, // id piano required
        "payment_method": payment_method // opzioni: [paypal, credit_card, bank_transfer]
      }

      if (token) {
        data["setup_token"] = token
      }
      if (ccFormData) {
        let _ccFormData = JSON.parse(ccFormData)
        console.log("_ccFormData", _ccFormData)

        data["payment_details"] = { // required quando si paga con carta di credito
          "card": {
            "number": _ccFormData['cardNumber'],
            "expiry": moment(_ccFormData['expirationDate'], 'MM/YY').format('YYYY-MM'),
            "name": _ccFormData['ccname'],
            "billing_address": { // opzionale
              "address_line_1": _ccFormData['address1'], // indirizzo 1
              "address_line_2": _ccFormData['address2'], // indirizzo 2
              "admin_area_1": _ccFormData['province'].code, // provincia abbreviata
              "admin_area_2": _ccFormData['province'].name, // provincia completa
              "postal_code": _ccFormData['postalCode'], // codice postale
              "country_code": _ccFormData['country_code'] // paese abbreviato
            }
          }
        }
      }

      if (variant_id) {
        data["payment_variant_id"] = variant_id
      }

      if (discount_code && discount_code !== 'undefined') {
        data["code"] = discount_code
      }

      if (SDI != undefined && SDI !== 'undefined' && !_.isEmpty(SDI)) {
        data["sdi"] = SDI
      } else {
        data["sdi"] = '0000000'
      }

      if (city_id) {
        data["city_id"] = city_id
      }
      // return
      let response = await Network.fetchAPI(method, endpoint, true, data, null)
      dispatch(setIsLoading(false));

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  static useConfirmAccountAPI = (): [any, any, (formData: FRMDATA) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const dispatch = useDispatch();


    let request = async (formData: FRMDATA) => {
      dispatch(setIsLoading(true));

      let method = NetworkMethod.post
      let endpoint = `payment-variants/professional-form/`

      var data: any = formData

      let response = await Network.fetchAPI(method, endpoint, true, data, null)
      dispatch(setIsLoading(false));

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


}

export default SubscriptionService