import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUserReducer, updateTempUser } from '../../store/reducers/UserReducer';
import strings from '../../values/strings';
import { FormFields } from '../../values/values';
import { IStepThird } from './interface/SignupInterface';
import InputAutosuggest from '../../components/common/AutoSuggest';
import UserService from '../../services/UserServices';
import SelectOption from '../../store/types/SelectionOption';
import { LegalOffice } from 'store/types/Profile';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';

const StepThird: React.FC<IStepThird> = ({ city, next }) => {


  const [lawfirmCity, setLawfirmCity] = useState<any>({
    query: '',
    object: undefined
  })

  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();

  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
  const dispatch = useDispatch();


  useEffect(() => {
    if (tempUser) {
      updateLegalOffice(FormFields.city, lawfirmCity.object)
    }
  }, [lawfirmCity])


  

  let updateProfile = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile,
      'skills': Array<any>(),
      [field]: value
    }

    await dispatch(updateTempUser(FormFields.profile, data))
  }

  let updateLegalOffice = async (field: string, value: any) => {
    if (!tempUser!.profile.lawfirm) updateProfile(FormFields.lawfirm, new LegalOffice())

    let data = {
      ...tempUser!.profile.lawfirm,
      name: null,
      [field]: value
    }

    await updateProfile(FormFields.lawfirm, data)
  }


  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.register3)
  }, [])


  return (
    <form onSubmit={preventSubmit(next)}>
      <div className={'title_container'}>
        <h1 className={'title'}>{strings.signUp.headerTitle}</h1>
        <a className={'help'} href={'mailto:' + strings.navMenu.support_email.address + '?subject=' + strings.signUp.help_subject} target={'_blank'}>{strings.signUp.help}</a>
      </div>
      <InputAutosuggest
        label={strings.fieldLabelCity}
        placeholder={strings.signUp.fifth.cityPlaceholder}
        suggestions={citiesResponse || []}
        autoFocus={true}
        onChange={(v) => setLawfirmCity({ query: v, object: undefined })}
        onSelect={(data) => setLawfirmCity({ query: data.name, object: data.value })}
        value={lawfirmCity.query}
        onFetch={citiesRequest} />

      <button type={'submit'} className={'button-placeholder-submit'} tabIndex={-1} />
    </form>
  );
};

export default StepThird;
