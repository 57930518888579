import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';

const StepWelcome: React.FC = () => {

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.registerDone)
  }, [])
  
  return (
    <form>
      <h1>Fantastico!</h1>
      <div className={'gdpr welcome'}>
        <p className={'copy'}>
        Il tuo account è stato creato correttamente. Ora puoi accedere a Collega ed utilizzare le sue innovative funzionalità.
        </p>
        <Link to={'signin'} style={{marginTop: 21, alignSelf: 'flex-start'}} className={'button-primary'}>
          <span>Accedi a Collega</span>
        </Link>
      </div>
    </form>
  );
};
export default StepWelcome;
