import React from 'react';
import { Item } from 'store/types/Item';
import { ReactComponent as Credits } from 'image/icons/credits.svg';
import { ReactComponent as Price } from 'image/icons/price.svg';
import strings from 'values/strings';
import { SidePanelReducer } from 'store/reducers/SidePanelReducer';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Utility from 'utils/utils';

interface Props {
  obj: Item
  history?: any
}

let CourseItem: React.FC<Props> = React.memo(({ obj, history }) => {

  const { category, course, last_purchased } = obj

  const currentCourse = useSelector((state: SidePanelReducer) => state.sidePanelReducer.currentCourse);

  let purchased = _.isEmpty(last_purchased)

  return (
    <div className={currentCourse && currentCourse.id === obj.id ? 'courses__card selected' : 'courses__card'}
    >
      <div className="text" onClick={() => history.push(`/courses/${obj.id}`)}>

        <p className="title">{obj.name}</p>
        <p className="content">{obj.short_description && obj.short_description.length > 180 ? obj.short_description.substring(0, 180) + '...' : obj.short_description}</p>

        <div className={'info__container'}>

          {course && course.cfp && <div className={'info__item'}>
            <Credits fill={category && category.background_color} />
            <p className="info">{strings.courses.credits} {course.cfp}</p>
          </div >}

          <div className={'info__item'}>
            <Price className={'info_price'} fill={category && category.background_color} />
            {obj.cost
              ? <p className="info">{strings.courses.price} {Utility.currency(obj.cost)}</p>
              : obj.package_available
                ? <p className="info">{'Disponibile nel pacchetto acquistato'}</p>
                : <p className="info">{strings.courses.price} {strings.courses.free}</p>
            }
          </div>

        </div>

        {!purchased && <p className={'label purchased'}>{strings.courses.purchasedTicket}</p>}
      </div>
      <div className={'rightBorder'} />
    </div>
  );
});

export default CourseItem;

