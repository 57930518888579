import React, {MouseEvent} from 'react';
import ReactSelect from 'react-select';
import {arrowSelect, basket, add} from '../../image/icons';
import classNames from 'classnames';

interface ISelectProps<TSelect> {
  label?: string;
  defaultValue?: TSelect;
  value?: TSelect;
  options: TSelect[];
  noOptionsMessage?: string
  onChange: (opt: TSelect) => void;
  error?: string;
  cancelable?: boolean;
  onCancel?: (event?: MouseEvent<HTMLButtonElement>) => any;
  autoFocus?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  readonly?: boolean;
  disabled?: boolean;
}

const Select = <TSelect extends {}>({label, defaultValue, value, options, onChange, onCancel, cancelable, error, autoFocus, placeholder, isSearchable= false,readonly, noOptionsMessage}: ISelectProps<TSelect>) => {
  return (
    <>
      {label && (<label>{label}</label>)}
      <div
        className={classNames('date-container', {'input-error': error, cancelable})}>
        <ReactSelect<TSelect>
          defaultValue={defaultValue}
          value={value}
          autoFocus={autoFocus}
          options={options}
          onChange={(opt: any) => onChange(opt)}
          noOptionsMessage={() => noOptionsMessage && noOptionsMessage != undefined ? noOptionsMessage : 'In questo momento non ci sono informazioni.'}
          components={{
            DropdownIndicator: () => {
              return <div className={'collega-select__caret'}>
                <img src={arrowSelect} alt={'seleziona'} />
              </div>;
            }
          }}
          isSearchable={isSearchable}
          isDisabled={readonly}
          placeholder={placeholder}
          className={'collega-select-container'}
          classNamePrefix={'collega-select'}
        />
      </div>
      {cancelable &&
      <button className={'input-trash'} onClick={onCancel}
              tabIndex={-1}><img src={basket} alt="basket"/></button>}
      {error && <p className={'error'}>{error}</p>}
    </>
  );
};

interface ICancelableFieldProps extends ISelectProps<any> {
  showField: boolean;
  labelAdd: string;
  onCancel: (event?: MouseEvent<HTMLButtonElement>) => any;
  handleShow: (event?: MouseEvent<HTMLButtonElement>) => any;
}

const CancelableSelectField: React.FC<ICancelableFieldProps> =
  ({
     showField,
     labelAdd,
     handleShow,
     ...rest
   }) => {
    return (
      <div className={'form__full'}>
        {showField && (
          <Select
            {...rest}
            cancelable={true}
            autoFocus={true}
          />
        )}
        {!showField && (
          <button className={'button-secondary icon-right'}
                  onClick={handleShow}>
            <span className={'txt'}>{labelAdd}</span>
            <span className={'icon'}><img src={add} alt="add"/></span>
          </button>
        )}
      </div>
    );
  };

export {CancelableSelectField};

export default Select;
