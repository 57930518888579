import React, { useState, useEffect } from 'react';
import Input from 'components/common/Input';
import { User } from 'store/types/User';
import UserService from 'services/UserServices';
import InputAutosuggest from '../common/AutoSuggest'
import { useHistory } from 'react-router-dom';
import SelectOption from 'store/types/SelectionOption';
import classNames from 'classnames';
import strings from 'values/strings';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
var _ = require('lodash');

interface Props {
  currentUser: User;
}


const ProfileLocations: React.FC<Props> = ({ currentUser }) => {

  const [tempValue, setTempValue] = useState<any>({
    query: '',
    object: undefined
  });

  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
  let [updateResponse, updateError, updateRequest] = UserService.useUpdateUserAPI()

  let history = useHistory()


  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.profileCity2)
  }, [])
  
  useEffect(() => {
    if (updateResponse) history.replace('/profile')
  }, [updateResponse])


  let onSave = async () => {
    let user: User = currentUser!
    user.profile.lawfirm.city2 = tempValue.object

    updateRequest(user)
  }


  return (
    <div className={'profile__edit__container'}>
      <h2>{strings.profileLocations.title}</h2>
      <div className={'divider'}></div>

      <div className={'__edit__container'}>
        <div className={'profile__steps'}>
          <div className={'profile__step'}>
            <h3><span>{strings.profileLocations.subtitle}</span>{strings.profileLocations.subtitle2}</h3>
            <form>

              <div>
                <Input
                  label={strings.profileLocations.input.city}
                  placeholder={''}
                  value={currentUser!.profile.city!.name}
                  disabled={true}
                  error={''} />
              </div>

              <div>
                <InputAutosuggest
                  label={strings.profileLocations.input.city2.label}
                  placeholder={strings.profileLocations.input.city2.placeholder}
                  suggestions={citiesResponse || []}
                  onChange={(v) => setTempValue({ query: v, object: undefined })}
                  onSelect={(data) => setTempValue({ query: data.name, object: data.value })}
                  value={tempValue.query}
                  onFetch={citiesRequest} />
              </div>

            </form>
          </div>
        </div>
        <div className={'button__next'}>
          <button className={classNames('button-primary', { disabled: tempValue.object === undefined })} onClick={onSave}>{strings.profileLocations.cta}</button>
        </div>
      </div>
    </div>
  )
}

export default ProfileLocations;