import React, { useEffect, useState, Fragment } from 'react';
import 'moment/locale/it'
import { NewsCategory } from 'store/types/News';
import moment from 'moment';
import { DateFormat } from 'values/values';
import NewsService from 'services/NewsService';
import classNames from 'classnames';
import searchIcon from 'image/icons/news-search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IAgendaReducer, setSearchVisibility } from 'store/reducers/AgendaReducer';
import Search from 'components/search/Search';
var _ = require('lodash');

export enum NewsRanges {
  all = 'Tutte', today = 'Oggi', yesterday = 'Ieri', week = 'Ultima settimana'
}

interface Props {
  onChangeFilter: (category?: NewsCategory, range?: any, query?: string) => void
}

let NewsFilter: React.FC<Props> = React.memo((props) => {

  let [categoriesResponse, , categoriesRequest] = NewsService.useNewsCategoriesAPI()

  let [categories, setCategories] = useState<Array<NewsCategory>>()

  let [selectedCategory, setSelectedCategory] = useState<NewsCategory>()
  let [selectedRange, setSelectedRange] = useState(NewsRanges.all)
  let [query, setQuery] = useState<string>()

  const dispatch = useDispatch();

  const isVisibleSearch = useSelector((state: IAgendaReducer) => state.agenda.isVisibleSearch);

  useEffect(() => {
    if (!categories) categoriesRequest()
    setSelectedRange(NewsRanges.all)
  }, [])

  useEffect(() => {
    setCategories(categoriesResponse)
  }, [categoriesResponse])

  useEffect(() => {
    props.onChangeFilter(selectedCategory, parseRange(selectedRange), query)
  }, [selectedCategory, selectedRange, query])

  useEffect(() => {
    if (!isVisibleSearch && query) setQuery(undefined)
  }, [isVisibleSearch])

  let isSelectedRange = (range: string) => {
    return range === selectedRange
  }

  let parseRange = (range: string) => {
    switch (range) {
      case NewsRanges.all: return { min: moment().add(-1, 'years').format(DateFormat.server), max: undefined }
      case NewsRanges.week: return { min: moment().add(-1, 'weeks').format(DateFormat.server), max: undefined }
      case NewsRanges.yesterday: return { min: moment().add(-1, 'days').format(DateFormat.server), max: moment().add(-1, 'days').format(DateFormat.server) }
      case NewsRanges.today: return { min: moment().format(DateFormat.server), max: undefined }
      default: return ''
    }
  }

  let temporalFilter = () => {
    return (
      <div className={'news__filter'}>
        {_.values(NewsRanges).map((item: any) => {
          return (
            <button
              className={classNames('button-filter date', { active: isSelectedRange(item) })}
              onClick={() => setSelectedRange(item)}>
              {item}
            </button>
          )
        })}
      </div>
    )
  }

  let isSelectedCategory = (category: NewsCategory) => {
    if (selectedCategory === undefined) return false
    return category.id === selectedCategory.id
  }

  let categoryFilter = () => {
    return (
      <div className={'news__category__container'}>
        {categories && categories.map((category: NewsCategory) => {
          return (
            <div
              className={classNames('news__category', { active: isSelectedCategory(category) })}
              onClick={() => setSelectedCategory(isSelectedCategory(category) ? undefined : category)} >
              <span>{category.name}</span>
            </div>
          )
        })}
      </div>
    )
  }

  let searchFilter = () => {
    return (
      <Fragment>
        <div className={classNames('news__header__search', { visible: isVisibleSearch })}>
          {isVisibleSearch && <Search callback={setQuery} />}
        </div>
        <img src={searchIcon} onClick={() => dispatch(setSearchVisibility(true))} style={{ cursor: 'pointer' }} alt={'search'}/>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={'news__category__search__container'}>
        {categoryFilter()}
        {searchFilter()}
      </div>
      {temporalFilter()}
    </Fragment>
  );
});

export default NewsFilter;

