import React, { useState, Fragment, useEffect } from 'react';
import close from 'image/icons/dashboard/close-white.svg';
import add from 'image/icons/dashboard/add-white.svg';
import collega from 'image/icons/dashboard/collega-card.svg';
import collega_g from 'image/icons/nav/collega_g.svg';
import arrowW from 'image/icons/dashboard/right-white.svg';
import profile from 'image/icons/profile-placeholder.svg'
import arrowG from 'image/icons/green-arrow-right.svg';
import request from 'image/icons/nav/activity-request-icon.svg';
import proposal from 'image/icons/nav/activity-proposal-icon.svg';
import assign from 'image/icons/nav/activity-assignments-icon.svg';
import requestW from 'image/icons/dashboard/activity-request-white-icon.svg';
import proposalW from 'image/icons/dashboard/activity-proposal-white-icon.svg';
import assignW from 'image/icons/dashboard/activity-assignments-white-icon.svg';

import { ActivitiesContext, activityLink } from 'components/activity-request/ActivityRequest';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import DashboardService from 'services/DashboardService';
import NewsItem from 'components/news/NewsItem';
import _ from 'lodash';
import { DynamicCard, CardDeeplink } from 'store/types/DynamicCard';
import classNames from 'classnames';
import CommitmentEvent from 'components/commitment/CommitmentEvent';
import Event from '../../store/types/Event';
import { Demand, Candidate } from 'store/types/Demand';
import { useSelector } from 'react-redux';
import { ActivitiesReducer, setCurrentDemand, setCurrentCandidate, setCurrentCandidateUser, setShouldPerformRequest } from 'store/reducers/ActivitiesReducer';
import { useDispatch } from 'react-redux';
import AgendaDetail from 'components/commitment/CommitmentRightDetail';
import ActivityRightPanel from 'components/activity-request/ActivityRightPanel';
import { tabThirdClose, tabActivityClose } from 'store/reducers/AppReducer';
import { setCurrentEvent } from 'store/reducers/AgendaReducer';
import strings from 'values/strings';
import ActivityRightProfile from 'components/activity-request/ActivityRightProfile';
import AnalyticsService, { AnalyticsScreens, AnalyticsEvents } from 'services/AnalyticsService';
import { Counters } from 'store/types/Counters';
import { ExternalLinks, SubscriptionInfoType } from 'values/values';
import UpdatesModal, { Step } from 'components/updates-modal/UpdatesModal';
import { User } from 'store/types/User';
import EmptySpecificSkillBanner from 'components/profile/EmptySpecificSkillBanner';
import newUserProfile from 'image/update-modal-1.svg'
import genericSkills from 'image/update-modal-2.svg'
import specificSkills from 'image/update-modal-3.svg'
import { Subscription } from 'store/types/Subscriptions';
import SubscriptionDoneModal from 'components/request-activities/modal/SubscriptionDoneModal';
import UserService from 'services/UserServices';
import EmptyProfessionalBanner from 'components/profile/EmptyProfessionalBanner';
import EmptyAdditionalSkillBanner from 'components/profile/EmptyAdditionalSkillBanner';
import EmptyGoldBanner from 'components/profile/EmptyGoldBanner';

declare var googletag: any

type DashboardProps = {
  currentUser: User | undefined
}

const Dashboard: React.FC<DashboardProps> = ({ currentUser }) => {

  let dispatch = useDispatch()
  let history = useHistory()
  let location = useLocation()

  let shouldPerformRequest = useSelector((state: ActivitiesReducer) => state.activitiesReducer.shouldPerformRequest)

  const mainSkills = currentUser && currentUser.profile.skills.filter(skill => skill.is_main === true)
  let [isLoading, setIsLoading] = useState(true);
  let [isNewUser, setIsNewUser] = useState<boolean | undefined>()

  let [dashboardResponse, , dashboardRequest] = DashboardService.useDashboardAPI()
  let [, , deleteCardRequest] = DashboardService.useDeleteCardAPI()
  const [response, , markPopupAsSeen] = DashboardService.useMarkPopupAsSeenApi();
  const [hasSeenPopup, , checkIfSeen] = DashboardService.useHasSeenPopupApi();
  const [subscriptionInfoResponse, subscriptionInfoError, subscriptionInfoRequest] = UserService.useSubscriptionsInfoAPI()

  let [dashboardSkillsCard, setDashboardSkillsCard] = useState<boolean>(false)
  let [dashboardGoldCard, setDashboardGoldCard] = useState<boolean>(false)
  let [dashboardAdditionalSkillsCard, setDashboardAdditionalSkillsCard] = useState<boolean>(false)
  let [dashboardProfessionalCard, setDashboardProfessionalCard] = useState<boolean>(false)

  let [events, setEvents] = useState<Array<any>>([])
  let [news, setNews] = useState<Array<any>>([])
  let [cards, setCards] = useState<Array<DynamicCard>>([])
  let [counters, setCounters] = useState<Counters>()

  const [subscriptionModal, setSubscriptionModal] = useState<Subscription>();


  const steps: Step[] = [
    {
      title: strings.updatesModal.step1.title,
      description: strings.updatesModal.step1.description,
      image: newUserProfile,
      buttonText: strings.updatesModal.step1.buttonText,
    },
    {
      title: strings.updatesModal.step2.title,
      description: strings.updatesModal.step2.description,
      image: genericSkills,
      buttonText: strings.updatesModal.step2.buttonText,
    },
    {
      title: strings.updatesModal.step3.title,
      description: strings.updatesModal.step3.description,
      image: specificSkills,
      buttonText: strings.updatesModal.step3.buttonText,
      onClick: async () => {
        await markPopupAsSeen(1);
        history.push('/profile', { showSkillModal: true })
      },
      secondaryButton: {
        text: strings.updatesModal.step3.secondaryButtonText,
        onClick: async () => {
          await markPopupAsSeen(1);
          window.location.reload();
        },
      },
    },
  ]

  useEffect(() => {
  }, []);

  useEffect(() => {
    checkIfSeen(1);

    if (currentUser) {
      setIsNewUser(currentUser.is_new)
    }
  }, [])

  useEffect(() => {
    const script = document.createElement("script");
    if (document.getElementById('div-gpt-ad-home') !== undefined) {
      script.innerHTML = "googletag.cmd.push(function() {googletag.display(`div-gpt-ad-home`); });"
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      //TODO ADV REFRESH
      googletag.cmd.push(function () { googletag.pubads().refresh(); })
      document.body.removeChild(script);
    }
  }, [])

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.dashboard)
    dashboardRequest()
    subscriptionInfoRequest()
  }, [])



  let [activities, setActivities] = useState<Array<Demand>>([])
  let [activitiesData, setActivitiesData] = useState<any>({
    demands: [],
    candidates: [],
    candidates_candidates: [],
    assigned_demands: [],
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('subscription')) {
      const subscription: Subscription = JSON.parse(queryParams.get('subscription')!)
      setSubscriptionModal(subscription)

    } else if (queryParams.get('subscription_error')) { }
  }, [location]);


  useEffect(() => {
    if (dashboardResponse)
      setIsLoading(false);
    else
      setIsLoading(true)
  }, [dashboardResponse]);



  useEffect(() => {
    if (subscriptionInfoResponse && currentUser) {
      setDashboardProfessionalCard(!_.isEmpty(subscriptionInfoResponse.filter((s) => s.type == 'professional')) && User.professionalSubscription(currentUser) == undefined)
      setDashboardGoldCard(!_.isEmpty(subscriptionInfoResponse.filter((s) => s.type == 'gold')) && User.goldSubscription(currentUser) == undefined)
      setDashboardAdditionalSkillsCard(!_.isEmpty(subscriptionInfoResponse.filter((s) => s.type == 'competenze')) && _.isEmpty(User.additionalSkillsSubscriptions(currentUser)))
    }
  }, [subscriptionInfoResponse, currentUser])

  useEffect(() => {

    if (shouldPerformRequest.value) {
      dispatch(setCurrentDemand(undefined))
      dispatch(setCurrentCandidate(undefined))
      dispatch(setCurrentCandidateUser(undefined))

      dashboardRequest()
    }
  }, [shouldPerformRequest.datetime])

  useEffect(() => {
    if (!dashboardResponse) return
    setEvents(dashboardResponse.events)
    setNews(dashboardResponse.articles)
    setCards(dashboardResponse.cards)
    setCounters(dashboardResponse.counters)

    setActivitiesData({
      demands: dashboardResponse.demands,
      candidates: dashboardResponse.candidates.map((candidate: Candidate) => candidate.demand),
      candidates_candidates: dashboardResponse.candidates,
      assigned_demands: dashboardResponse.assigned_demands,
    })

    setActivities(dashboardResponse.demands)
    setActivities(dashboardResponse.candidates.map((candidate: Candidate) => candidate.demand))
    setActivities(dashboardResponse.assigned_demands)

    dispatch(setShouldPerformRequest(false))

  }, [dashboardResponse])

  useEffect(() => {
    dispatch(tabThirdClose());
    dispatch(tabActivityClose());
    dispatch(setCurrentEvent());
    dispatch(setCurrentDemand(undefined))
  }, [])

  const collegaCard = () => {
    let card = _.get(cards, 0)

    return (
      <div className={'card blue'} style={{ backgroundImage: `linear-gradient(-45deg, ${card.color_end} 0%, ${card.color_start} 100%)` }}>
        {card.closable && <img src={close} alt={'close'}
          className={'close'}
          onClick={async () => {
            AnalyticsService.event(AnalyticsEvents.card, { action: 'close' })

            await deleteCardRequest(_.get(cards, 0))
            await setCards(cards.filter((card: DynamicCard) => card !== _.get(cards, 0)))
          }}
        />}

        <h4>{card.title}</h4>
        <p style={{ opacity: '.7' }}>{card.subtitle}</p>

        <div className={'cta__container'}>
          <img src={collega} alt={'collega'} />
          <div style={{ display: 'flex', cursor: 'pointer' }} onClick={async () => {
            AnalyticsService.event(AnalyticsEvents.card, { action: 'open', card_id: card.id })

            let _card: DynamicCard = _.get(cards, 0)

            await setCards(cards.filter((card: DynamicCard) => card !== _card))

            if (card.link) {
              window.open(card.link, '_blank')

            } else if (card.deeplink) {
              if (card.deeplink === CardDeeplink.COURSES) history.push('/courses')
              if (card.deeplink === CardDeeplink.AGENDA) history.push('/agenda')
              if (card.deeplink === CardDeeplink.PROFILE) history.push('/profile')
            }

          }} >
            <h4>{card.cta}</h4>
            <img src={arrowW} alt={'arrow'} />
          </div>
        </div>
      </div>
    )
  }

  const agendaCard = () => {
    return (
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <NavLink to={'/agenda'} className={'toLink'}>
          <div className={'title__card__container'}>
            <h3>{strings.dashboard.agenda}</h3>
            <img src={arrowG} alt={'arrow'} />
          </div>
        </NavLink>
        <div className={'card agenda'}>
          <div className={'agenda__container'}>
            <div className={classNames('commitment__date__content')} onClick={() => (
              dispatch(tabActivityClose()),
              dispatch(setCurrentDemand(undefined)),
              dispatch(setCurrentCandidate(undefined)),
              dispatch(setCurrentCandidateUser(undefined))
            )}>
              {events.length > 0 && (
                <>
                  {events.map((ev: Event) => {
                    return <CommitmentEvent event={new Event(ev)} key={ev.id} showDate={true} />
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const requestActivityCard = () => {
    return (
      <div className={'card green'}>
        <NavLink to={'/request-activities'}>
          <div className={'activity'}>
            <div className={'copy'}>
              <h4>{strings.dashboard.activityCard.title}</h4>
              <span>{strings.dashboard.activityCard.subtitle}</span>
            </div>
            <img src={add} alt={'add'} style={{ width: '32px', height: '32px' }} />
          </div>
        </NavLink>
      </div>
    )
  }

  const shopCard = () => {
    return (
      <div className={'card'}>
        <a href={ExternalLinks.CollegaShop} onClick={() => AnalyticsService.event(AnalyticsEvents.iSkilled, undefined)} target={'_blank'}>
          <div className={'shop'}>
            <div className={'shop copy'}>
              <img src={collega_g} alt={'Shop'} />
              <div>
                <h4 style={{ color: '#208A86' }}>{strings.dashboard.collegaTraining}</h4>
                <h4 style={{ color: '#82849D' }}>{strings.dashboard.iSkilledCard.subtitle}</h4>
              </div>
            </div>
            <img src={arrowG} alt={'arrow'} />
          </div>
        </a>
      </div>
    )
  }

  const adManagerCard = () => {
    return (
      <div className={'ad_manager_container'}>
        <div id={'div-gpt-ad-home'} />
      </div>
    )
  }

  const activityImage = (context: ActivitiesContext) => {
    var activities: Array<Demand> = []

    switch (context) {
      case ActivitiesContext.demands: activities = activitiesData.demands; break
      case ActivitiesContext.candidates: activities = activitiesData.candidates; break
      case ActivitiesContext.managements: activities = activitiesData.assigned_demands
    }

    return activities.map((demand: Demand) => {
      return (
        demand.active_candidates.slice(0, 2).map((candidate: Candidate, index) => {
          return (
            <div key={index} className={'img__container'}>
              <img src={candidate.user!.profile.picture ? candidate.user!.profile.picture : profile} alt={'group'} />
            </div>
          )
        })
      )
    })
  }

  const activityCounter = (context: ActivitiesContext) => {
    var counter: number = 0

    if (counters != undefined)
      switch (context) {
        case ActivitiesContext.demands: counter = counters.demands; break
        case ActivitiesContext.candidates: counter = counters.candidates; break
        case ActivitiesContext.managements: counter = counters.assigned_demands
      }

    return <span className='counter'>{counter}</span>
  }

  const activityFilter = (context: ActivitiesContext, white: string, def: string, tile: string, sub: string) => {
    return (
      <NavLink to={activityLink(context!)} className={'toLink'}>

        <div className={'filter'} onClick={() => (
          AnalyticsService.event(AnalyticsEvents.dashboardActivityTab, { selected_tab: ActivitiesContext[context] }),

          dispatch(tabActivityClose()),
          dispatch(tabThirdClose()),
          dispatch(setCurrentEvent()),
          dispatch(setCurrentDemand(undefined)),
          dispatch(setCurrentCandidate(undefined)),
          dispatch(setCurrentCandidateUser(undefined))
        )}>
          <div className={'imgs__container'}>
            <img src={def} alt={'context'} />
            <div className={'activity__candidates'}>
              {activityImage(context)}
              {/* {activitiesTab === context ?  : undefined} */}
            </div>
          </div>
          <p>{tile}</p>
          <span>{sub}</span>
          {activityCounter(context)}
        </div>
      </NavLink>

    )
  }

  const activitiesCard = () => {
    return (
      <div className={'activity-area__container'}>
        <div className={'title__card__container'} style={{}}>
          <h3>{strings.dashboard.activitiesCard.title}</h3>
        </div>

        <div className={'filters__container'}>
          {activityFilter(ActivitiesContext.candidates, proposalW, proposal, ``, 'Disponibilità')}
          {activityFilter(ActivitiesContext.demands, requestW, request, ``, 'Richieste da Te')}
          {activityFilter(ActivitiesContext.managements, assignW, assign, ``, 'Incarichi Ricevuti')}
        </div>


      </div>
    )
  }

  const newsCard = () => {
    return (
      <Fragment>
        {!_.isEmpty(news) && <div className={'news-area__container'}>
          <NavLink to={'/news'} className={'toLink'}>
            <div className={'title__card__container'}>
              <h3>{strings.dashboard.news}</h3>
              <img src={arrowG} alt={'arrow'} />
            </div>
          </NavLink>

          <div className={'card news'}>
            <div className={'news__card__container'}>
              <div className={'news__card__'}>
                {_.values(news).map((_news: any, index) => {
                  return <NewsItem key={index} news={_news} />
                })}
              </div>
            </div>
          </div>
        </div>}
      </Fragment>
    )
  }

  return (
    <div className={"dashboard__container"}>
      <h2>{strings.dashboard.title}</h2>
      <div className={"divider"}></div>

      {adManagerCard()}

      <div className={"dashboard__"}>
        {isLoading ? (
          <div className={"loader_empty_state_container"}>
            <div className={"loader_loading"} />
          </div>
        ) : (
          <Fragment>
            {_.isEmpty(cards) === true && events.length === 0 ? (
              <div className={"first-area__container cta"}>
                <Fragment>
                  {requestActivityCard()}
                  {shopCard()}
                </Fragment>
              </div>
            ) : (
              <div
                className={
                  _.isEmpty(cards) === false && events.length > 0
                    ? "first-area__container blue "
                    : "first-area__container"
                }
              >
                {/* Dynamic card */}
                {!_.isEmpty(cards) && collegaCard()}
                {/* Agenda Legale */}
                {events.length > 0 && agendaCard()}
                {/* CTA */}
                {_.isEmpty(cards) === false && events.length > 0 ? (
                  <Fragment>
                    {requestActivityCard()}
                    {shopCard()}
                  </Fragment>
                ) : (
                  <div className={"activity-iskilled__container"}>
                    {requestActivityCard()}
                    {shopCard()}
                  </div>
                )}
              </div>
            )}


            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 16, rowGap: 0 }}>
              {/* Main Skills */}
              {(dashboardSkillsCard || (mainSkills && mainSkills.length === 1)) && (
                <EmptySpecificSkillBanner
                  isForMainSkill={true}
                  action={() => history.push('/profile', { showSkillModal: true })}
                />
              )}

              {/* Professional */}
              {dashboardProfessionalCard && (
                <EmptyProfessionalBanner
                  action={() => history.push(`/subscription?type=${SubscriptionInfoType.PROFESSIONAL}`)}
                />
              )}

              {/* Gold */}
              {dashboardGoldCard && (
                <EmptyGoldBanner
                  action={() => history.push(`/subscription?type=${SubscriptionInfoType.GOLD}`)}
                />
              )}

              {/* Additional Skills */}
              {dashboardAdditionalSkillsCard && (
                <EmptyAdditionalSkillBanner
                  action={() => history.push(`/subscription?type=${SubscriptionInfoType.SKILLS}`)}
                />
              )}
            </div>

            {/* Main Skills */}
            {/* {dashboardSkillsCard || (mainSkills && mainSkills.length == 1) && <EmptySpecificSkillBanner isForMainSkill={true} action={() => history.push('/profile', { showSkillModal: true })} />} */}

            {/* Professional */}
            {/* {dashboardProfessionalCard && <EmptyProfessionalBanner action={() => history.push(`/subscription?type=${SubscriptionInfoType.PROFESSIONAL}`)} />} */}

            {/* Gold */}
            {/* {dashboardGoldCard && <EmptyGoldlBanner action={() => history.push(`/subscription?type=${SubscriptionInfoType.GOLD}`)} />} */}

            {/* Additional Skills */}
            {/* {dashboardAdditionalSkillsCard && <EmptyAdditionalSkillBanner action={() => history.push(`/subscription?type=${SubscriptionInfoType.SKILLS}`)} />} */}

            {/* Activities */}
            {activitiesCard()}

            {/* News */}
            {newsCard()}
          </Fragment>
        )}
      </div>
      {hasSeenPopup == false && <UpdatesModal isNewUser={isNewUser} steps={steps} />}

      {subscriptionModal && <SubscriptionDoneModal
        subscription={subscriptionModal}
        onConfirm={() => {
          history.replace({
            pathname: '/dashboard',
            search: ''
          });

          // Then navigate to /profile
          history.push('/profile');
          setSubscriptionModal(undefined)
        }}
        onCancel={() => {
          history.replace({
            pathname: '/dashboard',
            search: ''
          });

          setSubscriptionModal(undefined)
        }}
      />}

      <AgendaDetail />
      <ActivityRightPanel />
      <ActivityRightProfile />
    </div>
  );
};

export default Dashboard