export class NetworkResponse {
  data?: any;
  error?: any;
  error500!: boolean;

  constructor(data?: any, error?: any, error500= false) {
    this.data = data;
    this.error = error;
    this.error500 = error500;
  }
}
