import { useState } from "react";
import Network, { NetworkMethod } from "network/Network";
import { DynamicCard } from "store/types/DynamicCard";
import { conforms } from "lodash";


class DashboardService {

  static useDashboardAPI = (): [any, any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    let request = async () => {

      let method = NetworkMethod.get
      let endpoint = 'dashboard/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useHasSeenPopupApi = (): [any, any, (popupId: number) => void] => {
    const [response, setResponse] = useState<boolean>();
    const [error, setError] = useState(null);

    const checkIfSeen = async (popupId: number) => {
      const method = NetworkMethod.get;
      const endpoint = `popups/has-seen-popup/?popup_id=${popupId}`;

      try {
        const response = await Network.fetchAPI(method, endpoint, true, null, null);
        setError(response.error);
        if (response.data != undefined) {
          setResponse(response.data);
        }
      } catch (e) {
        setError(e);
      }
    };

    return [response, error, checkIfSeen];
  };

  static useMarkPopupAsSeenApi = (): [any, any, (popupId: number) => void] => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const markAsSeen = async (popupId: number) => {
      const method = NetworkMethod.post;
      const endpoint = 'popups/seen/';
      const body = { popup_id: popupId };

      try {
        const response = await Network.fetchAPI(method, endpoint, true, body, null);
        setError(response.error);

        if (response.data) {
          setResponse(response.data);
        }
      } catch (e) {
        setError(e);
      }
    };

    return [response, error, markAsSeen];
  };

  static useDeleteCardAPI = (): [any, any, (card: DynamicCard) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    let request = async (card: DynamicCard) => {

      let method = NetworkMethod.delete
      let endpoint = 'cards/' + card.id + '/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }

    }

    return [response, error, request]
  }

}

export default DashboardService
