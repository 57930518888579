import React, { CSSProperties, FC, ReactNode } from "react"
import { createPortal } from "react-dom"
import closeIcon from '../../image/close-icon.svg'

interface ModalProps {
    children: ReactNode 
    className?: string
    onClose?: () => void
    width?: CSSProperties['width']
}

const Modal: FC<ModalProps> = ({
    children, 
    className,
    onClose,
    width
}) => {
  return createPortal(
    <>
        <div className={'modal-overlay'}/>
        <div className={'modal-container'}>
            {onClose && <div onClick={onClose} className="modal-close-icon">
              <img src={closeIcon} alt="close-icon" />
            </div>}
            {children}
        </div>
    </>, 
    document.body
  )
}

export default Modal