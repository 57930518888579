import React, { MouseEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../image/logo-collega-white.svg';
import card1 from '../image/on-boarding-card-1.svg';
import card2 from '../image/on-boarding-card-2.svg';
import card3 from '../image/on-boarding-card-3.svg';
import card4 from '../image/on-boarding-card-4.svg';
import strings from '../values/strings';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';


const Onboarding: React.FC = () => {
  const history = useHistory();

  // FIXME: You can replace it with <Link />
  function goToSignIn(event: MouseEvent) {
    event.preventDefault();
    history.push('signin');
  }

  function goToSignUp(event: MouseEvent) {
    event.preventDefault();
    history.push('SignUp');
  }

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.onboarding)
  }, [])

  
  return (
    <div className={'onboarding-cntr'}>
      <div className={'header-onboarding'}>
        <div>
          <img className={'logo'} src={logo} alt="Logo collega" />
          <h2>{strings.onBoarding.first.pre}</h2>
          <p className={'title'}>{strings.onBoarding.first.title}</p>
          <p>{strings.onBoarding.first.subtitle}</p>
        </div>
        <div className="buttons">
          <button className={'button-main'} onClick={goToSignUp}>{strings.onBoarding.signUp}</button>
          <button className={'button-login'} onClick={goToSignIn}>{strings.onBoarding.signIn}</button>
        </div>
      </div>
      <div className={'wrapper-on'}>
        <div className={'onboarding'}>
          <div className={'onboarding__content'}>
            <div className={'img__container'}>
              <img src={card1} alt="card1" />
            </div>
            <h2>{strings.onBoarding.card1.pre}</h2>
            <p>{strings.onBoarding.card1.text}</p>
          </div>
          <div className={'onboarding__content'}>
            <div className={'img__container'}>
              <img src={card2} alt="card2" />
            </div>
            <h2>{strings.onBoarding.card2.pre}</h2>
            <p>{strings.onBoarding.card2.text}</p>
          </div>
          <div className={'onboarding__content'}>
            <div className={'img__container'}>
              <img src={card3} alt="card3" />
            </div>
            <h2>{strings.onBoarding.card3.pre}</h2>
            <p>{strings.onBoarding.card3.text}</p>
          </div>
          <div className={'onboarding__content'}>
            <div className={'img__container'}>
              <img src={card4} alt="card4" />
            </div>
            <h2>{strings.onBoarding.card4.pre}</h2>
            <p>{strings.onBoarding.card4.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
