import React, { ChangeEvent, FocusEvent, MouseEvent } from 'react';
import classNames from 'classnames';
import { add, basket } from '../../image/icons';
import gold from '../../image/gold.svg';
import professional from '../../image/icons/activity/collega-gold.svg';

export interface ITextareaProps {
  label?: string;
  name?: string;
  placeholder?: string;
  value?: string | undefined;
  error?: string;
  autoFocus?: boolean | undefined;
  readOnly?: boolean | undefined;
  disabled?: boolean | undefined;
  cancelable?: boolean;
  onChange?: (event?: ChangeEvent<HTMLTextAreaElement>) => any;
  onBlur?: (event?: FocusEvent<HTMLTextAreaElement>) => any;
  onCancel?: (event?: MouseEvent<HTMLButtonElement>) => any;
  style?: React.CSSProperties;
  hasIcon?: boolean;
  rows?: number;
  cols?: number;
}

const TextArea: React.FC<ITextareaProps> = ({
  label,
  name,
  error,
  onChange,
  onBlur,
  placeholder,
  value,
  autoFocus,
  readOnly,
  cancelable,
  onCancel,
  style,
  disabled,
  hasIcon,
  rows = 3,
  cols,
}) => {
  return (
    <>
      <div style={{ display: 'flex', }}>
        {hasIcon && <img src={gold} alt={'collega icon'} style={{ width: 20, height: 20, marginRight: 8, marginTop: 10 }} />}
        {label && (<label>{label}</label>)}
      </div>

      <textarea
        name={name}
        readOnly={readOnly}
        autoFocus={autoFocus}
        className={classNames({ 'input-error': error, cancelable })}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        style={style}
        disabled={disabled}
        rows={rows}
        cols={cols}
      />

      {cancelable &&
        <button className={'input-trash'} onClick={onCancel} type={'button'}
          tabIndex={-1}><img src={basket} alt="basket" /></button>}
      {error && <p className={'error'}>{error}</p>}
    </>
  );
};

interface ICancelableTextareaProps extends ITextareaProps {
  showField: boolean;
  labelAdd: string;
  onCancel: (event?: MouseEvent<HTMLButtonElement>) => any;
  handleShow: (event?: MouseEvent<HTMLButtonElement>) => any;
}

const CancelableTextareaField: React.FC<ICancelableTextareaProps> =
  ({
    showField,
    labelAdd,
    handleShow,
    ...rest
  }) => {
    return (
      <div className={'form__full'}>
        {showField && (
          <TextArea
            {...rest}
            cancelable={true}
          />
        )}
        {!showField && (
          <button className={'button-secondary icon-right'}
            type={'button'}
            onClick={handleShow}>
            <span className={'txt'}>{labelAdd}</span>
            <span className={'icon'}><img src={add} alt="add" /></span>
          </button>
        )}
      </div>
    );
  };

export { CancelableTextareaField };

export default TextArea;
