import { TypedSerializer } from 'ts-typed';

import _ from 'lodash';
import moment from 'moment';
import {DateFormat, FormFields, NoticeType, ResourceType} from '../../values/values';
import {LawyerEvent} from './LawyerEvent';
import {News, NewsEvent} from './News';
import Utility from '../../utils/utils';

export default class Event {
  static parts = (event: Event) => {
    if (event._lawyerevent) {
      return (event._lawyerevent.part1 || 'N.D') + '/' + (event._lawyerevent.part2 || 'N.D');
    }
    return 'N.D.';
  };

  id?: string;
  date?: string;
  created_at?: string;
  updated_at?: string;
  all_day: boolean = false;
  news?: NewsEvent;


  resourcetype?: ResourceType;
  display_name?: string;

  private _start?: string;
  private _end?: string;
  private _lawyerevent!: LawyerEvent;
  private _fulfillment?: Event;

  get start(): string | undefined {
    return this._start ? moment(this._start, DateFormat.timeSecond).format(DateFormat.time) : this._start;
  }

  set start(value: string | undefined) {
    this._start = value;
  }

  get end(): string | undefined {
    return this._end ? moment(this._end, DateFormat.timeSecond).format(DateFormat.time) : this._end;
  }

  set end(value: string | undefined) {
    this._end = value;
  }

  get lawyerevent(): LawyerEvent | Partial<LawyerEvent> {
    return this._lawyerevent;
  }

  set lawyerevent(value: LawyerEvent | Partial<LawyerEvent>) {
    this._lawyerevent = (value instanceof LawyerEvent) ? value : new LawyerEvent(value);
  }

  get fulfillment(): Event | Partial<Event> | undefined {
    return this._fulfillment;
  }

  set fulfillment(value: Event | Partial<Event> | undefined) {
    this._fulfillment = value ? ((value instanceof Event) ? value : new Event(value)) : undefined;
  }

constructor(object: Partial<Event>) {
    this.all_day = false;

    const {lawyerevent, fulfillment, ...eventparam} = object;

    Object.assign(this, eventparam);

    this.lawyerevent = new LawyerEvent(lawyerevent || {});
    this.fulfillment = fulfillment ? new Event(fulfillment || {}) : undefined;
  }

  getResource = (field: string) => {
    switch (this.resourcetype) {
      case ResourceType.Appointment:
        return _.get(this._lawyerevent.appointment, field);
      case ResourceType.Fulfillment:
        return _.get(this._lawyerevent.fulfillment, field);
      case ResourceType.Hearing:
        return _.get(this._lawyerevent.hearing, field);
    }
  };

  noticeDays = (): string | null => {
    const option = Utility.selectOptions(NoticeType).find((o) => o.value === `_${this.getResource(FormFields.notice)}`);

    return option ? option.label : null;
  };

  eventStatus = () => {
    switch (this.resourcetype) {
      case ResourceType.Notice: return this.fulfillment!.lawyerevent!.status;
      default: return this.lawyerevent.status;
    }
  };

  /**
   * toString equivalent, allows you to remove the _ prefix from props.
   *
   */
  toJSON(): Event {
    return TypedSerializer.serialize(this);
  }
}
