import React, { useState, useEffect } from 'react'
import strings from 'values/strings';
import CoursesFilter from './CoursesFilter';
import { ItemCategory, Item } from 'store/types/Item';
import CoursesItem from './CoursesItem';
import { setCurrentCourse, SidePanelReducer, setPurchasedCourse, setCurrentPackage } from 'store/reducers/SidePanelReducer';
import { useDispatch, useSelector } from 'react-redux';
import CourseService from 'services/CourseService';
import _ from 'lodash';
import CourseSidePanel, { ItemPanelCtx } from './CourseSidePanel';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import { IUserReducer } from 'store/reducers/UserReducer';
import PackageItem from './PackageItem';
import { ActivityModal } from 'store/types/ActivityModal';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import classNames from 'classnames';

interface Props {
  history?: any
  id?: any
}

const Courses: React.FC<Props> = ({ history, id }) => {

  let [coursesResponse, coursesError, coursesRequest] = CourseService.useCoursesAPI()
  let [itemResponse, courseError, itemRequest] = CourseService.useCourseAPI()

  let [isLoading, setIsLoading] = useState(true);
  let [courses, setCourses] = useState<Array<Item>>([]);
  let [paginationPage, setPaginationPage] = useState(1)
  let [selectedFilters, setSelectedFilters] = useState<any>()

  let [course, setCourse] = useState<Item>();
  let [pkg, setPkg] = useState<Item>();
  let [showCredits, setShowCredits] = useState<boolean>(false);

  let [cfpModal, setCfpModal] = useState<boolean>(false);

  const currentPackage = useSelector((state: SidePanelReducer) => state.sidePanelReducer.currentPackage);
  const currentCourse = useSelector((state: SidePanelReducer) => state.sidePanelReducer.currentCourse);
  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

  let dispatch = useDispatch()


  const CFPContent: ActivityModal = currentUser ? {
    title: `${currentUser.profile.credits} CFP rimanenti`,
    copy: 'Con l’acquisto dei pacchetti potrai iscriverti ai corsi utilizzando i CFP senza ulteriori pagamenti. Ad esempio, con 8 CFP potrai iscriverti a 8 corsi con 1 credito formativo ciascuno, oppure a 4 corsi con 2 crediti formativi ciascuno e via dicendo…',
    cta: 'Chiudi',
    ctaUndo: strings.courses.modal.ctaUndo,
    onConfirm: () => setCfpModal(false),
  } : {}




  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.courses)
    dispatch(setPurchasedCourse(undefined))
  }, [])

  useEffect(() => {
    if (currentCourse) setCourse(currentCourse)
  }, [currentCourse])

  useEffect(() => {
    if (currentPackage) setPkg(currentPackage)
  }, [currentPackage])

  useEffect(() => {
    if (currentUser && currentUser!.profile.credits! > 0){
    setShowCredits(true)
  }
  }, [currentUser])


  //COURSES

  useEffect(() => {
    if (selectedFilters)
      coursesRequest(1, selectedFilters.category, selectedFilters.query)
    setPaginationPage(1)
  }, [selectedFilters])

  useEffect(() => {
    if (coursesResponse) setCourses([...courses, ...coursesResponse])
    setTimeout(() => { setIsLoading(false); }, 500);
  }, [coursesResponse])


  //COURSE

  useEffect(() => {
    if (id) {
      itemRequest(id)

    } else {
      dispatch(setCurrentCourse(undefined))
      dispatch(setCurrentPackage(undefined))
    }
  }, [id])

  useEffect(() => {
    if (itemResponse) {
      if (itemResponse.course !== null) dispatch(setCurrentCourse(itemResponse))
      if (itemResponse.package !== null) dispatch(setCurrentPackage(itemResponse))

      setTimeout(() => { setIsLoading(false); }, 500)
    }
  }, [itemResponse])

  useEffect(() => {
    if (currentCourse)
      dispatch(setCurrentPackage(undefined))
  }, [currentCourse])

  useEffect(() => {
    if (currentPackage)
      dispatch(setCurrentCourse(undefined))
  }, [currentPackage])


  // PAGINATION

  let askForPage = async (page: number, reset?: boolean) => {
    if (reset) await setCourses([])
    await setPaginationPage(page)
    await coursesRequest(page, selectedFilters.category, selectedFilters.query)
  }

  let loadMore = async () => {
    if (_.isEmpty(coursesResponse)) return
    askForPage(paginationPage + 1)
  }

  let handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) loadMore()
  }

  var filterLines = document.getElementById('linesNumber');

  const filtersHeight = () => {
    if (filterLines && filterLines.clientHeight < 100) {
      return 'oneLine'
    } else if (filterLines && filterLines.clientHeight < 150) {
      return 'twoLines'
    } else if (filterLines && filterLines.clientHeight < 200) {
      return 'threeLines'
    } else if (filterLines && filterLines.clientHeight < 250) {
      return 'fourLines'
    } else {
      return undefined
    }
  }

  return (
    <>
      <div className={'courses__container'} onScroll={handleScroll} >

        {cfpModal && <ConfirmModal content={CFPContent} />}

        <h2>{strings.courses.title}</h2>

        <div className={'divider'}></div>

        <CoursesFilter
          onChangeFilter={(category?: ItemCategory, query?: string) => {
            setIsLoading(true)
            setCourses([])

            dispatch(setCurrentCourse(undefined))
            dispatch(setCurrentPackage(undefined))

            setSelectedFilters({ category: category, query: query })
          }} 
          showCredits={showCredits}
          />
        {showCredits && <div className="credits_container">
          <p className="credits">{`${currentUser!.profile.credits} CFP rimanenti`}</p>
          <p className="credits_link" onClick={() => setCfpModal(true)}>Scopri di più...</p>
        </div>}
        {courses &&
          <div 
          className={classNames(`courses__card__container ${filtersHeight()}`, { creditsFix: showCredits })}>
            <div className={'courses__card__'}>
              {/* LOADING */}
              {isLoading &&
                <div className={'loader_empty_state_container'}>
                  <div className={'loader_loading'} />
                </div>}
              {/* ITEMS */}
              {!isLoading &&
                <>
                  {/* EMPTY STATE */}
                  {/* {coursesResponse && _.isEmpty(courses) && <div className={'empty__panel'}><Empty /></div>} */}
                  {courses.map((item: Item, index) => {
                    if (item.course) return <CoursesItem obj={item} key={index} history={history} />
                    if (item.package) return <PackageItem obj={item} key={index} history={history} />
                  })}
                </>
              }
            </div>
          </div>
        }
      </div>

      {course && <CourseSidePanel obj={course} context={ItemPanelCtx.course} history={history} />}
      {pkg && <CourseSidePanel obj={pkg} context={ItemPanelCtx.package} history={history} />}
    </>
  )
}

export default Courses