import { Item } from "store/types/Item";

// State

export interface SidePanelReducer {
    sidePanelReducer: SidePanelReducerState
}

export interface SidePanelReducerState {
    currentCourse?: Item
    purchasedCourse?: Item
    currentPackage?: Item
}

const INITIAL_STATE: SidePanelReducerState = {
    currentCourse: undefined,
    purchasedCourse: undefined,
    currentPackage: undefined
};


// Actions

const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE';
const SET_CURRENT_PACKAGE = 'SET_CURRENT_PACKAGE';
const SET_PURCHASED_COURSE = 'SET_PURCHASED_COURSE';


export function setCurrentCourse(item?: Item) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_CURRENT_COURSE,
            course: item
        });
    };
}

export function setCurrentPackage(item?: Item) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_CURRENT_PACKAGE,
            package: item
        });
    };
}

export function setPurchasedCourse(item?: Item) {
    return async (dispatch: any) => {
        await dispatch({
            type: SET_PURCHASED_COURSE,
            course: item
        });
    };
}

// Reduces

export function sidePanelReducer(state: SidePanelReducerState = INITIAL_STATE, action: any) {
  
    switch (action.type) {
        case SET_CURRENT_COURSE:
            return {
                ...state,
                currentCourse: action.course
            };

        case SET_CURRENT_PACKAGE:
            return {
                ...state,
                currentPackage: action.package
            };

        case SET_PURCHASED_COURSE:
            return {
                ...state,
                purchasedCourse: action.course
            };

        default:
            return state;
    }
};

