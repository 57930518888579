import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Network, { NetworkMethod } from '../network/Network';
import { setNotifications, setUnreadNotifications } from '../store/reducers/NotificationsReducer';

export default class NotificationService {


  static useNotificationsAPI = (): [Array<Notification>, any, (page: number) => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()

    const dispatch = useDispatch()

    let request = async (page: number) => {

      let method = NetworkMethod.get
      let endpoint = 'notifications/?page=' + page

      let response = await Network.fetchAPI(method, endpoint, true, null, null)

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
        dispatch(setNotifications(response.data))
      }

    }

    return [response, error, request]
  }

  static useUnreadNotificationsAPI = (): [number, any, () => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()

    const dispatch = useDispatch()

    let request = async () => {

      let method = NetworkMethod.get
      let endpoint = 'notifications/unread_notifications/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)

      setError(response.error)

      if (response.data) {
        dispatch(setUnreadNotifications(response.data.total))
      }
    }

    return [response, error, request]
  }

  static useNotificationsReadallAPI = (): [Array<Notification>, any, () => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()

    const dispatch = useDispatch()

    let request = async () => {

      let method = NetworkMethod.get
      let endpoint = 'notifications/readall/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)

      setError(response.error)
      dispatch(setUnreadNotifications(0))
    }

    return [response, error, request]
  }

}