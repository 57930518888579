import {useState} from 'react';
import Network, {NetworkMethod} from '../network/Network';
import {MenuItem} from '../store/types/MenuItem';
import {AppName} from '../values/values';
import {useDispatch} from 'react-redux';
import {setError500, setMenuItems} from '../store/reducers/AppReducer';

class MenuService {

  static useMenuAPI = (): [MenuItem[], any, () => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const request = async () => {

      const method = NetworkMethod.get;
      const endpoint = 'menus/';
      const params = {
        app: AppName.toLowerCase()
      };

      const networkResponse = await Network.fetchAPI(method, endpoint, false, null, params);
      setError(networkResponse.error);

      if (networkResponse.data) {
        setResponse(networkResponse.data);
        dispatch(setMenuItems(networkResponse.data));
      }
      if (networkResponse.error500) {
        dispatch(setError500(true));
      }
    };

    return [response, error, request];
  };
}

export default MenuService;
