import React, { FormEvent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { IUserReducer, updateTempUser } from '../../store/reducers/UserReducer';
import { FormFields, ExternalLinks } from '../../values/values';
import strings from '../../values/strings';
import Input from '../../components/common/Input';
import Utility from '../../utils/utils';
import { IStepSecond } from './interface/SignupInterface';

import closeTooltip from '../../image/icons/close.svg';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';

const StepSecond: React.FC<IStepSecond> = ({ email, password, unHidePassword, next }) => {

  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
  const dispatch = useDispatch();

  const [showTooltip, setShowTooltip] = useState(false);
  const [oulineCheckboxTerms, setOutlineCheckboxTerms] = useState(false);
  const [oulineCheckboxPrivacy, setOutlineCheckboxPrivacy] = useState(false);

  const toggleTerms = (force = false) => {
    dispatch(updateTempUser(FormFields.terms, force || !tempUser!.terms));
  };

  const togglePrivacy = (force = false) => {
    dispatch(updateTempUser(FormFields.privacy, force || !tempUser!.privacy));
  };

  const openTooltip = () => {
    setShowTooltip(true);
  };

  const unHide = () => {
    unHidePassword.setValidity(!unHidePassword.valid);
  };

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.register2)
  }, [])

  return (
    <form onSubmit={preventSubmit(next)}>
      <div className={'title_container'}>
        <h1 className={'title'}>{strings.signUp.headerTitle}</h1>
        <a className={'help'} href={'mailto:' + strings.navMenu.support_email.address + '?subject=' + strings.signUp.help_subject} target={'_blank'}>{strings.signUp.help}</a>
      </div>
      <Input
        label={strings.fieldLabelEmailRegister}
        type="email"
        autoFocus={true}
        placeholder={strings.fieldPlaceholderEmail}
        value={tempUser!.email}
        onChange={
          (event?: React.ChangeEvent<HTMLInputElement>) => {
            if (event) {
              dispatch(updateTempUser(FormFields.email, event.target.value));
              if (Utility.validateEmail(event.target.value)) {
                email.setValidity(true);
              }
            }
          }
        }
        onBlur={
          (event?: React.FocusEvent<HTMLInputElement>) => {
            if (event) {
              email.setValidity(Utility.validateEmail(event.target.value));
            }
          }
        }
        error={!email.valid ? strings.errors.invalidEmail : undefined}
      />

      <div className={'flex-row'}>
        <label>{strings.fieldLabelPasswordRegister}</label>
        <span className={'button-help'} onClick={openTooltip} />
        {showTooltip && (
          <div className={'help-tooltip'}>
            <p className={'title'}>{strings.tooltip.passwordTitle}</p>
            <p className={'copy'}>{strings.tooltip.passwordText}</p>
            <div className={'close'} onClick={() => setShowTooltip(false)}>
              <img src={closeTooltip} alt="close" />
            </div>
          </div>
        )}
      </div>
      <div className={'password-container'}>
        <span className={classNames('unhide', { hide: unHidePassword.valid })} onClick={unHide} />
        <Input
          type={!unHidePassword.valid ? 'password' : 'text'}
          placeholder={strings.fieldPlaceholderPassword}
          value={tempUser!.password1}
          onChange={
            (event?: React.ChangeEvent<HTMLInputElement>) => {
              if (event) {
                dispatch(updateTempUser(FormFields.password, event.target.value));
                if (Utility.validatePassword(event.target.value)) {
                  password.setValidity(true);
                }
              }
            }
          }
          onBlur={
            (event?: React.FocusEvent<HTMLInputElement>) => {
              if (event) {
                password.setValidity(Utility.validatePassword(event.target.value));
              }
            }
          }
          error={!password.valid ? strings.errors.invalidPassword : undefined}
        />
      </div>

      {tempUser!.validateCredentials() && <div className={'checkbox-container'}>
        <div className={'checkbox-content'}>
          <div
            className={classNames('check', { checked: tempUser!.terms, outline: oulineCheckboxTerms })}
            onClick={() => toggleTerms()}
          >
            <input
              defaultChecked={tempUser!.terms}
              type={'checkbox'}
              onFocus={() => setOutlineCheckboxTerms(true)}
              onBlur={() => setOutlineCheckboxTerms(false)}
            />
            <span />
          </div>
          <a href={ExternalLinks.Terms} target={'_blank'} tabIndex={-1}
            onClick={() => toggleTerms()}>{strings.fieldLabelTerms}</a>
        </div>

        <div className={'checkbox-content'}>
          <div className={classNames('check', { checked: tempUser!.privacy, outline: oulineCheckboxPrivacy })}
            onClick={() => togglePrivacy()}>
            <input
              defaultChecked={tempUser!.privacy}
              type={'checkbox'}
              onFocus={() => setOutlineCheckboxPrivacy(true)}
              onBlur={() => setOutlineCheckboxPrivacy(false)}
            />
            <span />
          </div>
          <a href={ExternalLinks.Privacy} target={'_blank'} tabIndex={-1}
            onClick={() => togglePrivacy()}>{strings.fieldLabelPrivacy}</a>
        </div>

      </div>}

      <button type={'submit'} className={'button-placeholder-submit'} tabIndex={-1} />
    </form>
  );
};

export default StepSecond;
