import Input from "components/common/Input";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import strings from "values/strings";
import { FormFields, RoleType } from "values/values";
import logo from '../../../image/logo-collega.svg';
import verify from '../../../image/image-collect-flow-collega.svg';
import { useSelector } from "react-redux";
import { IUserReducer } from "store/reducers/UserReducer";
import Select from '../../../components/common/Select';
import SelectOption from '../../../store/types/SelectionOption';
import { ISubscriptionReducer } from "store/reducers/SubscriptionReducer";
import SubscriptionService from "services/SubscriptionService";
import _ from 'lodash';


export interface FormData {
  first_name: string;
  last_name: string;
  fiscal_code: string;
  type: string;
}


const AccountConfirm = () => {
  const history = useHistory();
  const subscription = useSelector((state: ISubscriptionReducer) => state.subscription.subscription);
  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

  const [confirmResponse, confirmError, confirmRequest] = SubscriptionService.useConfirmAccountAPI()


  const [formData, setFormData] = useState<FormData>({
    first_name: currentUser!.first_name!,
    last_name: currentUser!.last_name!,
    fiscal_code: currentUser!.fiscal_code!,
    type: "",
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [option, setOption] = useState<SelectOption>();

  const collegaDetails = strings.subscriptions.collegaDetails;



  useEffect(() => {
    if (!currentUser) return
    setFormData({
      ...formData,
      ["type"]: _.first(options.filter((o) => o.value == currentUser!.profile.type))!.label
    });
  }, [currentUser])

  useEffect(() => {
    if (!subscription) window.location.href = '/dashboard'
  }, [subscription])

  useEffect(() => {
    if (confirmResponse) history.push("/subscription-plan")
  }, [confirmResponse])


  const handleInputChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event == null) return;
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim() === "") {
      setErrors({
        ...errors,
        [name]: `${name} cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
  };

  const selectOption = (name: string, option: SelectOption) => {
    setFormData({
      ...formData,
      [name]: option.label,
    });
  }

  const options = [
    new SelectOption<RoleType>(RoleType.LAWYER, 'Avvocato'),
    new SelectOption<RoleType>(RoleType.ESTABLISHED, 'Avvocato stabilito'),
    new SelectOption<RoleType>(RoleType.PRACTICING, 'Praticante avvocato'),
    new SelectOption<RoleType>(RoleType.QUALIFIED, 'Praticante avvocato abilitato')
  ];

  let typeValue = (option?: SelectOption) => {
    if (!currentUser!.profile.type) return undefined
    return options.find((defaultOption) => option != undefined ? option.value === currentUser!.profile.type : defaultOption.value == currentUser!.profile.type)
  }

  return (
    <Fragment>
      <div className={'header-subscription'}>
        <img className="logo subscription-logo" src={logo} alt="Logo collega" />
      </div>
      <div className={'wrapper-detail'}>
        {subscription && subscription.type && <div className={'login'} style={{ paddingTop: '30px' }}>
          <div style={{ position: 'relative' }} className={'login__form'}>

            <div className="collega-details">
              <h1 className="title" style={{ marginBottom: 16 }}>{collegaDetails.title}</h1>
              <p style={{ marginTop: 0, marginBottom: 32, lineHeight: '24px' }}>{collegaDetails.description}</p>
            </div>

            <form className="collega-confirmation__form">
              <Input
                label="Nome"
                name={FormFields.first_name}
                placeholder="Inserisci nome"
                value={formData.first_name}
                onChange={handleInputChange}
                error={errors.first_name}
              />
              <Input
                label="Cognome"
                name={FormFields.last_name}
                placeholder="Inserisci cognome"
                value={formData.last_name}
                onChange={handleInputChange}
                error={errors.last_name}
              />
              <Input
                label="Codice Fiscale"
                name={FormFields.fiscal_code}
                placeholder="Inserisci codice fiscale"
                value={formData.fiscal_code}
                onChange={handleInputChange}
                error={errors.fiscal_code}
              />

              <div className={'select-container'}>
                <Select<SelectOption>
                  label={'Qualifica'}
                  placeholder="Seleziona qualifica"
                  value={typeValue(option)}
                  defaultValue={typeValue(option)}
                  options={options}
                  onChange={(option: SelectOption) => {
                    setOption(option)
                    selectOption(FormFields.type, option)
                  }} />
              </div>
            </form>

            <button
              title={"Continua"}
              className={'button-primary'}
              onClick={() => {
                confirmRequest(formData)
              }}>
              <span>{'Prosegui'}</span>
            </button>

          </div>
          <div className={'login__image'}>
            <img style={{ width: 400 }} src={verify} alt="Logo collega" />
          </div>
        </div>}
      </div>
    </Fragment>
  );
};

export default AccountConfirm;
