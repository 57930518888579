import React, { useState, useEffect, Fragment } from 'react';
import ActivityFilter from './ActivityFilter';
import ActivityCard from './ActivityCard';
import _ from 'lodash';
import ActivitiesService from '../../services/ActivitiesService';
import { useDispatch, useSelector } from 'react-redux';
import { setActivitiesContext, setCurrentCandidateUser, setCurrentDemand, setCurrentCandidate, ActivitiesReducer, setShouldPerformRequest } from '../../store/reducers/ActivitiesReducer';
import ActivityRightPanel from './ActivityRightPanel';
import ActivityRightProfile from './ActivityRightProfile';
import { Demand } from 'store/types/Demand';
import { IAppReducer } from 'store/reducers/AppReducer';
import { setIsFromNotifications } from '../../store/reducers/AppReducer';
import Empty from 'components/Empty/Empty';
import strings from 'values/strings';
import { DemandsStatus, CandidatesStatus, ManagementsStatus } from 'values/values';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';


export enum ActivitiesContext {
  demands, candidates, managements
}


export let activityLink = (context: ActivitiesContext) => {
  switch (context) {
    case ActivitiesContext.demands: return '/activity-request'
    case ActivitiesContext.candidates: return '/activity-proposal'
    case ActivitiesContext.managements: return '/activity-assignments'
  }
}

interface Props {
  context: ActivitiesContext;
}

const ActivityRequest: React.FC<Props> = ({ context }) => {

  const [isLoading, setIsLoading] = useState(true);

  let [selectedFilter, setSelectedFilter] = useState<string>()
  let [query, setQuery] = useState<string>()
  let [paginationPage, setPaginationPage] = useState(1)
  let [activities, setActivities] = useState<Array<Demand>>([])

  let [demandsResponse, , demandsRequest] = ActivitiesService.useDemandsRequestsAPI()
  let [candidatesResponse, , candidatesRequest] = ActivitiesService.useCandidatesRequestsAPI()
  let [assignedDemandsResponse, , assignedDemandsRequest] = ActivitiesService.useAssignedDemandsRequestsAPI()

  let shouldPerformRequest = useSelector((state: ActivitiesReducer) => state.activitiesReducer.shouldPerformRequest)
  let isFromNotifications = useSelector((state: IAppReducer) => state.app.isFromNotifications)

  let dispatch = useDispatch()

  useEffect(() => {
    if (context === ActivitiesContext.demands) {
      analyticsScreen(selectedFilter!)
      setTimeout(() => { setIsLoading(false); }, 500);

    } else {
      setIsLoading(true)
    }
  }, [context]);

  useEffect(() => {
    if (context === ActivitiesContext.candidates) {
      analyticsScreen(selectedFilter!)
      setTimeout(() => { setIsLoading(false); }, 500);

    } else {
      setIsLoading(true)
    }
  }, [context]);

  useEffect(() => {
    if (context === ActivitiesContext.managements) {
      analyticsScreen(selectedFilter!)
      setTimeout(() => { setIsLoading(false); }, 500);

    } else {
      setIsLoading(true)
    }
  }, [context, assignedDemandsResponse]);

  useEffect(() => {
    if (demandsResponse || candidatesResponse || assignedDemandsResponse)
      setTimeout(() => { setIsLoading(false); }, 500);
  }, [demandsResponse, candidatesResponse, assignedDemandsResponse]);

  useEffect(() => {
    dispatch(setActivitiesContext(context))

    dispatch(setCurrentDemand(undefined))
    dispatch(setCurrentCandidate(undefined))
    dispatch(setCurrentCandidateUser(undefined))
    setIsFromNotifications(false)

    askForPage(1)
  }, [context])

  useEffect(() => {
    if (shouldPerformRequest.value) {
      dispatch(setCurrentDemand(undefined))
      dispatch(setCurrentCandidate(undefined))
      dispatch(setCurrentCandidateUser(undefined))

      askForPage(1)
    }
  }, [shouldPerformRequest])

  useEffect(() => {
    analyticsScreen(selectedFilter!)

    askForPage(1)
  }, [selectedFilter])

  useEffect(() => {
    askForPage(1)
  }, [query])


  let analyticsScreen = (selectedFilter: string) => {
    switch (context) {
      case ActivitiesContext.demands:
        switch (selectedFilter) {
          case DemandsStatus.ALL: AnalyticsService.screenName(AnalyticsScreens.activitiesDemands); break
          case DemandsStatus.PENDING: AnalyticsService.screenName(AnalyticsScreens.activitiesDemands1); break
          case DemandsStatus.ASSIGNED: AnalyticsService.screenName(AnalyticsScreens.activitiesDemands2); break
          case DemandsStatus.COMPLETED: AnalyticsService.screenName(AnalyticsScreens.activitiesDemands3); break
          case DemandsStatus.PAID: AnalyticsService.screenName(AnalyticsScreens.activitiesDemands4); break
          case DemandsStatus.ANNULLED: AnalyticsService.screenName(AnalyticsScreens.activitiesDemands5); break
          case DemandsStatus.EXPIRED: AnalyticsService.screenName(AnalyticsScreens.activitiesDemands6); break
        } break;
      case ActivitiesContext.candidates:
        switch (selectedFilter) {
          case DemandsStatus.ALL: AnalyticsService.screenName(AnalyticsScreens.activitiesCandidates); break
          case CandidatesStatus.PENDING: AnalyticsService.screenName(AnalyticsScreens.activitiesCandidates1); break
          case CandidatesStatus.ACCEPTED: AnalyticsService.screenName(AnalyticsScreens.activitiesCandidates2); break
          case CandidatesStatus.REJECTED: AnalyticsService.screenName(AnalyticsScreens.activitiesCandidates3); break
          case CandidatesStatus.DEAD: AnalyticsService.screenName(AnalyticsScreens.activitiesCandidates4); break
        } break;
      case ActivitiesContext.managements:
        switch (selectedFilter) {
          case DemandsStatus.ALL: AnalyticsService.screenName(AnalyticsScreens.activitiesReceivedDemands); break
          case ManagementsStatus.ASSIGNED: AnalyticsService.screenName(AnalyticsScreens.activitiesReceivedDemands1); break
          case DemandsStatus.COMPLETED: AnalyticsService.screenName(AnalyticsScreens.activitiesReceivedDemands2); break
          case DemandsStatus.PAID: AnalyticsService.screenName(AnalyticsScreens.activitiesReceivedDemands3); break
        }
    }
  }

  let isCandidate = () => {
    return context === ActivitiesContext.candidates
  }

  let request = (page: number) => {
    switch (context) {
      case ActivitiesContext.demands: return demandsRequest(selectedFilter, page, query)
      case ActivitiesContext.candidates: return candidatesRequest(selectedFilter, page, query)
      case ActivitiesContext.managements: return assignedDemandsRequest(selectedFilter, page, query)
    }
  }

  let response = () => {
    switch (context) {
      case ActivitiesContext.demands: return demandsResponse
      case ActivitiesContext.candidates: return candidatesResponse
      case ActivitiesContext.managements: return assignedDemandsResponse
    }
  }

  let title = () => {
    switch (context) {
      case ActivitiesContext.demands: return strings.activityRequest.demands
      case ActivitiesContext.candidates: return strings.activityRequest.candidates
      case ActivitiesContext.managements: return strings.activityRequest.managements
    }
  }

  useEffect(() => {
    if (response()) dispatch(setShouldPerformRequest(false))
  }, [(response())])

  useEffect(() => {
    if (response()) {
      handleResponse()
    }
  }, [response()])

  let handleResponse = async () => {
    if (paginationPage === 1) {
      await setActivities(response())

    } else {
      setActivities(activities.concat(
        await response().filter((d: Demand) => {
          return activities.indexOf(d) === -1
        })
      ))
    }
  }

  let askForPage = async (page: number) => {
    await setPaginationPage(page)
    await request(page)
  }

  let loadMore = async () => {
    if (_.isEmpty(response())) return
    askForPage(paginationPage + 1)
  }

  let handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) loadMore()
  }

  return (
    <div className={'activity__container'} onScroll={handleScroll} >
      <h2>{title()}</h2>
      <div className={'divider'}></div>

      <ActivityFilter
        onChangeCharacter={setQuery}
        onChangeStatus={setSelectedFilter}
        onSelect={() => setIsLoading(true)} />

      {activities && <div className={'activity___cards__container'} >
        {isLoading ?
          <div className={'loader_empty_state_container'} style={{ width: '478px' }}>
            <div className={'loader_loading'} />
          </div> :
          <Fragment>
            {response() && _.isEmpty(activities) &&
              <div style={{ width: '478px' }}>
                <Empty />
              </div>
            }

            {activities.map((item: any, index: any) => {
              let demand = isCandidate() ? item.demand : item
              let candidate = isCandidate() ? item : undefined

              return <ActivityCard
                key={index}
                demand={demand}
                candidate={candidate} />
            })}
          </Fragment>
        }
      </div>
      }

      <ActivityRightPanel />
      <ActivityRightProfile />
    </div>
  )

};

export default ActivityRequest;
