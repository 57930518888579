import React, { FC } from 'react'
import plusIcon from '../../image/plus-icon.svg'
import collegaWhite from '../../image/collega-white.svg';
import colors from 'values/colors';

type EmptyGoldlBannerProps = {
  action: () => void
}

const EmptyGoldBanner: FC<EmptyGoldlBannerProps> = ({ action }) => {
  return (
    <div className="banner-container-gold" >

      <div className="banner-content" style={{cursor: 'pointer'}} onClick={action}>
        <img src={collegaWhite} alt="collega" style={{ fill: 'white', height: 32, marginBottom: 12 }}  />
        <div className="banner-title">{'Acquista Collega Gold'}</div>
        <div className="banner-subtitle">{'Acquista Collega Gold e arricchisci il tuo profilo.'}</div>
      </div>

      <button className="banner-button">
        <img src={plusIcon} alt="plus-icon" />
      </button>
    </div>
  )
}

export default EmptyGoldBanner