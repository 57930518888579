import React, { useState, useEffect, Fragment } from 'react';
import NewsFilter from './NewsFilter';
import { NewsCategory, News } from 'store/types/News';
import NewsService from 'services/NewsService';
import NewsItem from './NewsItem';
import Empty from 'components/Empty/Empty';
import strings from 'values/strings';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
var _ = require('lodash');


const NewsWrapper: React.FC = () => {

  let [isLoading, setIsLoading] = useState(true);

  let [_newsResponse, newsError, newsRequest] = NewsService.useNewsAPI()

  let [paginationPage, setPaginationPage] = useState(1)
  let [newsResponse, setNewsResponse] = useState<any>()
  let [news, setNews] = useState<Array<News>>([])
  let [selectedFilters, setSelectedFilters] = useState<any>({ category: undefined, range: undefined, query: undefined })

  useEffect(() => {
    if (!_newsResponse) return
    setNewsResponse(_newsResponse)

    let _array = news.concat(
      _newsResponse.filter((n: News) => {
        return news.indexOf(n) === -1
      })
    )

    setNews(_array)
  }, [_newsResponse])

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.news)
  }, [])

  useEffect(() => {
    if (selectedFilters.range) askForPage(1, true)
  }, [selectedFilters])

  useEffect(() => {
    if (_newsResponse) {
      setTimeout(() => { setIsLoading(false); }, 500);
    }

  }, [_newsResponse]);

  let askForPage = async (page: number, reset?: boolean) => {
    if (reset) await setNews([])
    await setPaginationPage(page)
    await newsRequest(page, selectedFilters.category, selectedFilters.range.min, selectedFilters.range.max, selectedFilters.query)
  }

  let loadMore = async () => {
    if (_.isEmpty(newsResponse)) return
    askForPage(paginationPage + 1)
  }

  let handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) loadMore()
  }

  return (
    <div className={'news__container'} onScroll={handleScroll} >
      <h2>{strings.news.title}</h2>
      <div className={'divider'}></div>
      <NewsFilter
        onChangeFilter={(category?: NewsCategory, range?: any, query?: string) => {
          setIsLoading(true)
          setNewsResponse(undefined)
          setSelectedFilters({ category: category, range: range, query: query })
        }} />
      <div className={'news__card__container'}>
        <div className={'news__card__'}>

          {isLoading ?
            <div className={'loader_empty_state_container'}>
              <div className={'loader_loading'} />
            </div> :
            <Fragment>
              {newsResponse && _.isEmpty(news) && <Empty />}
              {_.values(news).map((news: News) => {
                return <NewsItem news={news} />
              })}
            </Fragment>
          }
        </div>
      </div>
    </div>
  );
};

export default NewsWrapper;
